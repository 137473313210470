import {routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {applyMiddleware, compose, createStore} from 'redux';
import {logger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {createRootReducer} from './reducerHelpers';
import {rootSaga} from './sagas';
import {unrecoverableErrorAction} from './_app/actions';

/**
 * Browser history.
 *
 * https://github.com/ReactTraining/history
 * */
export const history = createBrowserHistory();

/**
 * Creates and configures Redux global store.
 *
 * https://redux.js.org/glossary#store
 */
export const configureStore = initialState => {
    // https://redux.js.org/api/compose

    // @ts-ignore __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // https://redux-saga.js.org/
  const sagaMiddleware = createSagaMiddleware({onError: (error, errorInfo) => {
      // The whole saga tree was already crashed. We can only show an error message
      // TODO: Report error to Application Insights (if needed)

      error.info = errorInfo.sagaStack;

      const store = getStore();
      store.dispatch(unrecoverableErrorAction(error));
  }});

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancer(
      applyMiddleware(
        logger,
        routerMiddleware(history),
                sagaMiddleware,
            ),
            // TODO: ?? Replace by `redux-persist`
            // import persistState from 'redux-localstorage';
            // Persist Redux state in localStorage
            // persistState(),
        ),
    );

  const getStore = () => store;

    // Run root redux-saga
    sagaMiddleware.run(rootSaga);

    // // Load user to Redux global store by userManager // Not needed because of `redux-localstorage`
    // loadUser(store, userManager);

    return store;
};
