import _ from "lodash";

export const selectByRoomId = (entities, roomId) => {
    if (_.isEmpty(roomId)) {
        return entities;
    }

    if (!_.isEmpty(entities)) {
        const result = entities.filter(w => w.meetingRoom.id === roomId);
        if (!_.isEmpty(result)) {
            return result;
        }
    }

    return [];
};
