import { BUILDINGS_FETCHED } from "./actions";
import _ from "lodash";
import {normalizeNumbers} from "../../helpers";

const initialState = {
  entities: [],
};

export const buildingsApiReducer = (reducerState = initialState, action) => {
  switch (action.type) {
    // Don't use `const newState`. It doesn't work in `switch`
    case BUILDINGS_FETCHED:
      // Sort buildings
      const entities = _.orderBy(action.payload.entities, b => normalizeNumbers(b.code));
      return  {
        ...reducerState,
        entities,
      };

    default:
      return reducerState;
  }
};
