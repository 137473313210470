import {takeEvery} from 'redux-saga/effects';
import {httpGet, processJsonApiResponse} from '../helpers';
import {API} from "../apiRoutes";
import {
    AUDIT_LOG_FETCHING,
    fetchAuditLogResultAction,
} from './actions';
import {ApplicationError} from "../../_app/ApplicationError";

function* fetchEntitiesByTypeAndId(action) {
    const {resourceEntityType, resourceEntityId} = action.payload;

    const response = yield* httpGet(API.auditLog.forEntity(resourceEntityType, resourceEntityId));
    yield* processJsonApiResponse(
        response,
        entity => fetchAuditLogResultAction(resourceEntityType, resourceEntityId, entity),
        error => { throw ApplicationError("Failed to fetch", `Failed to fetch audit log for entity type "${resourceEntityType}" and ID "${resourceEntityId}"`); }
    );
}

export const auditLogSagas = [
    takeEvery(AUDIT_LOG_FETCHING, fetchEntitiesByTypeAndId),
];
