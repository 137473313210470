import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as yup from 'yup';
import _ from "lodash";
import Wind from "@bookingcom/bui-react/icons/Wind";
import EmptyState from '@bookingcom/bui-react/components/EmptyState';
import TextWithSpinner from '../../../../components/text-with-spinner/TextWithSpinner';
import { MODEL_STATE } from '../../../../lib/MODEL_STATE';
import ModelEditor from '../../../../components/model-editor/ModelEditor';
import { connect } from 'react-redux';
import { Alert, GridColumn, Grid } from "@bookingcom/bui-react";
import { MeetingRoomShape } from "../../../../api/rooms/shapes";
import { ErrorShape } from "../../../../_app/appPropShapes";
import { SharingRequestShape } from "../../../../api/sharing/shapes";
import { TenantShape } from "../../../../api/sharing/tenants/shapes";

import { fetchEntitiesAction as fetchTenantsAction } from "../../../../api/sharing/tenants/actions";
import { createEntityAction as createSharingRequestAction } from "../../../../api/sharing/actions";
import { RenderEither } from '../../../../components/render-either/RenderEither';

class AddTenant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entity: this.props.entity
        };
    }

    availableTenants = () => {
        var existing = this.props.sharingRoomRequests.map(req => {
            return req.tenantId;
        });

        if (!_.isEmpty(this.props.tenants)) {
            return this.props.tenants
                .filter(t => existing.includes(t.tenantId) === false)
        }

        return [];
    };

    dropDownOptions = () => {
        var tenants = this.availableTenants();
        var models = tenants
            .map(t =>
            ({
                text: t.name,
                value: t.tenantId,
                key: t.tenantId,
            }));

        return [
            { text: '- Select a tenant -', value: '', key: '' },
            ...models,];

    };

    grid = () => [
        { fieldName: 'tenantId', label: 'Tenant', size: 6, options: this.dropDownOptions() },
    ];


    validationSchema = () => {
        var tenants = this.availableTenants();
        var values = tenants.map(t => t.tenantId);
        return yup.object().shape({
            tenantId: yup.string().label('Tenant').oneOf(values, 'Please select a tenant.'),
        });
    }

    componentDidMount() {
        this.props.fetchTenantsAction();
    }

    onSave = (selectedEntity) => {
        var entity = { ...this.state.entity }
        entity.tenantId = selectedEntity.tenantId;
        this.setState({ entity })
        this.props.createSharingRequestAction(selectedEntity);
    };

    modelState = () => {
        return this.props.modelStates[this.state.entity.tenantId];
    }

    saveError = () => {
        return this.props.saveErrors[this.state.entity.tenantId];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.saveError() && this.modelState() !== prevProps.modelStates[this.state.entity.tenantId] && this.modelState() === MODEL_STATE.SYNCED) {
            this.props.onSave();
        }
    }

    isSaving = () => {
        return this.modelState() === MODEL_STATE.SYNCING;
    }

    hasAvailableTenants = () => {
        return !_.isEmpty(this.availableTenants());
    }

    render() {

        return <Grid>
            <RenderEither condition={this.props.isLoadingTenants}
                isTrue={() =>
                    <TextWithSpinner>Our rodents are busy searching for your data, hang on a second...</TextWithSpinner>}
                isFalse={() =>

                    <RenderEither condition={this.hasAvailableTenants() || this.isSaving()}
                        isTrue={() =>
                            <GridColumn size="full">
                                <ModelEditor
                                    legend="Sharing Request"
                                    model={this.props.entity}
                                    modelSchema={this.validationSchema()}
                                    grid={this.grid()}
                                    onSave={this.onSave}
                                    footer={() =>
                                        <Alert
                                            title='Notice!'
                                            text="Tenant sharing can take up to 2 business days to complete. These links cannot be removed easily, so please make sure this is a valid use-case."
                                            variant='primary'
                                        />
                                    }
                                    onCancel={this.props.onCancel}
                                    isSaving={this.isSaving()}
                                    saveError={this.saveError()}
                                />
                            </GridColumn>}
                        isFalse={() => <EmptyState icon={<Wind />} text="There are no available tenants for this meeting room" />}>
                    </RenderEither>}
            ></RenderEither>
        </Grid>;
    }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => (
    {
        // User
        user: state.oidc.user,

        // API data
        tenants: state.api.sharing.tenants.entities,
        isLoadingTenants: state.pages.roomEdit.sharing.isLoadingTenants,
        modelStates: state.pages.roomEdit.sharing.sharingRoomRequestsStates,
        saveErrors: state.pages.roomEdit.sharing.sharingRoomRequestsErrors,
    });

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    fetchTenantsAction,
    createSharingRequestAction
};

AddTenant.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,
    sharingRoomRequests: PropTypes.arrayOf(PropTypes.shape(SharingRequestShape)).isRequired,

    tenants: PropTypes.arrayOf(PropTypes.shape(TenantShape)),
    entity: PropTypes.shape(SharingRequestShape).isRequired,

    onCancel: PropTypes.func,
    onSave: PropTypes.func,

    saveError: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTenant);