import {
    MAINTENANCE_WINDOW_CREATING,
    MAINTENANCE_WINDOW_CREATED,
    MAINTENANCE_WINDOW_CREATE_FAILED, MAINTENANCE_CALCULATION_FETCHED, MAINTENANCE_CALCULATION_FETCHING,
} from "../../../api/room-maintenance/actions";
import {ROOM_MAINTENANCE_VIEW_RESET_STATE} from "./actions";

const initialState = {
    isEntitySaving: false,
    isEntitySaved: false,
    entitySaveError: null,
    isCalculationFetching: false,
};

// `{...state}` makes a copy of object `state`
// `{ ...state.rooms, ...newRooms }` merges two objects into a new one
// Details: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

// IMPORTANT: Make sure that you return new copy of a `state`.
// IMPORTANT: Don't mutate objects from `state`. Make a copy and only then mutate them.

export const roomMaintenanceViewReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case MAINTENANCE_WINDOW_CREATING:
            return {
                ...reducerState,
                isEntitySaving: true,
                isEntitySaved: false,
                entitySaveError: null,
            };

        case MAINTENANCE_WINDOW_CREATED:
            return {
                ...reducerState,
                isEntitySaving: false,
                isEntitySaved: true,
                entitySaveError: null,
            };

        case MAINTENANCE_WINDOW_CREATE_FAILED:
            const entitySaveError = action.payload.error.type === 'Error' ?
                action.payload.error.message :
                null;

            return {
                ...reducerState,
                isEntitySaving: false,
                isEntitySaved: false,
                entitySaveError,
            };

        case MAINTENANCE_CALCULATION_FETCHING:
            return {
                ...reducerState,
                isCalculationFetching: true,
            };

        case MAINTENANCE_CALCULATION_FETCHED:
            return {
                ...reducerState,
                isCalculationFetching: false,
            };

        case ROOM_MAINTENANCE_VIEW_RESET_STATE:
            return { ...initialState };

        default:
            /**
             * It's important to return the previous state for any unknown action.
             *
             * https://redux.js.org/basics/reducers
             */
            return reducerState;
    }
};
