import * as _ from 'lodash';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import {GridColumn} from '@bookingcom/bui-react';
import Grid from '@bookingcom/bui-react/components/Grid';
import InputText from '@bookingcom/bui-react/components/InputText';
import InputSelect from '@bookingcom/bui-react/components/InputSelect';
import styles from './Form.module.css';

export const Form = props =>
    <fieldset className={styles['bui-fieldset']}>
        <legend className={styles['bui-legend']}>{props.legend}</legend>
        <Grid>
            {props.grid.map(item => {
                const label = item.label ? item.label : item.fieldName;
                const hasOptions = !_.isEmpty(item.options);

                let input = null;

                if (hasOptions) {
                    input = (
                        <InputSelect
                            error={props.modelErrors[item.fieldName]}
                            name={item.fieldName}
                            label={label}
                            options={item.options}
                            value={props.model[item.fieldName]}
                            onBlur={props.onBlur}
                            onChange={props.onBlur}
                        />
                    );
                } else if (!_.isEmpty(item.mask)) {
                    input = (
                        <InputMask
                            error={props.modelErrors[item.fieldName]}
                            label={label}
                            mask={item.mask}
                            maskChar={null}
                            name={item.fieldName}
                            onBlur={props.onBlur}
                            onChange={props.onBlur}
                            value={props.model[item.fieldName]}
                        >
                            {(inputProps) => <InputText {...inputProps}/>}
                        </InputMask>
                    );
                } else {
                    input = (
                        <InputText
                            error={props.modelErrors[item.fieldName]}
                            label={label}
                            name={item.fieldName}
                            onBlur={props.onBlur}
                            onChange={props.onBlur}
                            value={props.model[item.fieldName]}
                        />
                    );
                }

                return (
                    <GridColumn size={item.size} key={item.fieldName}>
                        {input}
                    </GridColumn>
                );
            })}
        </Grid>
    </fieldset>;

Form.propTypes = {
    legend: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    grid: PropTypes.array.isRequired,
    onBlur: PropTypes.func.isRequired,

    modelErrors: PropTypes.object,
};

Form.defaultProps = {
    legend: 'Test form',
    model: {
        id: '1',
        firstName: 'John',
        lastName: 'Doe',
        address: 'Amsterdam Ave. 1',
    },
    grid: [
        {
            fieldName: 'firstName',
            size: 6,
            label: 'First Name',
        },
        {
            fieldName: 'lastName',
            size: 6,
            label: 'Last Name',
        },
        {
            fieldName: 'address',
            size: 12,
            label: 'Address',
        },
    ],
    modelErrors: {},
};

export default Form;
