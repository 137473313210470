import _ from 'lodash';
import React, {useState} from "react";
import * as PropTypes from "prop-types";
import Text from "@bookingcom/bui-react/components/Text";
import InputCheckbox from "@bookingcom/bui-react/components/InputCheckbox";
import {MeetingBotShape} from "../../../api/rooms/shapes";

const MeetingBotDetails = (props) => {
    const [entity, setEntity] = useState(props.value);

    if (_.isEmpty(props.value)) return '';

    if (props.value !== entity) {
        // Update entity whenever `props` is updated
        setEntity(props.value);
    }

    const onValueChanged = ({name, value}) => {
        setEntity(newEntity => {
            // Update state
            newEntity[name] = value;
            // Trigger callback
            props.onChanged({
                name: props.name,
                value: newEntity,
            });
            return newEntity;
        });
    };

    return <>
        <Text variant="heading" className="bui-spacer">{props.label}</Text>
        <InputCheckbox
            label='Meeting Bot enabled'
            name='isEnabled'
            onChange={onValueChanged}
            value={entity.isEnabled}
        />
    </>;
};

MeetingBotDetails.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.shape(MeetingBotShape),
    onChanged: PropTypes.func,
};

export default MeetingBotDetails;
