import * as uuid from "uuid";
import * as moment from "moment";
import {MODEL_STATE} from "./lib/MODEL_STATE";
import {API_RESOURCES} from "./api/apiRoutes";
import {RESHUFFLING_MODE} from "./api/room-maintenance/shapes";

export const buildNewMeetingRoom = () => ({
    __type: API_RESOURCES.rooms.type,
    __state: MODEL_STATE.NEW,
    id: uuid.v4(),
    isEnabled: false,
    isEnabledMessage: "Has been just created",
    features: [],
});

export const buildMeetingRoomMeetingBot = () => ({
    isEnabled: false,
});

export const buildMeetingRoomRecurringEventsLimiter = () => ({
    isEnabled: false,
    maxDuration: moment.duration(3, 'months'),
});

export const buildNewSharingRoomRequest = (room, tenantId, tenantName) => ({
    __type: API_RESOURCES.sharing.requests.type,
    __state: MODEL_STATE.NEW,
    roomId: room.id,
    tenantId: tenantId,
    tenantName: tenantName,
    status: "SHARING_REQUESTED"
});

export const buildNewVcCodec = room => ({
    __type: API_RESOURCES.equipment.vcCodecs.type,
    __state: MODEL_STATE.NEW,
    id: uuid.v4(),
    meetingRoomId: room.id,
    buildingCode: room.building.code,
    city: room.building.city,
    country: room.building.country,
    region: room.building.region,
});

export const buildNewAirMediaUnit = room => ({
    __type: API_RESOURCES.equipment.airMediaUnits.type,
    __state: MODEL_STATE.NEW,
    id: uuid.v4(),
    googleCalendarEmail: room.googleCalendar.calendarEmail,
    meetingRoomId: room.id,
    buildingCode: room.building.code,
    city: room.building.city,
    country: room.building.country,
    region: room.building.region,
});

export const buildNewRoomMaintenanceWindow = room => ({
    __type: API_RESOURCES.roomMaintenance.type,
    __state: MODEL_STATE.NEW,
    meetingRoom: room,
    from: null,
    to: null,
    reason: null,
    reshufflingMode: RESHUFFLING_MODE.EVERY_TIME,
});
