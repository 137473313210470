import * as PropTypes from 'prop-types';
import {ApiDtoShape} from "../shapes";
import {MeetingRoomShape} from "../rooms/shapes";

export const MaintenanceWindowShape = {
    ...ApiDtoShape,
    meetingRoom: PropTypes.shape(MeetingRoomShape),
    reason: PropTypes.string,
    reshufflingMode: PropTypes.string,
    from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    userId: PropTypes.string,
};

export const RESHUFFLING_MODE = Object.freeze({
    NO: 'No',
    ONCE: 'Once',
    EVERY_TIME: 'EveryTime',
});
