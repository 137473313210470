export const ACCESS_CONTROL_FETCHING = 'api/access-control/entity/FETCHING';
export const ACCESS_CONTROL_FETCHED = 'api/access-control/entity/FETCHED';
export const ACCESS_CONTROL_FETCHED_FAILED = 'api/access-control/entity/FETCHED_FAILED';

export const ACCESS_CONTROL_SMART_ACL_ENABLING = 'api/access-control/smart-acl-management/ENABLING';
export const ACCESS_CONTROL_SMART_ACL_ENABLED = 'api/access-control/smart-acl-management/ENABLED';
export const ACCESS_CONTROL_SMART_ACL_ENABLED_FAILED = 'api/access-control/smart-acl-management/ENABLED_FAILED';

export const ACCESS_CONTROL_MEETING_ROOM_ENABLING = 'api/access-control/meeting-room/ENABLING';
export const ACCESS_CONTROL_MEETING_ROOM_ENABLED = 'api/access-control/meeting-room/ENABLED';
export const ACCESS_CONTROL_MEETING_ROOM_ENABLED_FAILED = 'api/access-control/meeting-room/ENABLED_FAILED';

export const ACCESS_CONTROL_MEETING_ROOM_DISABLING = 'api/access-control/meeting-room/DISABLING';
export const ACCESS_CONTROL_MEETING_ROOM_DISABLED = 'api/access-control/meeting-room/DISABLED';
export const ACCESS_CONTROL_MEETING_ROOM_DISABLED_FAILED = 'api/access-control/meeting-room/DISABLED_FAILED';

export const ACCESS_CONTROL_MEETING_ROOM_DEDICATING = 'api/access-control/meeting-room/DEDICATING';
export const ACCESS_CONTROL_MEETING_ROOM_DEDICATED = 'api/access-control/meeting-room/DEDICATED';
export const ACCESS_CONTROL_MEETING_ROOM_DEDICATED_FAILED = 'api/access-control/meeting-room/DEDICATED_FAILED';

export const ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATING = 'api/access-control/meeting-room/UN_DEDICATING';
export const ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED = 'api/access-control/meeting-room/UN_DEDICATED';
export const ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED_FAILED = 'api/access-control/meeting-room/UN_DEDICATED_FAILED';

export const fetchEntityAction = roomId => ({
    type: ACCESS_CONTROL_FETCHING,
    payload: {
        roomId,
    },
});

export const fetchEntityResultAction = (roomId, entity) => ({
    type: ACCESS_CONTROL_FETCHED,
    payload: {
        roomId,
        entity,
    },
});

export const fetchEntityFailedAction = (roomId, error) => ({
    type: ACCESS_CONTROL_FETCHED_FAILED,
    payload: {
        roomId,
        error,
    },
});

export const enableSmartAclAction = entity => ({
    type: ACCESS_CONTROL_SMART_ACL_ENABLING,
    payload: {
        entity,
    },
});

export const enableSmartAclResultAction = entity => ({
    type: ACCESS_CONTROL_SMART_ACL_ENABLED,
    payload: {
        entity,
    },
});

export const enableSmartAclFailedAction = (entity, error) => ({
    type: ACCESS_CONTROL_SMART_ACL_ENABLED_FAILED,
    payload: {
        entity,
        error,
    },
});

export const enableMeetingRoomAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_ENABLING,
    payload: {
        entity,
    },
});

export const enableMeetingRoomResultAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_ENABLED,
    payload: {
        entity,
    },
});

export const enableMeetingRoomFailedAction = (entity, error) => ({
    type: ACCESS_CONTROL_MEETING_ROOM_ENABLED_FAILED,
    payload: {
        entity,
        error
    },
});

export const disableMeetingRoomAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_DISABLING,
    payload: {
        entity,
    },
});

export const disableMeetingRoomResultAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_DISABLED,
    payload: {
        entity,
    },
});

export const disableMeetingRoomFailedAction = (entity, error) => ({
    type: ACCESS_CONTROL_MEETING_ROOM_DISABLED_FAILED,
    payload: {
        entity,
        error
    },
});

export const dedicateMeetingRoomAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_DEDICATING,
    payload: {
        entity,
    },
});

export const dedicateMeetingRoomResultAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_DEDICATED,
    payload: {
        entity,
    },
});

export const dedicateMeetingRoomFailedAction = (entity, error) => ({
    type: ACCESS_CONTROL_MEETING_ROOM_DEDICATED_FAILED,
    payload: {
        entity,
        error
    },
});

export const unDedicateMeetingRoomAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATING,
    payload: {
        entity,
    },
});

export const unDedicateMeetingRoomResultAction = entity => ({
    type: ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED,
    payload: {
        entity,
    },
});

export const unDedicateMeetingRoomFailedAction = (entity, error) => ({
    type: ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED_FAILED,
    payload: {
        entity,
        error,
    },
});