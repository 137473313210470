import _ from "lodash";
import { takeEvery } from 'redux-saga/effects';
import { API } from '../apiRoutes';
import { httpGet, httpPatch, httpPost, processApiResponse } from '../helpers';
import {tenantsSagas} from "./tenants/sagas";
import {
    SHARING_REQUESTS_FETCHING,
    SHARING_REQUEST_CANCELING,
    SHARING_REQUEST_CREATING,
    SHARING_SYNC_APPROVING,
    fetchEntitiesResultAction,
    fetchEntitiesFailedAction,
    createEntityResultAction,
    createEntityFailedAction,
    cancelEntityResultAction,
    cancelEntityFailedAction,
    approveEntityFailedAction,
    approveEntityResultAction,
} from './actions';

function* fetchEntities(action) {
    const { roomId } = action.payload;
    const response = yield* httpGet(API.sharing.requests.byRoomId(roomId));
    yield* processApiResponse(
        response,
        entity => fetchEntitiesResultAction(roomId, entity),
        error => fetchEntitiesFailedAction(roomId, error),
    );
}

function* updateEntity(action) {
    const roomId = action.payload.entity.roomId;
    const roomStatus = action.payload.entity.status;
    const response = yield* httpPatch(API.sharing.requests.endpoint, {status: roomStatus, roomId: roomId});
    console.log(API.sharing.requests.endpoint);
    yield* processApiResponse(
        response,
        entity => approveEntityResultAction(roomId, entity),
        error => approveEntityFailedAction(roomId, error),
    );
}

function* createEntity(action) {
    const entity = action.payload.entity;
    
    const response = yield* httpPost(API.sharing.requests.endpoint, entity);
    yield* processApiResponse(response, createEntityResultAction, createEntityFailedAction);
}

//TODO Matei adjust call or refactor API
function* cancelEntity(action) {
    const entity = _.clone(action.payload.entity);
    
    const response = yield* httpPatch(API.sharing.requests.endpoint, entity);
    yield* processApiResponse(response, cancelEntityResultAction, cancelEntityFailedAction);
}

export const sharingSagas = [
    ...tenantsSagas,
    takeEvery(SHARING_REQUESTS_FETCHING, fetchEntities),
    takeEvery(SHARING_REQUEST_CANCELING, cancelEntity),
    takeEvery(SHARING_REQUEST_CREATING, createEntity),
    takeEvery(SHARING_SYNC_APPROVING, updateEntity),
];
