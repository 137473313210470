
class ApplicationError extends Error {
    constructor(title = 'Unexpected error', message = 'Unknown error') {
        super();

        // HTTP status
        this.status = 0;
        this.title = title;
        this.message = message;
        this.modelErrors = {};
        this.reloadLink = document.location.href;
        this.detailsNode = null;
    }
}

export {ApplicationError};
