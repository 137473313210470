import React from "react";
import Spinner from "@bookingcom/bui-react/components/Spinner";
import './styles.css'

const TextWithSpinner = (props) => {
    return <div className="mrt-text-with-spinner">
        <Spinner className="mrt-text-with-spinner__spinner" />
        <span className="mrt-text-with-spinner__text">{props.children}</span>
    </div>;
};

export default TextWithSpinner;
