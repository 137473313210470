import {UNRECOVERABLE_ERROR} from './actions';
import {USER_NOTIFICATIONS_FETCHED, USER_NOTIFICATIONS_FETCHING} from "../api/user-notifications/actions";

const initialState = {
    unrecoverableError: null,
    isLoadingUserNotifications: false,
    hasLoadedUserNotifications: false,
};

export const appReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case UNRECOVERABLE_ERROR: {
            return {
                ...reducerState,
                unrecoverableError: action.payload.error,
            }
        }
        case USER_NOTIFICATIONS_FETCHING: {
            return {
                ...reducerState,
                isLoadingUserNotifications: true,
            };
        }
        case USER_NOTIFICATIONS_FETCHED: {
            return {
                ...reducerState,
                hasLoadedUserNotifications: true,
                isLoadingUserNotifications: false,
            };
        }
        default:
            /**
             * It's important to return the previous state for any unknown action.
             *
             * https://redux.js.org/basics/reducers
             */
            return reducerState;
    }
};
