export const ROOMS_FETCHING = 'api/rooms/list/FETCHING';
export const ROOMS_FETCHED = 'api/rooms/list/FETCHED';
export const ROOMS_FETCH_FAILED = 'api/rooms/list/FETCH_FAILED';

export const ROOM_FETCHING = 'api/rooms/entity/FETCHING';
export const ROOM_FETCHED = 'api/rooms/entity/FETCHED';
export const ROOM_FETCH_FAILED = 'api/rooms/entity/FETCH_FAILED';

export const ROOM_SAVING = 'api/rooms/entity/SAVING';
export const ROOM_SAVED = 'api/rooms/entity/SAVED';
export const ROOM_SAVE_FAILED = 'api/rooms/entity/SAVE_FAILED';

export const ROOM_CREATING = 'api/rooms/entity/CREATING';
export const ROOM_CREATED = 'api/rooms/entity/CREATED';
export const ROOM_CREATE_FAILED = 'api/rooms/entity/CREATE_FAILED';

export const fetchEntitiesAction = (buildingId, searchQuery) => ({
    type: ROOMS_FETCHING,
    payload: {
        buildingId,
        searchQuery,
    },
});

export const fetchEntitiesResultAction = entities => ({
    type: ROOMS_FETCHED,
    payload: {
        entities,
    },
});

export const fetchEntitiesFailedAction = (_, error) => ({
    type: ROOMS_FETCH_FAILED,
    payload: {
        error,
    },
});

export const fetchEntityAction = identifier => ({
    type: ROOM_FETCHING,
    payload: {
        identifier,
    },
});

export const fetchEntityResultAction = entity => ({
    type: ROOM_FETCHED,
    payload: {
        entity,
    },
});

export const fetchEntityFailedAction = error => ({
    type: ROOM_FETCH_FAILED,
    payload: {
        error,
    },
});

export const saveEntityAction = entity => ({
    type: ROOM_SAVING,
    payload: {
        entity,
    },
});

export const saveEntityResultAction = entity => ({
    type: ROOM_SAVED,
    payload: {
        entity,
    },
});

export const saveEntityFailedAction = error => ({
    type: ROOM_SAVE_FAILED,
    payload: {
        error,
    },
});

export const createEntityAction = entity => ({
    type: ROOM_CREATING,
    payload: {
        entity,
    },
});

export const createEntityResultAction = entity => ({
    type: ROOM_CREATED,
    payload: {
        entity,
    },
});

export const createEntityFailedAction = error => ({
    type: ROOM_CREATE_FAILED,
    payload: {
        error,
    },
});
