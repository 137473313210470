import {
    SHARING_REQUESTS_FETCHING,
    SHARING_REQUESTS_FETCHED,
    SHARING_REQUESTS_FETCH_FAILED,
    SHARING_REQUEST_CREATING,
    SHARING_REQUEST_CREATED,
    SHARING_REQUEST_CREATE_FAILED,
    SHARING_REQUEST_CANCELING,
    SHARING_REQUEST_CANCELED,
    SHARING_REQUEST_CANCEL_FAILED, SHARING_SYNC_APPROVED, SHARING_SYNC_APPROVED_FAILED, SHARING_SYNC_APPROVING
} from '../../../api/sharing/actions';
import {deleteEntity, upsertEntity} from '../../../reducerHelpers';
import {MODEL_STATE} from "../../../lib/MODEL_STATE";
import { TENANTS_FETCH_FAILED, TENANTS_FETCHED, TENANTS_FETCHING } from '../../../api/sharing/tenants/actions';

const initialState = {
    isLoadingRoomRequests: false,
    isLoadingTenants: false,
    sharingRoomRequestsErrors: {},
    sharingRoomRequestsStates: {}
};

export const sharingViewReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case SHARING_REQUESTS_FETCHING: {
            const sharingFetchingStates = {...reducerState.sharingRoomRequestsStates};
            // Update state for all entities in the room
            Object.keys(sharingFetchingStates).forEach(key => {
                sharingFetchingStates[key] = MODEL_STATE.SYNCING;
            });

            return {
                ...reducerState,
                sharingRoomRequestsStates: sharingFetchingStates,
                isLoadingRoomRequests: true,
            };
        }
        case SHARING_REQUESTS_FETCHED:
        case SHARING_REQUESTS_FETCH_FAILED: {
            const sharingFetchedEntities = [...action.payload.entities];
            const sharingFetchedStates = {...reducerState.sharingRoomRequestsStates};
            const sharingFetchedErrors = {...reducerState.sharingRoomRequestsErrors};

            // Update all existing states
            Object.keys(sharingFetchedStates).forEach(key => {
                sharingFetchedStates[key] = MODEL_STATE.SYNCED;
            });

            // Iterate through fetched entities, update states and remove errors
            sharingFetchedEntities.forEach(e => {
                sharingFetchedStates[e.tenantId] = MODEL_STATE.SYNCED;
                delete sharingFetchedErrors[e.tenantId];
            });

            return {
                ...reducerState,
                sharingRoomRequestsStates: sharingFetchedStates,
                sharingRoomRequestsErrors: sharingFetchedErrors,
                isLoadingRoomRequests: false,
            };
        }
        case SHARING_REQUEST_CREATING:
        case SHARING_REQUEST_CANCELING: {
            return {
                ...reducerState,
                sharingRoomRequestsStates: upsertEntity(reducerState.sharingRoomRequestsStates, action.payload.entity.tenantId, MODEL_STATE.SYNCING),
                sharingRoomRequestsErrors: deleteEntity(reducerState.sharingRoomRequestsErrors, action.payload.entity.tenantId),
            };
        }
        case SHARING_REQUEST_CREATED: {
            return {
                ...reducerState,
                sharingRoomRequestsStates: upsertEntity(reducerState.sharingRoomRequestsStates, action.payload.entity.tenantId, MODEL_STATE.SYNCED),
                sharingRoomRequestsErrors: deleteEntity(reducerState.sharingRoomRequestsErrors, action.payload.entity.tenantId),
            };
        }
        case SHARING_REQUEST_CREATE_FAILED:
            const sharingRoomRequestsError = {...action.payload.error};
            // TODO Matei - we do not know which one failed if the response doesnt contain it - we should load from 
            const state = !!action.payload.entity ? MODEL_STATE.SYNCED : MODEL_STATE.NEW;
            
            return {
                ...reducerState,
                sharingRoomRequestsStates: upsertEntity(reducerState.sharingRoomRequestsStates, action.payload.entity.tenantId, state),
                sharingRoomRequestsErrors: upsertEntity(reducerState.sharingRoomRequestsErrors, action.payload.entity.tenantId, sharingRoomRequestsError),
            };
        case SHARING_REQUEST_CANCEL_FAILED: {
            const sharingRoomRequestsError = {...action.payload.error};
            // TODO Matei - we do not know which one failed if the response doesnt contain it - we should load from 
            const state = !!action.payload.entity ? MODEL_STATE.SYNCED : MODEL_STATE.NEW;
            
            return {
                ...reducerState,
                sharingRoomRequestsStates: upsertEntity(reducerState.sharingRoomRequestsStates, action.payload.entity.tenantId, state),
                sharingRoomRequestsErrors: upsertEntity(reducerState.sharingRoomRequestsErrors, action.payload.entity.tenantId, sharingRoomRequestsError),
            };
        }
        case SHARING_REQUEST_CANCELED: {
            return {
                ...reducerState,
                sharingRoomRequestsStates: upsertEntity(reducerState.sharingRoomRequestsStates, action.payload.entity.tenantId, MODEL_STATE.SYNCED),
                sharingRoomRequestsErrors: upsertEntity(reducerState.sharingRoomRequestsErrors, action.payload.entity.tenantId),
            };
        }
        case TENANTS_FETCHING: {
            return {
                ...reducerState,
                isLoadingTenants: true,
            };
        }
        case TENANTS_FETCHED:
        case TENANTS_FETCH_FAILED: {
            return {
                ...reducerState,
                isLoadingTenants: false,
            };
        }
        case SHARING_SYNC_APPROVING:
            return {
                ...reducerState,
                isLoadingTenants: true,
                isLoadingRoomRequests: true,
            };
        case SHARING_SYNC_APPROVED:
        case SHARING_SYNC_APPROVED_FAILED:
            return {
                ...reducerState,
                isLoadingTenants: false,
                isLoadingRoomRequests: false,
                approvalError: action.payload.error,
            };
        default:
            return reducerState;
    }
};
