import {put, takeEvery} from 'redux-saga/effects';
import {
    fetchEntitiesAction as fetchAirMediaUnitsAction,
    AIR_MEDIA_UNIT_CREATED,
    AIR_MEDIA_UNIT_SAVED,
} from '../../../api/equipment/air-media-units/actions';
import {
    fetchEntitiesAction as fetchVcCodecsAction,
    VC_CODEC_CREATED,
    VC_CODEC_SAVED,
} from '../../../api/equipment/vc-codecs/actions';

function* refetchAirMedia(action) {
    const entity = {...action.payload.entity};
    yield put(fetchAirMediaUnitsAction(entity.meetingRoomId));
}

function* refetchVcCodecs(action) {
    const entity = {...action.payload.entity};
    yield put(fetchVcCodecsAction(entity.meetingRoomId));
}

export const roomEquipmentViewSagas = [
    takeEvery(AIR_MEDIA_UNIT_SAVED, refetchAirMedia),
    takeEvery(AIR_MEDIA_UNIT_CREATED, refetchAirMedia),
    takeEvery(VC_CODEC_SAVED, refetchVcCodecs),
    takeEvery(VC_CODEC_CREATED, refetchVcCodecs),
];
