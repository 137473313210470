export const BUILDINGS_FETCHING = 'api/buildings/list/FETCHING';
export const BUILDINGS_FETCHED = 'api/buildings/list/FETCHED';

export const fetchBuildingsAction = () => ({
  type: BUILDINGS_FETCHING,
  payload: {
  },
});

export const fetchBuildingsResultAction = entities => ({
  type: BUILDINGS_FETCHED,
  payload: {
    entities,
  },
});
