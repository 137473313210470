import moment from 'moment';

export const USER_NOTIFICATIONS_FETCHING = 'api/userNotifications/list/FETCHING';
export const USER_NOTIFICATIONS_FETCHED = 'api/userNotifications/list/FETCHED';

export const fetchUserNotificationsAction = () => ({
  type: USER_NOTIFICATIONS_FETCHING,
  payload: {
  },
});

export const fetchUserNotificationsResultAction = entities => {
  // TODO: Fix on API side
  // HACK
  entities = entities.map(hackPascalCased);
  entities = entities.map(hackNodaTime);

  return ({
    type: USER_NOTIFICATIONS_FETCHED,
    payload: {
      entities,
    },
  });
};

function hackPascalCased(entity) {
  const keys = Object.keys(entity);

  const result = {};
  for (const key of keys) {
    const newKey = `${key[0].toLowerCase()}${key.substr(1)}`;
    result[newKey] = entity[key];
  }

  return result;
}

function hackNodaTime(entity) {
  const result = {};
  for (const prop in entity) {
    const oldValue = entity[prop];

    let newValue;
    if (!!oldValue && !!oldValue.Year && !!oldValue.YearOfEra) {
      if (oldValue.YearOfEra === 1) {
        newValue = null;
      }
      else {
        newValue = moment.tz([
          oldValue.Year,
          oldValue.Month - 1,
          oldValue.Day,
          oldValue.Hour,
          oldValue.Minute,
          oldValue.Second], 'UTC');
      }
    }
    else {
      newValue = oldValue;
    }

    result[prop] = newValue;
  }

  return result;
}
