/**
 * @module actions
 *
 * Redux action types and action creation functions.
 *
 * https://redux.js.org/basics/actions
 ********************************************************
 */

export const SEARCH_CRITERIA_UPDATED = 'roomsPage/SEARCH_CRITERIA_UPDATED';

export const updateSearchCriteriaAction = (buildingCode, searchQuery) => ({
    type: SEARCH_CRITERIA_UPDATED,
    payload: {
        buildingCode,
        searchQuery,
    },
});
