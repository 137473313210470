import _ from 'lodash';
import React from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Grid from '@bookingcom/bui-react/components/Grid';
import GridColumn from "@bookingcom/bui-react/components/Grid/GridColumn";
import Card from "@bookingcom/bui-react/components/Card";
import Alert from '@bookingcom/bui-react/components/Alert';
import Breadcrumb from "@bookingcom/bui-react/components/Breadcrumb";
import {ROOM_EDIT_PAGE_FUNC, ROOMS_PAGE} from '../../appRoutes';
import {fetchBuildingsAction} from '../../api/buildings/actions';
import {createEntityAction} from '../../api/rooms/actions';
import {updateUrlAction} from "../../_app/actions";
import {RenderIf} from "../../components/render-if/RenderIf";
import {RoomMainDetails} from '../Rooms.Edit/details/RoomMainDetails';
import {resetRoomCreatePageStateAction} from './actions';
import DocumentTitle from "../../components/document-title/DocumentTitle";

class RoomCreatePage extends React.Component {
    componentDidMount() {
        // Dispatches an action to fetch all buildings as soon the page is mounted.
        this.props.fetchBuildingsAction();
    }

    componentWillUnmount() {
        this.props.resetRoomCreatePageStateAction();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!!this.props.isEntityCreated) {
            this.props.updateUrlAction(ROOM_EDIT_PAGE_FUNC(this.props.createdEntity.code));
        }
    }

    onSave = entity => {
        this.props.createEntityAction(entity);
    };

    render() {

        if (this.props.isEntityCreated) {
            // Actually, this message is not shown because of immediate redirect to the created room's page.
            return <Alert variant="success"
               className="bui-spacer"
               size="large"
               title="Room has been created"
            >
                <p>It will take a couple of minutes to propagate this change to Google Suite, so please be patient.</p>
            </Alert>;
        }

        return <DocumentTitle title="New meeting room">
            <Grid className="bui-spacer">
                <GridColumn size="full">
                    <Breadcrumb items={[{text: "Rooms", href: ROOMS_PAGE}, {text: "New meeting room"}]} />
                </GridColumn>
            </Grid>
            {alert}

            <RenderIf
                condition={!_.isEmpty(this.props.buildings)}
                render={() =>
                    <Card className="bui-f-depth-1">
                        <RoomMainDetails
                            modelErrors={this.props.modelErrors}
                            entitySaveError={this.props.entityCreateError}
                            onSave={this.onSave}
                            buildings={this.props.buildings}
                            isSaving={this.props.isEntityCreating}
                        />
                    </Card>}
            />
        </DocumentTitle>;
    }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
    buildings: state.api.buildings.entities,
    isEntityCreating: state.pages.roomCreate.isEntityCreating,
    isEntityCreated: state.pages.roomCreate.isEntityCreated,
    createdEntity: state.pages.roomCreate.createdEntity,
    entityCreateError: state.pages.roomCreate.entityCreateError,
    modelErrors: state.pages.roomCreate.modelErrors,
});

const mapDispatchToProps = {
    fetchBuildingsAction,
    createEntityAction,
    resetRoomCreatePageStateAction,
    updateUrlAction,
};

RoomCreatePage.propTypes = {
    // Data
    buildings: PropTypes.array,
    isEntityCreated: PropTypes.bool,
    isEntityCreating: PropTypes.bool,
    entityCreateError: PropTypes.string,
    modelErrors: PropTypes.object,

    // Actions
    fetchBuildingsAction: PropTypes.func,
    createEntityAction: PropTypes.func,
    resetRoomCreatePageStateAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomCreatePage);
