import _ from "lodash";

export const selectByRoomId = (entities, roomId) => {
    if (!_.isEmpty(entities)) {
        const result = Object.values(entities)
            .filter(x => x.meetingRoomId === roomId);
        return result;
    }

    return null;
};
