import {put, takeLatest} from 'redux-saga/effects';
import {push, replace} from 'connected-react-router';
import {NAVIGATE_TO, NAVIGATE_TO_AND_RELOAD, NAVIGATE_TO_LOCATION, SIGNIN, UPDATE_URL} from './actions';
import {CALLBACK_PAGE, LOGIN_PAGE, ROOMS_PAGE} from "../appRoutes";
import userManagerPromise from "../_auth/userManager";

function* navigate(action) {
    yield put(push(action.payload.route));
}

function* navigateAndReload(action) {
    yield navigate(action);

    window.location.reload();
}

function* updateUrl(action) {
    yield put(replace(action.payload.url));
}

function* signIn(action) {
    const userManager = yield userManagerPromise;
    yield userManager.signinRedirect({
        data: {
            location: action.payload.nextLocation,
        },
    });
}

/**
 * Navigate to a particular location.
 * @param action
 * @returns {IterableIterator<PutEffect<CallHistoryMethodAction<[, ]>>>}
 */
function* navigateToLocation(action) {
    const pathname = action.payload.nextLocation.pathname || ROOMS_PAGE;
    const search = action.payload.nextLocation.search || "";

    yield put(push({
        pathname: pathname !== LOGIN_PAGE && pathname !== CALLBACK_PAGE ? pathname : ROOMS_PAGE,
        search: pathname !== LOGIN_PAGE && pathname !== CALLBACK_PAGE ? search : "",
    }));
}

export const appSagas = [
    takeLatest(NAVIGATE_TO, navigate),
    takeLatest(NAVIGATE_TO_AND_RELOAD, navigateAndReload),
    takeLatest(UPDATE_URL, updateUrl),
    takeLatest(SIGNIN, signIn),
    takeLatest(NAVIGATE_TO_LOCATION, navigateToLocation),
];
