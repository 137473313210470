import {takeEvery} from 'redux-saga/effects';
import {httpGet, httpJsonApiPatch, httpJsonApiPost, processJsonApiResponse} from '../../helpers';
import {API} from "../../apiRoutes";
import {
    VC_CODECS_FETCHING,
    VC_CODEC_CREATING,
    VC_CODEC_SAVING,
    VC_CODEC_DELETING,
    fetchEntitiesResultAction,
    fetchEntitiesFailedAction,
    createEntityResultAction,
    createEntityFailedAction,
    saveEntityResultAction,
    saveEntityFailedAction,
    deleteEntityResultAction,
    deleteEntityFailedAction,
} from './actions';

function* fetchEntitiesByRoomId(action) {
    const {roomId} = action.payload;

    const response = yield* httpGet(API.equipment.vcCodecs.byRoomId(roomId));
    yield* processJsonApiResponse(
        response,
        entity => fetchEntitiesResultAction(roomId, entity),
        error => fetchEntitiesFailedAction(roomId, error),
    );
}

function* createEntity(action) {
    const entity = {...action.payload.entity};

    const response = yield* httpJsonApiPost(API.equipment.vcCodecs.endpoint, entity);
    yield* processJsonApiResponse(
        response,
        createEntityResultAction,
        error => createEntityFailedAction(entity, error),
    );
}

function* saveEntity(action) {
    const entity = {...action.payload.entity};

    // TODO: HACK. Temporary solution to obtain the latest `revision` (and unintentionally skip concurrency check)
    if (!entity.revision) {
        const getResponse = yield* httpGet(API.equipment.vcCodecs.byId(action.payload.entity.id));
        const existingEntity = yield processJsonApiResponse(getResponse);
        console.warn('HACKED the revision');
        entity.revision = existingEntity.revision;
    }

    const response = yield* httpJsonApiPatch(API.equipment.vcCodecs.byId(entity.id), entity);
    yield* processJsonApiResponse(
        response,
        saveEntityResultAction,
        error => saveEntityFailedAction(entity, error),
    );
}

function* deleteEntity(action) {
    const getResponse = yield* httpGet(API.equipment.vcCodecs.byId(action.payload.entity.id));
    const entityToDelete = yield processJsonApiResponse(getResponse);
    entityToDelete.isDeleted = true;

    const response = yield* httpJsonApiPatch(API.equipment.vcCodecs.byId(entityToDelete.id), entityToDelete);
    yield* processJsonApiResponse(
        response,
        deleteEntityResultAction,
        error => deleteEntityFailedAction(entityToDelete, error),
    );
}

export const vcCodecsSagas = [
    takeEvery(VC_CODECS_FETCHING, fetchEntitiesByRoomId),
    takeEvery(VC_CODEC_CREATING, createEntity),
    takeEvery(VC_CODEC_SAVING, saveEntity),
    takeEvery(VC_CODEC_DELETING, deleteEntity),
];
