import React from 'react';
import * as PropTypes from 'prop-types';
import Button from '@bookingcom/bui-react/components/Button';
import InputText from '@bookingcom/bui-react/components/InputText';
import Copy from "@bookingcom/bui-react/icons/Copy";
import {Icon} from "@bookingcom/bui-react";
import './styles.css';

class CopyToClipboardText extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    copyInputValueToClipboard = () => {
        const inputText = document.createElement('input');
        inputText.value = this.props.text;
        this.myRef.current.appendChild(inputText);
        inputText.select();
        document.execCommand('copy');
        this.myRef.current.removeChild(inputText);
    };

    render() {
        return (
            <div ref={this.myRef} className="mrt-copy-to-clipboard">
                <InputText
                    label={this.props.label}
                    value={this.props.text}
                    name={this.props.name}
                    suffix={<Button
                        variant="secondary"
                        text={<Icon svg={<Copy />} size="large" />}
                        onClick={this.copyInputValueToClipboard}
                    />}
                />
            </div>
        );
    }
}

CopyToClipboardText.propTypes = {
    text: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
};

export default CopyToClipboardText;
