import {takeEvery} from 'redux-saga/effects';
import {httpGet, httpJsonApiPatch, httpJsonApiPost, processJsonApiResponse} from '../../helpers';
import {API} from "../../apiRoutes";
import {
    AIR_MEDIA_UNITS_FETCHING,
    AIR_MEDIA_UNIT_CREATING,
    AIR_MEDIA_UNIT_SAVING,
    AIR_MEDIA_UNIT_DELETING,
    fetchEntitiesResultAction,
    fetchEntitiesFailedAction,
    createEntityResultAction,
    createEntityFailedAction,
    saveEntityResultAction,
    saveEntityFailedAction,
    deleteEntityResultAction,
    deleteEntityFailedAction,
} from './actions';

function* fetchEntitiesByRoomId(action) {
    const {roomId} = action.payload;

    const response = yield* httpGet(API.equipment.airMediaUnits.byRoomId(roomId));
    yield* processJsonApiResponse(
        response,
        entity => fetchEntitiesResultAction(roomId, entity),
        error => fetchEntitiesFailedAction(roomId, error),
    );
}

function* createEntity(action) {
    const entity = {...action.payload.entity};

    const response = yield* httpJsonApiPost(API.equipment.airMediaUnits.endpoint, entity);
    yield* processJsonApiResponse(
        response,
        createEntityResultAction,
        error => createEntityFailedAction(entity, error),
    );
}

function* saveEntity(action) {
    const entity = {...action.payload.entity};

    // TODO: HACK. Temporary solution to obtain the latest `revision` (and unintentionally skip concurrency check)
    if (!entity.revision) {
        const getResponse = yield* httpGet(API.equipment.airMediaUnits.byId(action.payload.entity.id));
        const existingEntity = yield processJsonApiResponse(getResponse);
        console.warn('HACKED the revision');
        entity.revision = existingEntity.revision;
    }

    const response = yield* httpJsonApiPatch(API.equipment.airMediaUnits.byId(entity.id), entity);
    yield* processJsonApiResponse(
        response,
        saveEntityResultAction,
        error => saveEntityFailedAction(entity, error),
    );
}

function* deleteEntity(action) {
    const getResponse = yield* httpGet(API.equipment.airMediaUnits.byId(action.payload.entity.id));
    const entityToDelete = yield processJsonApiResponse(getResponse);
    entityToDelete.isDeleted = true;

    const response = yield* httpJsonApiPatch(API.equipment.airMediaUnits.byId(entityToDelete.id), entityToDelete);
    yield* processJsonApiResponse(
        response,
        deleteEntityResultAction,
        error => deleteEntityFailedAction(entityToDelete, error),
    );
}

export const airMediaUnitsSagas = [
    takeEvery(AIR_MEDIA_UNITS_FETCHING, fetchEntitiesByRoomId),
    takeEvery(AIR_MEDIA_UNIT_CREATING, createEntity),
    takeEvery(AIR_MEDIA_UNIT_SAVING, saveEntity),
    takeEvery(AIR_MEDIA_UNIT_DELETING, deleteEntity),
];
