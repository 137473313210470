import {
    ACCESS_CONTROL_FETCHING,
    ACCESS_CONTROL_FETCHED,
    ACCESS_CONTROL_FETCHED_FAILED,
    ACCESS_CONTROL_SMART_ACL_ENABLING,
    ACCESS_CONTROL_SMART_ACL_ENABLED,
    ACCESS_CONTROL_SMART_ACL_ENABLED_FAILED,
    ACCESS_CONTROL_MEETING_ROOM_ENABLING,
    ACCESS_CONTROL_MEETING_ROOM_ENABLED,
    ACCESS_CONTROL_MEETING_ROOM_ENABLED_FAILED,
    ACCESS_CONTROL_MEETING_ROOM_DISABLING,
    ACCESS_CONTROL_MEETING_ROOM_DISABLED,
    ACCESS_CONTROL_MEETING_ROOM_DISABLED_FAILED,
    ACCESS_CONTROL_MEETING_ROOM_DEDICATING,
    ACCESS_CONTROL_MEETING_ROOM_DEDICATED,
    ACCESS_CONTROL_MEETING_ROOM_DEDICATED_FAILED,
    ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATING,
    ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED,
    ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED_FAILED,
} from '../../../api/access-control/actions';

const initialState = {
    isLoadingRoomAccessControl: false,
    accessControlErrors: null,
    meetingRoomAccessControlState: {},
    entity: {}
};

export const accessControlViewReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case ACCESS_CONTROL_FETCHING:
        case ACCESS_CONTROL_SMART_ACL_ENABLING:
        case ACCESS_CONTROL_MEETING_ROOM_ENABLING:
        case ACCESS_CONTROL_MEETING_ROOM_DISABLING:
        case ACCESS_CONTROL_MEETING_ROOM_DEDICATING:
        case ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATING: {
            return {
                ...reducerState,
                isLoadingRoomAccessControl: true,
            }
        }
        case ACCESS_CONTROL_FETCHED:
        case ACCESS_CONTROL_SMART_ACL_ENABLED:
        case ACCESS_CONTROL_MEETING_ROOM_ENABLED:
        case ACCESS_CONTROL_MEETING_ROOM_DISABLED:
        case ACCESS_CONTROL_MEETING_ROOM_DEDICATED:
        case ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED: {
            const entity = {...action.payload.entity};
            return {
                ...reducerState,
                isLoadingRoomAccessControl: false,
                entity
            }
        }
        case ACCESS_CONTROL_FETCHED_FAILED:
        case ACCESS_CONTROL_SMART_ACL_ENABLED_FAILED:
        case ACCESS_CONTROL_MEETING_ROOM_ENABLED_FAILED:
        case ACCESS_CONTROL_MEETING_ROOM_DISABLED_FAILED:
        case ACCESS_CONTROL_MEETING_ROOM_DEDICATED_FAILED:
        case ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED_FAILED:
            const accessControlError = action.payload.error.type === 'Error' ?
                action.payload.error.message : null;
            return {
                ...reducerState,
                isLoadingRoomAccessControl: false,
                accessControlErrors: accessControlError,
                entity: action.payload.entity
            }
        default:
            return reducerState;
    }
};