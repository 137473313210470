import * as PropTypes from 'prop-types';
import {ApiDtoShape} from "../shapes";

export const AuditLogEntryShape = {
    ...ApiDtoShape,
    resourceEntityId: PropTypes.string,
    resourceEntityType: PropTypes.string,
    userId: PropTypes.string,
    message: PropTypes.string,
    propertyName: PropTypes.string,
    oldValue: PropTypes.any,
    newValue: PropTypes.any,
};
