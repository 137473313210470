import * as PropTypes from 'prop-types';

export const ApiDtoShape = {
    id: PropTypes.string,
    lastUpdatedBy: PropTypes.string,
    lastUpdatedTimestamp: PropTypes.string,
    isDeleted: PropTypes.bool,
    revision: PropTypes.string,
};

export const SyncStatusShape = {
    status: PropTypes.string,
    message: PropTypes.string,
    timestamp: PropTypes.string,
};
