import React from 'react';
import * as PropTypes from 'prop-types';
import Info from "../info/Info";
import {MeetingRoomShape} from "../../api/rooms/shapes";
import {RoomListItem} from './RoomListItem';
import './RoomList.css';

export const RoomList = props => (
    <table className="bui-table bui-f-depth-1">
        <thead className="bui-table__head">
        <tr className="bui-table__row">
            <th className="bui-table__cell bui-table__cell--head">
                Name
            </th>
            <th className="bui-table__cell bui-table__cell--head">
                Building
            </th>
            <th className="bui-table__cell bui-table__cell--head">
                Floor
            </th>
            <th className="bui-table__cell bui-table__cell--head">
                Number
            </th>
            <th className="bui-table__cell bui-table__cell--head">
                Is enabled?
                <Info title="Can any employee book the room?" variant="right"/>
            </th>
            <th className="bui-table__cell bui-table__cell--head bui-table__cell--align-end">
            </th>
        </tr>
        </thead>
        <tbody className="bui-table__body">
        {props.entities.map(room =>
            <RoomListItem
                key={room.id}
                room={room}
            />)}
        </tbody>
    </table>
);

RoomList.propTypes = {
    entities: PropTypes.arrayOf(PropTypes.shape(MeetingRoomShape)),
};
