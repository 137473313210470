import {
    AIR_MEDIA_UNITS_FETCHING,
    AIR_MEDIA_UNITS_FETCHED,
    AIR_MEDIA_UNITS_FETCH_FAILED,
    AIR_MEDIA_UNIT_CREATING,
    AIR_MEDIA_UNIT_CREATED,
    AIR_MEDIA_UNIT_CREATE_FAILED,
    AIR_MEDIA_UNIT_SAVING,
    AIR_MEDIA_UNIT_SAVED,
    AIR_MEDIA_UNIT_SAVE_FAILED,
    AIR_MEDIA_UNIT_DELETING,
    AIR_MEDIA_UNIT_DELETED,
    AIR_MEDIA_UNIT_DELETE_FAILED,
} from '../../../api/equipment/air-media-units/actions';
import {
    VC_CODECS_FETCHING,
    VC_CODECS_FETCHED,
    VC_CODECS_FETCH_FAILED,
    VC_CODEC_CREATING,
    VC_CODEC_CREATED,
    VC_CODEC_CREATE_FAILED,
    VC_CODEC_SAVING,
    VC_CODEC_SAVED,
    VC_CODEC_SAVE_FAILED,
    VC_CODEC_DELETING,
    VC_CODEC_DELETED,
    VC_CODEC_DELETE_FAILED,
} from '../../../api/equipment/vc-codecs/actions';
import {deleteEntity, upsertEntity} from '../../../reducerHelpers';
import {
    EQUIPMENT_EXPORT_TRIGGERING,
    EQUIPMENT_EXPORT_TRIGGERED,
    EQUIPMENT_EXPORT_TRIGGER_FAILED,
} from "../../../api/equipment/actions";
import {AIR_MEDIA_UNIT_REMOVE_ERROR, VC_CODEC_REMOVE_ERROR} from "./actions";
import {MODEL_STATE} from "../../../lib/MODEL_STATE";

const initialState = {
    isLoadingAirMediaUnitsList: false,
    isLoadingVcCodecsList: false,
    isTriggeringExport: false,
    airMediaUnitErrors: {},
    vcCodecErrors: {},
    airMediaUnitStates: {},
    vcCodecStates: {},
    exportError: null,
    exportResult: null,
};

export const roomEquipmentViewReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case AIR_MEDIA_UNITS_FETCHING: {
            const fetchingAirMediaStates = {...reducerState.airMediaUnitStates} || {};
            // Update state for all entities in the room
            Object.keys(fetchingAirMediaStates).forEach(key => {
                fetchingAirMediaStates[key] = MODEL_STATE.SYNCING;
            });

            return {
                ...reducerState,
                airMediaUnitStates: fetchingAirMediaStates,
                isLoadingAirMediaUnitsList: true,
            };
        }
        case VC_CODECS_FETCHING: {
            const fetchingCodecStates = {...reducerState.vcCodecStates} || {};
            // Update state for all entities in the room
            Object.keys(fetchingCodecStates).forEach(key => {
                // if (vcCodecStates[key].roomId !== action.payload.roomId) return;
                fetchingCodecStates[key] = MODEL_STATE.SYNCING;
            });

            return {
                ...reducerState,
                vcCodecStates: fetchingCodecStates,
                isLoadingVcCodecsList: true,
            };
        }
        case AIR_MEDIA_UNITS_FETCHED:
        case AIR_MEDIA_UNITS_FETCH_FAILED: {
            const fetchedAirMediaEntities = [...action.payload.entities];
            const fetchedAirMediaStates = {...reducerState.airMediaUnitStates} || {};
            const fetchedAirMediaErrors = {...reducerState.airMediaUnitErrors} || {};

            // Update all existing states
            Object.keys(fetchedAirMediaStates).forEach(key => {
                fetchedAirMediaStates[key] = MODEL_STATE.SYNCED;
            });

            // Iterate through fetched entities, update states and remove errors
            fetchedAirMediaEntities.forEach(e => {
                fetchedAirMediaStates[e.id] = MODEL_STATE.SYNCED;
                delete fetchedAirMediaErrors[e.id];
            });

            return {
                ...reducerState,
                airMediaUnitStates: fetchedAirMediaStates,
                airMediaUnitErrors: fetchedAirMediaErrors,
                isLoadingAirMediaUnitsList: false,
            };
        }
        case VC_CODECS_FETCHED:
        case VC_CODECS_FETCH_FAILED: {
            const fetchedCodecsEntities = [...action.payload.entities];
            const fetchedCodecStates = {...reducerState.vcCodecStates} || {};
            const fetchedCodecErrors = {...reducerState.vcCodecErrors} || {};

            // Update all existing states
            Object.keys(fetchedCodecStates).forEach(key => {
                fetchedCodecStates[key] = MODEL_STATE.SYNCED;
            });

            // Iterate through fetched entities, update states and remove errors
            fetchedCodecsEntities.forEach(e => {
                fetchedCodecStates[e.id] = MODEL_STATE.SYNCED;
                delete fetchedCodecErrors[e.id];
            });

            return {
                ...reducerState,
                vcCodecStates: fetchedCodecStates,
                vcCodecErrors: fetchedCodecErrors,
                isLoadingVcCodecsList: false,
            };
        }
        case AIR_MEDIA_UNIT_CREATING:
        case AIR_MEDIA_UNIT_SAVING:
        case AIR_MEDIA_UNIT_DELETING: {
            return {
                ...reducerState,
                airMediaUnitStates: upsertEntity(reducerState.airMediaUnitStates, action.payload.entity.id, MODEL_STATE.SYNCING),
                airMediaUnitErrors: deleteEntity(reducerState.airMediaUnitErrors, action.payload.entity.id),
            };
        }
        case VC_CODEC_CREATING:
        case VC_CODEC_SAVING:
        case VC_CODEC_DELETING: {
            return {
                ...reducerState,
                vcCodecStates: upsertEntity(reducerState.vcCodecStates, action.payload.entity.id, MODEL_STATE.SYNCING),
                vcCodecErrors: deleteEntity(reducerState.vcCodecErrors, action.payload.entity.id),
            };
        }
        case AIR_MEDIA_UNIT_REMOVE_ERROR: {
            return {
                ...reducerState,
                airMediaUnitErrors: deleteEntity(reducerState.airMediaUnitErrors, action.payload.entity.id),
            };
        }
        case VC_CODEC_REMOVE_ERROR: {
            return {
                ...reducerState,
                vcCodecErrors: deleteEntity(reducerState.vcCodecErrors, action.payload.entity.id),
            };
        }
        case AIR_MEDIA_UNIT_SAVED:
        case AIR_MEDIA_UNIT_CREATED: {
            return {
                ...reducerState,
                airMediaUnitStates: upsertEntity(reducerState.airMediaUnitStates, action.payload.entity.id, MODEL_STATE.SYNCED),
                airMediaUnitErrors: deleteEntity(reducerState.airMediaUnitErrors, action.payload.entity.id),
            };
        }
        case VC_CODEC_SAVED:
        case VC_CODEC_CREATED: {
            return {
                ...reducerState,
                vcCodecStates: upsertEntity(reducerState.vcCodecStates, action.payload.entity.id, MODEL_STATE.SYNCED),
                vcCodecErrors: deleteEntity(reducerState.vcCodecErrors, action.payload.entity.id),
            };
        }
        case AIR_MEDIA_UNIT_CREATE_FAILED:
        case AIR_MEDIA_UNIT_SAVE_FAILED:
        case AIR_MEDIA_UNIT_DELETE_FAILED: {
            const airMediaUnitError = {...action.payload.error};

            // Restore previous state
            const state = !!action.payload.entity.lastUpdatedTimestamp ? MODEL_STATE.SYNCED : MODEL_STATE.NEW;

            return {
                ...reducerState,
                airMediaUnitStates: upsertEntity(reducerState.airMediaUnitStates, action.payload.entity.id, state),
                airMediaUnitErrors: upsertEntity(reducerState.airMediaUnitErrors, action.payload.entity.id, airMediaUnitError),
            };
        }
        case VC_CODEC_CREATE_FAILED:
        case VC_CODEC_SAVE_FAILED:
        case VC_CODEC_DELETE_FAILED: {
            const vcCodecError = {...action.payload.error};

            // Restore previous state
            const state = !!action.payload.entity.lastUpdatedTimestamp ? MODEL_STATE.SYNCED : MODEL_STATE.NEW;

            return {
                ...reducerState,
                vcCodecStates: upsertEntity(reducerState.vcCodecStates, action.payload.entity.id, state),
                vcCodecErrors: upsertEntity(reducerState.vcCodecErrors, action.payload.entity.id, vcCodecError),
            };
        }
        case AIR_MEDIA_UNIT_DELETED: {
            return {
                ...reducerState,
                airMediaUnitStates: deleteEntity(reducerState.airMediaUnitStates, action.payload.entity.id),
                airMediaUnitErrors: deleteEntity(reducerState.airMediaUnitErrors, action.payload.entity.id),
            };
        }
        case VC_CODEC_DELETED: {
            return {
                ...reducerState,
                vcCodecStates: deleteEntity(reducerState.vcCodecStates, action.payload.entity.id),
                vcCodecErrors: deleteEntity(reducerState.vcCodecErrors, action.payload.entity.id),
            };
        }
        case EQUIPMENT_EXPORT_TRIGGERING: {
            return {
                ...reducerState,
                isTriggeringExport: true,
                exportError: null,
                exportResult: null,
            };
        }
        case EQUIPMENT_EXPORT_TRIGGERED: {
            const exportResult = action.payload.result;
            return {
                ...reducerState,
                isTriggeringExport: false,
                exportError: null,
                exportResult,
            };
        }
        case EQUIPMENT_EXPORT_TRIGGER_FAILED: {
            const exportError = {...action.payload.error};
            return {
                ...reducerState,
                isTriggeringExport: false,
                exportError,
                exportResult: null,
            };
        }

        default:
            return reducerState;
    }
};
