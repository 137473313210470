import {ROOMS_FETCHED, ROOMS_FETCHING} from '../../api/rooms/actions';
import {BUILDINGS_FETCHED, BUILDINGS_FETCHING} from "../../api/buildings/actions";
import {SEARCH_CRITERIA_UPDATED} from "./actions";

const initialState = {
    buildingCode: null,
    searchQuery: null,
    isLoadingBuildings: false,
    isSearchingRooms: false,
};

// `{...state}` makes a copy of object `state`
// `{ ...state.rooms, ...newRooms }` merges two objects into a new one
// Details: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

// IMPORTANT: Make sure that you return new copy of a `state`.
// IMPORTANT: Don't mutate objects from `state`. Make a copy and only then mutate them.

export const roomsListPageReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case BUILDINGS_FETCHING:
            return { ...reducerState, isLoadingBuildings: true };

        case BUILDINGS_FETCHED:
            return {...reducerState, isLoadingBuildings: false};

        case SEARCH_CRITERIA_UPDATED:
            return {
                ...reducerState,
                buildingCode: action.payload.buildingCode,
                searchQuery: action.payload.searchQuery,
            };

        case ROOMS_FETCHING: {
            return {
                ...reducerState,
                isSearchingRooms: true,
            }
        }

        case ROOMS_FETCHED: {
            return {
                ...reducerState,
                isSearchingRooms: false,
            }
        }

        default:
            /**
             * It's important to return the previous state for any unknown action.
             *
             * https://redux.js.org/basics/reducers
             */
            return reducerState;
    }
};
