export const EQUIPMENT_EXPORT_TRIGGERING = 'api/equipment/export/TRIGGERING';
export const EQUIPMENT_EXPORT_TRIGGERED = 'api/equipment/export/TRIGGERED';
export const EQUIPMENT_EXPORT_TRIGGER_FAILED = 'api/equipment/export/TRIGGER_FAILED';

export const triggerEquipmentExportAction = () => ({
    type: EQUIPMENT_EXPORT_TRIGGERING,
    payload: {
    },
});

export const triggerEquipmentExportResultAction = result => ({
    type: EQUIPMENT_EXPORT_TRIGGERED,
    payload: {
        result,
    },
});

export const triggerEquipmentExportFailedAction = error => ({
    type: EQUIPMENT_EXPORT_TRIGGER_FAILED,
    payload: {
        error,
    },
});
