import React from "react";
import {Icon} from "@bookingcom/bui-react";
import InputText from "@bookingcom/bui-react/components/InputText";
import Button from "@bookingcom/bui-react/components/Button";
import Close from "@bookingcom/bui-react/icons/Close";
import './styles.css';

const InputTextWithClearButton = ({onClear, ...props}) => {
    return <div className="mrt-input-text-with-clear-button">
        <InputText
            {...props}
            suffix={<Button
                variant="secondary"
                text={<Icon svg={<Close/>} size="large" className="mrt-input-text-with-clear-button__clear-button" />}
                onClick={onClear}
            />}
        />
    </div>;
};

export {InputTextWithClearButton};
