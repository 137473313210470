import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { OidcProvider } from 'redux-oidc';
import ReactAI from 'react-appinsights';
import userManagerPromise from './_auth/userManager';
import App from './_app/App';
import { AI_INSTRUMENTATION_KEY } from './constants';
import { configureStore, history } from './store';
import {unrecoverableErrorAction} from "./_app/actions";
import './styles.css'; // Main CSS styles that are rendered from LESS. Don't edit CSS, edit LESS.

// Creating Redux global store
const store = configureStore();

ReactAI.init({instrumentationKey: AI_INSTRUMENTATION_KEY}, history);

window.onerror = (_, file, line, col, error) => {
    store.dispatch(unrecoverableErrorAction(error));
};

const render = userManager => {

    // Rendering React application in "Strict mode"

    ReactDOM.render(
        // Rendering using JSX markup. https://reactjs.org/docs/introducing-jsx.html
        <>
            {/*<React.StrictMode>*/}
                {/* Redux store provider. https://react-redux.js.org/api/provider */}
                <Provider store={store}>
                    {/* OIDC (OpenId Connect) provider that does the magic. http://openid.net/connect/ */}
                    <OidcProvider store={store} userManager={userManager}>
                        {/* Main application component */}
                        <App/>
                    </OidcProvider>
                </Provider>
            {/*</React.StrictMode>*/}
        </>,
        // DOM element to render React to
        document.getElementById('react-root'),
    );
};

userManagerPromise
    .then(userManager => render(userManager));
