import * as _ from 'lodash';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { GridColumn } from '@bookingcom/bui-react';
import Grid from '@bookingcom/bui-react/components/Grid';
import IconPlus from '@bookingcom/bui-react/icons/Plus';
import Route from '@bookingcom/bui-react/icons/Route';
import Table from '@bookingcom/bui-react/components/Table';
import Modal from '@bookingcom/bui-react/components/Modal';
import EmptyState from '@bookingcom/bui-react/components/EmptyState';
import CloseBold from "@bookingcom/bui-react/icons/CloseBold";
import TextWithSpinner from '../../../components/text-with-spinner/TextWithSpinner';
import { buildNewSharingRoomRequest } from "../../../factories";
import {
    fetchEntitiesAction as fetchSharingRequestsAction,
    cancelEntityAction as cancelSharingAction,
    approveEntityAction as approveSharingSyncAction,
    approveEntityFailedAction as approveSharingFailedSyncAction,
} from '../../../api/sharing/actions';
import styles from "./MeetingRoomShareListView.module.css";
import { MeetingRoomShape } from "../../../api/rooms/shapes";
import { SharingRequestShape } from "../../../api/sharing/shapes";
import { UserShape } from "../../../_app/appPropShapes";
import { SharingStatuses } from "./MeetingRoomShareListView.constants"
import { RenderEither } from "../../../components/render-either/RenderEither";
import Alert from "@bookingcom/bui-react/components/Alert";
import RbacButton from "../../../components/rbac-button/RbacButton";
import { RBAC_ROOM_SHARING, RBAC_ROOM_SHARING_CANCEL } from "../../../constants";
import Wind from "@bookingcom/bui-react/icons/Wind";
import AddTenant from './add-tenant/AddTenant';

const tableColumnsMapping = [
    { key: 'tenantName', title: 'Tenant' }
];

class MeetingRoomShareListView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shouldDisplayAddEntityModal: false,
            selectedEntity: null,
        };
    }

    componentDidMount() {
        if (this.props.room) {
            this.props.fetchSharingRequestsAction(this.props.room.id);
        }
    }
    cancelShare = entity => {
        this.props.cancelSharingAction(entity);
    };

    approveSync = () => {
        if (this.props.room.id) {
            this.props.approveSharingSyncAction({roomId: this.props.room.id, status: SharingStatuses.SYNC_APPROVED});
        }
        if (!this.props.approvalError) {
            this.props.sharingRoomRequests.map(request => request.status = SharingStatuses.SYNC_APPROVED);
        }
    }

    tableRows = () => {
        const rows = this.sharingRequestsRows(this.props.sharingRoomRequests);
        return rows;
    };

    sharingRequestsRows = sharingRequests =>
        _.map(
            sharingRequests,
            sharingRequest => {

                const cells = _.map(tableColumnsMapping, m => this.buildCell(sharingRequest, m));
                cells.push(this.getStatusIndicator(sharingRequest));
                cells.push(this.getActionButton(sharingRequest));

                return {
                    cells,
                    key: sharingRequest.tenantId,
                };
            }
        );

    getStatusIndicator = (sharingRequest) => {
        var variant;
        switch (sharingRequest.status) {
            case SharingStatuses.SYNC_APPROVED:
                variant = "success";
                break;
            case SharingStatuses.FAILED:
            case SharingStatuses.DELETE_REQUESTED:
                variant = "error";
                break;
            default:
                variant = "info";
        }

        return { content: <Alert inline={true} text={sharingRequest.status} variant={variant} /> };
    }
 
    getActionButton = (sharingRequest) => {
        var text;
        switch (sharingRequest.status) {
            case SharingStatuses.READY_FOR_SYNC:
            case SharingStatuses.SHARED:
            case SharingStatuses.SYNC_REQUESTED:
            case SharingStatuses.SYNC_APPROVED:
                text = "Request Removal"
                break;
            case SharingStatuses.SHARING_REQUESTED:
            case SharingStatuses.AWAITING_ADMIN_APPROVAL:
            case SharingStatuses.FAILED:
                text = "Cancel Request"
                break;
            default:
                return "";
        }


        return {
            content: <div className={styles['__buttons-cell']}>
                <RbacButton
                    rbacRight={RBAC_ROOM_SHARING_CANCEL}
                    className={styles['__cancel']}
                    icon={<CloseBold />}
                    text={text}
                    variant="secondary"
                    onClick={() => {
                        sharingRequest.status = SharingStatuses.DELETE_REQUESTED;
                        this.cancelShare(sharingRequest);
                    }}
                    attributes={{ title: "Remove Tenant from room sharing" }}
                    prompt={{ title: "Confirmation", subtitle: "Are you sure want to request tenant removal?", buttonText: "Request removal" }}
                />
            </div>
        };
    }



    buildCell(sharingRequest, mapping) {
        const value = sharingRequest[mapping.key];
        let content = value;
        return { content };
    }

    tableHeadings = () => {
        const tableHeadings = _.map(tableColumnsMapping, m => ({ text: m.title }));
        tableHeadings.push({ text: 'Status' });
        tableHeadings.push({ text: '', align: 'end' });
        return tableHeadings;
    };

    onAddShareClicked = () => {
        this.setState({ shouldDisplayAddEntityModal: true });
    };



    onAddShareModalClose = () => {
        this.setState({ shouldDisplayAddEntityModal: false });
    };

    hasRequests = () =>
        (!_.isEmpty(this.props.sharingRoomRequests) && this.props.sharingRoomRequests.length > 0);

    render() {
        return <>
            <Grid>
                <GridColumn size="full">
                    <div className={styles['__buttons']}>
                        {this.props.approvalError &&  <Alert text='There was a problem when Approving Sync, please try again later' variant='error' bleed />}
                        <RbacButton rbacRight={RBAC_ROOM_SHARING}
                                    text="Approve Sync"
                                    disabled = {!this.props.readyForSync}
                                    icon={<Route/>}
                                    onClick={() => {
                                        this.approveSync();
                                    }}/>
                        <RbacButton rbacRight={RBAC_ROOM_SHARING}
                                    text="Add tenant"
                                    icon={<IconPlus/>}
                                    onClick={this.onAddShareClicked}/>
                    </div>
                </GridColumn>
                <GridColumn size="full">
                    <RenderEither condition={this.props.isLoadingRoomRequests}
                                  isTrue={() =>
                                      <TextWithSpinner>Our rodents are busy searching for your data, hang on a
                                          second...</TextWithSpinner>}
                        isFalse={() =>
                            <RenderEither condition={this.hasRequests()}
                                isTrue={() =>
                                    <Table
                                        headings={this.tableHeadings()}
                                        rows={this.tableRows()}
                                    />}
                                isFalse={() =>
                                    <EmptyState icon={<Wind />} text="There are no sharing requests for this meeting room" />}
                            />
                        }
                    />
                </GridColumn>
            </Grid>                
            <Modal
                title={`Add sharing request for ${this.props.room.code}`}
                closeAriaLabel="Close Modal"
                onClose={this.onAddShareModalClose}
                active={this.state.shouldDisplayAddEntityModal}
            >
                <AddTenant
                    room={this.props.room}
                    sharingRoomRequests={this.props.sharingRoomRequests || []}
                    entity={buildNewSharingRoomRequest(this.props.room, null, null)}
                    onSave={this.onAddShareModalClose}
                    onCancel={this.onAddShareModalClose}
                />
            </Modal>
        </>;
    }
}


export const selectById = (entities, roomId) => {
    if (!_.isEmpty(entities)) {
        const result = Object.values(entities)
            .filter(x => x.roomId === roomId);
        return result;
    }

    return [];
};

export const readyForSync = (entities, roomId) => {
    if (!_.isEmpty(entities)) {
        const result = Object.values(entities)
            .filter(x => x.roomId === roomId);
        return result.every((request) => request.status === SharingStatuses.ADMIN_APPROVED);
    }

    return false;
};

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    fetchSharingRequestsAction,
    cancelSharingAction,
    approveSharingSyncAction,
    approveSharingFailedSyncAction,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
    // User
    user: state.oidc.user,

    // API data
    sharingRoomRequests: selectById(state.api.sharing.requests.entities, ownProps.room.id),

    // Page data
    isLoadingRoomRequests: state.pages.roomEdit.sharing.isLoadingRoomRequests,

    approvalError: state.pages.roomEdit.sharing.approvalError,
    readyForSync:  readyForSync(state.api.sharing.requests.entities, ownProps.room.id),
});

MeetingRoomShareListView.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    // Actions
    fetchSharingRequestsAction: PropTypes.func,
    cancelSharingAction: PropTypes.func,
    approveSharingSyncAction: PropTypes.func,
    approveSharingSyncFailedAction: PropTypes.func,

    // Redux
    user: PropTypes.shape(UserShape),
    sharingRoomRequests: PropTypes.arrayOf(PropTypes.shape(SharingRequestShape)),
    isLoadingRoomRequests: PropTypes.bool,
    approvalError: PropTypes.string,

    readyForSync: PropTypes.bool,

};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoomShareListView);
