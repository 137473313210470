import _ from 'lodash';
import React from "react";
import * as PropTypes from 'prop-types';
import InputSelect from "@bookingcom/bui-react/components/InputSelect";
import Text from "@bookingcom/bui-react/components/Text";
import {InputTextWithClearButton} from "../input-text-with-clear-button/InputTextWithClearButton";

const allBuildings = buildings => [
    {name: 'All buildings', code: ''},
    ...buildings,
];

const RoomSearch = props => {
    let searchQuery = props.searchQuery;
    if (searchQuery === undefined) {
        searchQuery = '';
    }

    const onBuildingCodeChanged = ({name, value}) => {
        const building = allBuildings(props.buildings).find(b => b.code === value);
        props.onBuildingChanged(building);
    };

    function formatBuilding(building) {
        const text = _.isEmpty(building.name)
            ? building.code :
            `${building.code} - ${building.name}`;

        return {
            text: text,
            value: building.code,
            key: building.code,
        };
    }

    return <>
        <InputSelect
            disabled={props.isLoadingBuildings}
            className="bui-spacer"
            label="Building"
            name="buildingCode"
            onChange={onBuildingCodeChanged}
            value={props.buildingCode}
            options={allBuildings(props.buildings).map(formatBuilding)}
        />
        <InputTextWithClearButton
            className="bui-spacer--medium"
            label="Name"
            name="searchQuery"
            value={props.searchQuery}
            onChange={props.onSearchQueryChanged}
            onClear={props.onSearchQueryCleared}
        />
        <Text variant="caption" className="bui-spacer">Enter at least 3 characters to search</Text>
    </>
};

RoomSearch.propTypes = {
    onBuildingChanged: PropTypes.func,
    onSearchQueryChanged: PropTypes.func,
    onSearchButtonClicked: PropTypes.func,
    buildingCode: PropTypes.string,
    searchQuery: PropTypes.string,
    buildings: PropTypes.array,
    loading: PropTypes.bool,
};

export default RoomSearch;