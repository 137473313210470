export const AIR_MEDIA_UNIT_REMOVE_ERROR = 'roomEquipmentPage/AIR_MEDIA_UNIT_REMOVE_ERROR';

export const VC_CODEC_REMOVE_ERROR = 'roomEquipmentPage/VC_CODEC_REMOVE_ERROR';

export const removeAirMediaUnitErrorAction = entity => ({
    type: AIR_MEDIA_UNIT_REMOVE_ERROR,
    payload: {
        entity,
    },
});

export const removeVcCodecErrorAction = entity => ({
    type: VC_CODEC_REMOVE_ERROR,
    payload: {
        entity,
    },
});
