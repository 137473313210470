import _ from 'lodash';
import * as qs from "qs";
import React from 'react';
import {connect} from 'react-redux';
import * as PropTypes from "prop-types";
import Funnel from "@bookingcom/bui-react/icons/Funnel";
import Container from "@bookingcom/bui-react/components/Container";
import EmptyState from "@bookingcom/bui-react/components/EmptyState";
import Grid from "@bookingcom/bui-react/components/Grid";
import GridColumn from "@bookingcom/bui-react/components/Grid/GridColumn";
import Card from "@bookingcom/bui-react/components/Card";
import {updateSearchCriteriaAction} from "./actions";
import {fetchBuildingsAction} from '../../api/buildings/actions';
import {navigateToAction, updateUrlAction} from '../../_app/actions';
import TextWithSpinner from "../../components/text-with-spinner/TextWithSpinner";
import {roomsByBuildingCodeSelector} from "../../api/rooms/selectors";
import {RoomList} from '../../components/room-list/RoomList';
import RoomSearch from "../../components/room-search/RoomSearch";
import DocumentTitle from "../../components/document-title/DocumentTitle";

class RoomsPage extends React.Component {

    constructor(props) {
        super(props);

        let queryString = this.props.location.search;
        if (!!queryString && queryString[0] === '?') {
            queryString = queryString.substr(1);
        }
        const {buildingCode, searchQuery} = qs.parse(queryString);

        this.state = {
            isDirty: false,
            buildingCode,
            searchQuery,
        };

        /**
         * Props are already injected from Redux state in {@link mapReduxStateToProps}.
         * However, we should apply them not here but in `getDerivedStateFromProps()`
         */
    }

    /** Attached to the DOM. */
    componentDidMount() {
        // Dispatches an action to fetch all buildings as soon the page is mounted.
        this.props.fetchBuildingsAction();
        this.performSearch();
    }

    /**
     * Component was updated in DOM.
     *
     * Good place to react on update of local state and trigger some actions.
     *
     * Tip:
     * You can use `this.state` and `this.props` inside the method.
     */
    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        // // Iterate all rooms and create an action to fetch their status.
        // if (_.xor(this.props.rooms, prevProps.rooms).length > 0) {
        //     this.props.rooms.map(room => this.props.fetchRoomStatusAction(room.id));
        // }

        if (this.props.location.state === 'navBar') {
            this.props.location.state = null;
            this.evaluateLocation(prevState);
        }
    }

    evaluateLocation(prevState) {
        let {buildingCode, searchQuery} = qs.parse(this.props.location.search);

        const isBuildingCodeChanged = !_.isEmpty(prevState.buildingCode) && !_.isEmpty(buildingCode) &&
            prevState.buildingCode !== buildingCode;
        if (isBuildingCodeChanged) {
            this.setState({buildingCode});
        }

        const isSearchQueryChanged = prevState.searchQuery !== searchQuery;
        if (isSearchQueryChanged) {
            this.setState({searchQuery});
        }

        if ((isBuildingCodeChanged || isSearchQueryChanged) && _.isEmpty(buildingCode) && _.isEmpty(searchQuery)) {
            this.setState({isDirty: false});
        }
    }

    onBuildingChanged = building => {
        this.setState({
            buildingCode: building.code,
        });

        this.performSearch();
    };

    // eslint-disable-next-line no-unused-vars
    onSearchQueryChanged = ({name, value}) => {
        this.setState({
            searchQuery: value,
        });

        this.performSearch();
    };

    onSearchQueryCleared = () => {
        this.setState({
            searchQuery: '',
        });

        this.performSearch();
    };

    performSearch = _.debounce(
        () => { this.props.updateSearchCriteriaAction(this.state.buildingCode, this.state.searchQuery); },
        800);

    buildTitle() {
        let separator = '';
        const buildingCode = this.state.buildingCode || '';
        const searchQuery = this.state.searchQuery || '';

        if (!_.isEmpty(buildingCode) && !_.isEmpty(searchQuery)) {
            separator = ', query: ';
        }

        let title = `${buildingCode}${separator}${searchQuery}`;
        if (!_.isEmpty(title)) {
            title = ` - ${title}`;
        }
        return `Room list${title}`;
    }

    /**
     * Renders the content based on the local state - `this.state`.
     *
     * Tips:
     *
     * Use `className=` instead of `class=`.
     */
    render() {

        let resultsPanel = null;
        let doShowRooms = false;

        if (this.props.isSearchingRooms) {
            resultsPanel = (
                <Card className="bui-f-depth-1 bui-spacer--small">
                    <TextWithSpinner>Our rodents are busy searching for your rooms, hang on a second...</TextWithSpinner>
                </Card>
            );
        } else {
            doShowRooms = !_.isEmpty(this.props.entities);
            if (doShowRooms) {
                resultsPanel = (
                    <>
                        <RoomList
                            entities={this.props.entities}
                            // roomStatuses={this.props.roomStatuses}
                            // roomStatusCurrentlyLoadingByRoomId={this.props.roomStatusCurrentlyLoadingByRoomId}
                        />
                        <Container>Number of rooms: {this.props.entities.length}</Container>
                    </>
                );
            } else {
                resultsPanel = (
                    <Card className="bui-f-depth-1 bui-spacer--small">
                        <EmptyState
                            icon={<Funnel />}
                            text="Update the search criteria to find some rooms"
                        />
                    </Card>
                );
            }
        }

        return <DocumentTitle title={this.buildTitle()}>
            <Card className="bui-f-depth-1 bui-spacer">
                <h1>Search for a room</h1>
                <RoomSearch
                    isLoadingBuildings={this.props.isLoadingBuildings}
                    buildings={this.props.buildings}
                    buildingCode={this.state.buildingCode}
                    searchQuery={this.state.searchQuery}
                    onBuildingChanged={this.onBuildingChanged}
                    onSearchQueryChanged={this.onSearchQueryChanged}
                    onSearchQueryCleared={this.onSearchQueryCleared}
                />
            </Card>
            <Grid>
                <GridColumn size="full">
                    {resultsPanel}
                </GridColumn>
            </Grid>
        </DocumentTitle>;
    }
}

/**
 * Maps Redux global state to props.
 *
 * https://react-redux.js.org/using-react-redux/connect-mapstate
 *
 * **Note**:
 * Fired before constructor and at any Redux state update.
 * */
const mapReduxStateToProps = state => ({
    buildings: state.api.buildings.entities,
    entities: roomsByBuildingCodeSelector(state.api.rooms.entities, state.pages.roomsList.buildingCode),
    isLoadingBuildings: state.pages.roomsList.isLoadingBuildings,
    isSearchingRooms: state.pages.roomsList.isSearchingRooms,
});

/**
 * Injects action functions' dispatchers to props.
 *
 * https://react-redux.js.org/api/connect#mapdispatchtoprops-object-dispatch-ownprops-object
 *
 * https://redux.js.org/api/store#dispatch
 *
 * https://redux.js.org/glossary#action
 *
 * **Note**:
 *
 * Action function `doWork` could be dispatched in that way `this.props.doWork("paramValue")` instead of `this.props.dispatch(doWork("paramValue"))`.
 */
const mapDispatchToProps = {
    navigateToAction,
    updateUrlAction,
    updateSearchCriteriaAction,
    fetchBuildingsAction,
};

RoomsPage.propTypes = {
    location: PropTypes.object,
    entities: PropTypes.array,
    isSearchingRooms: PropTypes.bool,
    buildings: PropTypes.array,

    fetchBuildingsAction: PropTypes.func,
    updateSearchCriteriaAction: PropTypes.func,
    updateUrlAction: PropTypes.func,
    navigateToAction: PropTypes.func,
};

/**
 * Connects React component to the Redux store.
 *
 * https://react-redux.js.org/api/connect#connect
 *
 * **Usage**:
 *
 * `export default connect(mapReduxStateToProps, mapDispatchToProps)(HomePage);`
 * `export default connect(null, mapDispatchToProps)(HomePage);`
 * `export default connect(mapReduxStateToProps)(HomePage);`
 * `export default connect()(HomePage);`
 */
export default connect(mapReduxStateToProps, mapDispatchToProps)(RoomsPage);
