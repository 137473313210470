export const TENANTS_FETCHING = 'api/sharing/tenants/list/FETCHING';
export const TENANTS_FETCHED = 'api/sharing/tenants/list/FETCHED';
export const TENANTS_FETCH_FAILED = 'api/sharing/tenants/list/FETCH_FAILED';

export const fetchEntitiesAction = () => ({
    type: TENANTS_FETCHING,
    payload: {
    },
});

export const fetchEntitiesResultAction = (entities) => ({
    type: TENANTS_FETCHED,
    payload: {
        entities,
    },
});

export const fetchEntitiesFailedAction = (error) => ({
    type: TENANTS_FETCH_FAILED,
    payload: {
        error,
    },
});
