import React from 'react';
import {connect} from 'react-redux';
import {CallbackComponent} from 'redux-oidc';
import {navigateToAction, navigateToLocationAction, unrecoverableErrorAction} from '../_app/actions';
import TextWithSpinner from "../components/text-with-spinner/TextWithSpinner";
import userManagerPromise from "./userManager";

/**
 * Callback page that is called by OAuth2 Identity Provider.
 *
 * Used by `redux-oidc` package.
 *
 * Inspired by https://github.com/maxmantz/redux-oidc-example
 *
 * **Example**:
 *
 * /callback?code=AAAAAAAAAAAAAAAAAAAAAA.JQ...fQ&state=d2...4a
 */
class CallbackPage extends React.Component {
    constructor() {
        super();
        this.state = { userManager: null };
    }

    async componentDidMount() {
        const userManager = await userManagerPromise;
        this.setState({ userManager });
    }

    render = () => {
        if (!this.state.userManager) return <></>

        return (<CallbackComponent
            userManager={this.state.userManager}
            successCallback={this.successCallback}
            errorCallback={this.errorCallback}>
            <TextWithSpinner>Redirecting...</TextWithSpinner>
        </CallbackComponent>);
    };

    successCallback = user => {
        this.props.navigateToLocationAction(user.state.location);
    };

    errorCallback = error => {
        this.props.unrecoverableErrorAction(`Login error: ${error.message}`);
    };
}

const mapDispatchToProps = {
    navigateToAction,
    navigateToLocationAction,
    unrecoverableErrorAction,
};

export default connect(null, mapDispatchToProps)(CallbackPage);
