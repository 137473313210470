import * as React from 'react';
import {connect} from 'react-redux';
import * as PropTypes from 'prop-types';
import * as yup from 'yup';
import {Address4} from 'ip-address';
import MacAddress from 'is-mac-address';
import {MODEL_STATE} from '../../lib/MODEL_STATE';
import { createEntityAction, saveEntityAction } from "../../api/equipment/vc-codecs/actions";
import {selectItemById} from "../../api/selectors";
import {ErrorShape} from "../../_app/appPropShapes";
import {VcCodecShape} from "../../api/equipment/vc-codecs/shapes";
import ModelEditor from '../model-editor/ModelEditor';

const codecModels = [
    "LifeSize Icon 400",
    "LifeSize Express 220",
    "LifeSize Icon 600",
    "Cisco Webex Room Kit",
    "Cisco Webex Room Kit Mini",
    "Cisco Webex Codec Plus",
    "Cisco Webex Codec Pro",
    "Cisco Webex Room 55",
    "Cisco Webex Room 70 Dual",
    "Cisco Webex Room 70 Single",
    "Cisco TelePresence SX80",
];

const validationSchema = yup.object().shape({
    macAddress: yup.string().label('MAC Address').test('macAddressFormat', 'MAC Address is not a valid hardware address', value => MacAddress.isMACAddress(value)).required(),
    ipAddress: yup.string().label('IP Address').test('ipAddressFormat', 'IP Address is not a valid IPv4 address', value => new Address4(value).isValid()).required(),
    model: yup.string().label('Model Type').oneOf(codecModels).required(),
    vsatDeviceNumber: yup.string().label('VSAT Device Number').required(),
});

class VcCodecEditorView extends React.Component {
    grid= [
        { fieldName: 'model', label: 'Model Name', size: 6, options: VcCodecEditorView.allModels() },
        { fieldName: 'vsatDeviceNumber', label: 'VSAT Device Number', size: 6 },
        { fieldName: 'macAddress', label: 'MAC Address', size: 6,/* mask: '**:**:**:**:**:**'*/ },
        { fieldName: 'ipAddress', label: 'IP Address', size: 6,/* mask: '999.999.999.999'*/ },
    ];

    constructor(props) {
        super(props);

        this.state = {
            entity: {...props.entity},
            modelErrors: {},
        };
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.saveError && this.props.modelState !== prevProps.modelState && this.props.modelState === MODEL_STATE.SYNCED) {
            this.props.onSave();
        }
    }

    static allModels = () => {
        const models = codecModels
            .sort((a, b) => a.localeCompare(b))
            .map(value => ({
                text: value,
                value: value,
                key: value,
            }));
        const result = [
            { text: '- Select a model -', value: '', key: '' },
            ...models,
        ];
        return result;
    };

    onSave = entity => {
        if (!!entity.lastUpdatedTimestamp) {
            this.props.saveEntityAction(entity);
        } else {
            this.props.createEntityAction(entity);
        }
    };

    render = () =>
        <ModelEditor
            legend="VC Codec"
            model={this.state.entity}
            modelSchema={validationSchema}
            grid={this.grid}
            onSave={this.onSave}
            onCancel={this.props.onCancel}
            isSaving={this.props.modelState === MODEL_STATE.SYNCING}
            saveError={this.props.saveError}
        />;
}

const mapDispatchToProps = {
    createEntityAction,
    saveEntityAction,
};

const mapStateToProps = (state, ownProps) => ({
    modelState: selectItemById(state.pages.roomEdit.equipment.vcCodecStates, ownProps.entity.id),
    saveError: selectItemById(state.pages.roomEdit.equipment.vcCodecErrors, ownProps.entity.id),
});

VcCodecEditorView.propTypes = {
    entity: PropTypes.shape(VcCodecShape).isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,

    // Actions
    createEntityAction: PropTypes.func,
    saveEntityAction: PropTypes.func,

    // Redux
    modelState: PropTypes.oneOf(Object.values(MODEL_STATE)),
    saveError: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(VcCodecEditorView);
