import _ from 'lodash';
import moment from "moment";
// import { fetch } from 'cross-fetch';
import * as HttpStatus from "http-status-codes";
import {call, put, select, delay} from 'redux-saga/effects';
import {getUser} from '../selectors';
import {API_BASE_URL} from '../constants';
import {buildResponseError} from "../_app/errors";
import {throwApplicationError} from "../helpers";
import userManagerPromise from "../_auth/userManager";

// `yield` resolves a `Promise`

/**
 * Makes GET API call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Deserializes JSON
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @returns {object} API response.
 */
export function* httpGet(route) {
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`, {
            method: 'GET',
        }));
}

/**
 * Makes POST API call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Serializes request JSON
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @param {object} entity Request payload (will be wrapped into JSON:API DTO)
 * @returns {object} API response.
 */
export function* httpPost(route, entity) {
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(entity),
        }));
}

/**
 * Makes PUT API call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Serializes request JSON
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @param {object} entity Request payload (will be wrapped into JSON:API DTO)
 * @returns {object} API response.
 */
export function* httpPut(route, entity) {
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(entity),
        }));
}

/**
 * Makes POST API call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Serializes request JSON
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @param {object} entity Request payload (will be wrapped into JSON:API DTO)
 * @returns {object} API response.
 */
export function* httpPatch(route, entity) {
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(entity),
        }));
}

/**
 * Makes DELETE API call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @returns {object} API response.
 */
export function* httpDelete(route) {
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`,
            {
                method: 'DELETE',
            },
        ),
    );
}

/**
 * Makes JSON:API POST call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Packs entity to JSON:API DTO
 * - Serializes request JSON
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @param {object} entity Request payload (will be wrapped into JSON:API DTO)
 * @returns {object} API response.
 */
export function* httpJsonApiPost(route, entity) {
    // IMPORTANT: entity has to have '__type' attribute set
    const dto = packJsonApiDto(entity);
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
            body: JSON.stringify(dto),
        }));
}

/**
 * Makes JSON:API PATCH call via `fetch()`
 * - Adds `Authorization` token with `Bearer` token
 * - Packs entity to JSON:API DTO
 * - Serializes request JSON
 * - Handles HTTP errors
 * @param {string} route Request URL.
 * @param {object} entity Request payload (will be wrapped into JSON:API DTO)
 * @returns {object} API response.
 */
export function* httpJsonApiPatch(route, entity) {
    // IMPORTANT: entity has to have 'id' and '__type' attributes set
    const dto = packJsonApiDto(entity);
    return yield* callApi(
        new Request(`${API_BASE_URL}${route}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
                body: JSON.stringify(dto),
            },
        ));
}

/**
 * Makes an API call via `fetch()`:
 * - Adds `Authorization` token with `Bearer` token
 * - Handles HTTP errors
 * @param {Request} request API request.
 * @returns {object} API response.
 */
export default function* callApi(request) {
    try {
        const userManager = yield userManagerPromise;

        const token = yield call(getAccessToken);

        if (token) {
            request.headers.append('Authorization', `Bearer ${token}`);

            const response = yield call(fetch, request);

            if (response && response.ok) {
                return yield response;
            } else {
                if (response.statusCode === HttpStatus.UNAUTHORIZED) {
                    // eslint-disable-next-line
                    debugger/*error*/;
                    console.error(`401: ${response.message}`);

                    // TODO: Extract silent renew to saga

                    // TODO: Test and fix
                    // TODO: Take a look on https://github.com/maxmantz/redux-oidc/issues/48
                    // TODO: Take a look on https://stackoverflow.com/questions/43938570/adding-silent-renew-entry-point-to-reactcreate-react-app
                    // TODO: Take a look on https://github.com/maxmantz/redux-oidc/issues/86
                    // Inspired by https://github.com/maxmantz/redux-oidc/issues/43
                    userManager
                        .signinSilent()
                        // eslint-disable-next-line no-unused-vars
                        .then(user => {
                                // Nothing to do, handled by oidc-client-js internally
                            },
                            err => {
                                // eslint-disable-next-line no-debugger
                                debugger/*error*/;
                                userManager.events.addSilentRenewError(err);
                            });

                    // TODO: Retry the API call
                } else {
                    // TODO: Handle 4xx and 5xx responses
                }
            }

            if (request.method !== 'GET') {
                // TODO: Check duration. If it's less than 600, then delay
                yield delay(600);
            }

            response.method = request.method;
            return response;
        } else {
            // Should never happen
            // eslint-disable-next-line no-debugger
            debugger/*error*/;
            console.error('User token is null');
            // TODO: Report error to Application Insights
        }
    } catch (error) {
        console.error(error);
        // TODO: Report error to Application Insights

        throw error;
    }

    return null;
}

export function* processJsonApiResponse(response, resultActionLambda, failedActionLambda) {
    return yield* processResponse(response, resultActionLambda, failedActionLambda, true);
}

export function* processApiResponse(response, resultActionLambda, failedActionLambda) {
    return yield* processResponse(response, resultActionLambda, failedActionLambda, false);
}

function* processResponse(response, resultActionLambda, failedActionLambda, isJsonApi = false) {
    let error;

    if (!!response && !!response.ok) {
        // Reading the response body
        const responseBody = yield response.text();

        let result = {};

        if (responseBody) {
            // Parse response text from JSON
            const dto = JSON.parse(responseBody);
            // If JSON:API call, unpack the DTO
            result = isJsonApi ? unpackJsonApiDto(dto) : dto;
        } else {
            result.id = response.__id;
        }

        // Success
        if (!!resultActionLambda) {
            // Dispatch successful result action (entity | entities | ID)
            yield put(resultActionLambda(result));
        } else {
            // If no result action is provided -- just return the value
            return result;
        }

        return;
    }

    // Failure

    let responseJson = null;

    try {
        responseJson = yield response.json();
    } catch (e) {
        debugger/*error*/;
        console.warn(e);
    }

    error = buildResponseError(response, responseJson);

    if (failedActionLambda) {
        const failedAction = failedActionLambda(error);
        if (!!failedAction) {
            yield put(failedAction);
        }
    } else {
        throw error;
    }
}

/**
 * Gets access token from the Redux store.
 * @returns {IterableIterator<string>} Access token or null.
 */
function* getAccessToken() {
    // https://redux-saga.js.org/docs/api/#selectselector-args

    // @ts-ignore select type
    // noinspection JSValidateJSDoc,JSValidateTypes
    /**
     * @type Oidc.User
     */
    const user = yield select(getUser);
    return user ?
        user.access_token :
        null;
}

/**
 * Unpacks a JSON:API DTO.
 * @param dto
 * @returns object|object[] Entity/entities fetched from API.
 */
export function unpackJsonApiDto(dto) {
    if (_.isArray(dto.data)) {
        return dto.data.map(i => buildEntityFromDto(i));
    } else {
        return buildEntityFromDto(dto.data);
    }
}

/**
 * Packs a JSON:API DTO.
 * @param entity
 * @returns DTO for JSON:API.
 */
export function packJsonApiDto(entity) {
    const dto = {
        data: {
            id: entity.id,
            type: entity.__type,
            attributes: {
                payload: entity,
            },
        },
    };
    dto.data.attributes.payload.lastUpdatedBy = 'UI';
    return dto;
}

/**
 * Builds a UI entity from API DTO.
 * @param data JSON:API data
 * @returns object UI entity
 */
function buildEntityFromDto(data) {
    const entity = data.attributes.payload;
    entity.id = data.id;
    entity.__type = data.type;
    entity.__loadedAt = moment();
    return entity;
}

/**
 * Replacement for `xxxFailedAction`
 * @param message Error message
 * @param title Error title
 * @returns {Function} Callback to replace `xxxFailedAction`
 */
export function throwOnError(message, title) {
    // TODO: process `error` object
    return error => {
        throwApplicationError(message, title);
    };
}
