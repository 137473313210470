import * as React from 'react';
import * as PropTypes from 'prop-types';
import {ApiDtoShape} from "../../../../api/shapes";
import AirMediaEditorView from "../../../../components/air-media-editor-view/AirMediaEditorView";
import VcCodecEditorView from '../../../../components/vc-codec-editor-view/VcCodecEditorView';
import {API_RESOURCES} from "../../../../api/apiRoutes";

export function EditVcDevice(props) {
    let EditorView = null;

    switch (props.entity.__type) {
        case API_RESOURCES.equipment.airMediaUnits.type:
            EditorView = AirMediaEditorView;
            break;
        case API_RESOURCES.equipment.vcCodecs.type:
            EditorView = VcCodecEditorView;
            break;
        default:
            // noinspection JSConstructorReturnsPrimitive
            return '';
    }

    return <EditorView
        entity={props.entity}
        onCancel={props.onCancel}
        onSave={props.onSave}
    />;
}

EditVcDevice.propTypes = {
    entity: PropTypes.shape(ApiDtoShape).isRequired,

    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
};
