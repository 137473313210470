import _ from 'lodash';
import React from 'react';
import * as PropTypes from 'prop-types';
import Alert from '@bookingcom/bui-react/components/Alert';
import Text from '@bookingcom/bui-react/components/Text';
import {ErrorShape} from "./appPropShapes";
import {RenderIf} from "../components/render-if/RenderIf";

export const ErrorMessage = props =>
    <RenderIf condition={!!props.error} render={() =>
        <div className="bui-container bui-container--center s-main-body">
            <Alert variant="error" size="large" title={props.error.title} className="mrt-error-message">
                <Text variant="featured">{props.error.message}</Text>
                <RenderIf condition={!_.isEmpty(props.error.reloadLink)} render={() =>
                    <p><strong>Reload the application by clicking the <a href={props.error.reloadLink}>link</a></strong></p>}
                />
                {props.error.detailsNode}
            </Alert>
        </div>}
    />;

ErrorMessage.propTypes = {
    error: PropTypes.shape(ErrorShape),
};
