import _ from 'lodash';
import {put, select, takeLatest} from 'redux-saga/effects';
import {httpGet, processApiResponse} from '../helpers';
import {API} from "../apiRoutes";
import {fetchUserNotificationsResultAction, USER_NOTIFICATIONS_FETCHING} from './actions';

function* fetchUserNotifications() {
    // Check whether do we have entities cached already
    let entities = yield select(state => state.api.userNotifications.entities);

    if (_.isEmpty(entities)) {
        // If no entities,
        const response = yield httpGet(API.userNotifications.list);
        yield* processApiResponse(
            response,
            fetchUserNotificationsResultAction,
            () => console.error("Failed to load user notifications from API"));
    }
    else {
        yield put(fetchUserNotificationsResultAction(entities));
    }
}


export const userNotificationsSagas = [
    takeLatest(USER_NOTIFICATIONS_FETCHING, fetchUserNotifications),
];
