import {
    VC_CODECS_FETCHED,
    VC_CODEC_CREATED,
    VC_CODEC_SAVED,
    VC_CODEC_DELETED
} from './actions';
import {deleteEntity, upsertEntities, upsertEntity} from '../../../reducerHelpers';
import {normalizeEntities, normalizeMacAddress} from '../../../normalizers';

const initialState = {
    entities: {},
};

export const vcCodecsApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case VC_CODECS_FETCHED: {
            const fetchedEntities = upsertEntities(reducerState.entities, action.payload.entities);
            const entities = normalizeEntities(fetchedEntities, normalizeEntity);
            return {
                ...reducerState,
                entities,
            };
        }
        case VC_CODEC_CREATED:
        case VC_CODEC_SAVED: {
            const entity = {...action.payload.entity};
            normalizeEntity(entity);
            return {
                ...reducerState,
                entities: upsertEntity(reducerState.entities, entity.id, entity),
            };
        }
        case VC_CODEC_DELETED: {
            return {
                ...reducerState,
                entities: deleteEntity(reducerState.entities, action.payload.entity.id),
            };
        }
        default:
            return reducerState;
    }
};

const normalizeEntity = entity => {
    entity.macAddress = normalizeMacAddress(entity.macAddress);
};
