import _ from 'lodash';
import {AUDIT_LOG_FETCHED} from './actions';

const initialState = {
    entitiesByType: {},
};

export const auditLogsApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case AUDIT_LOG_FETCHED: {
            const entitiesByType = { ...reducerState.entitiesByType };
            // If necessary keys are absent,
            if (!action.payload.resourceEntityType || !action.payload.resourceEntityId) {
                // Bypass
                return reducerState;
            }

            if (!entitiesByType[action.payload.resourceEntityType]) {
                entitiesByType[action.payload.resourceEntityType] = {};
            }

            const entries = _.orderBy(action.payload.entities, ['lastUpdatedTimestamp'], ['desc']);
            entitiesByType[action.payload.resourceEntityType][action.payload.resourceEntityId] = entries;

            return  {
                ...reducerState,
                entitiesByType,
            };
        }
        default:
            return reducerState;
    }
};
