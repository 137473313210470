// Swagger UI: https://localhost:44392/swagger/

import * as qs from "qs";

export const API_RESOURCES = Object.freeze({
    appConfig: {
        type: 'AppConfig',
        endpoint: '/app-config',
    },
    userNotifications: {
        type: 'UserNotification',
        endpoint: '/user-notifications',
    },
    auditLogs: {
        type: 'AuditLogEntry',
        endpoint: '/audit-logs',
    },
    rooms: {
        type: 'MeetingRoom',
        endpoint: '/meeting-rooms',
    },
    buildings: {
        type: 'Building',
        endpoint: '/buildings',
    },
    equipment: {
        export: {
            endpoint: '/equipment/export',
        },
        vcCodecs: {
            type: 'VcCodec',
            endpoint: '/equipment/vc-codecs',
        },
        airMediaUnits: {
            type: 'AirMediaUnit',
            endpoint: '/equipment/air-media-units',
        },
    },
    sharing: {
        requests: {
            type: 'SharingRequest',
            endpoint: '/sharing-rooms',
        },
        tenants: {
            type: 'Tenant',
            endpoint: '/tenants'
        }
    },
    roomMaintenance: {
        type: 'RoomMaintenanceWindow',
        endpoint: '/room-maintenance',
    },
    accessControl: {
        meetingRoomAccessControl: {
            type: 'AccessControl',
            endpoint: '/access-control',
        },
        enableSmartAclManagement: {
            type: 'EnableSmartAclManagement',
            endpoint: '/access-control/enable-smart-acl-management'
        },
        enableMeetingRoom: {
            type: 'EnableMeetingRoom',
            endpoint: '/access-control/enable-meeting-room'
        },
        disableMeetingRoom: {
            type: 'DisableMeetingRoom',
            endpoint: '/access-control/disable-meeting-room'
        },
        dedicateMeetingRoom: {
            type: 'DedicateMeetingRoom',
            endpoint: '/access-control/dedicate-meeting-room'
        },
        unDedicateMeetingRoom: {
            type: 'UnDedicateMeetingRoom',
            endpoint: '/access-control/un-dedicate-meeting-room'
        }
    }
});

export const API = Object.freeze({
    appConfig: {
        endpoint: API_RESOURCES.appConfig.endpoint,
    },
    userNotifications: {
        list: API_RESOURCES.userNotifications.endpoint,
    },
    auditLog: {
        forEntity: (entityType, entityId) => `${API_RESOURCES.auditLogs.endpoint}?filter[isDeleted]=eq:false&filter[resourceEntityType]=eq:${entityType}&filter[resourceEntityId]=eq:${entityId}&sort=-lastUpdatedTimestamp&page[size]=200`,
    },
    buildings: {
        list: fetchAll(API_RESOURCES.buildings.endpoint),
    },
    rooms: {
        endpoint: API_RESOURCES.rooms.endpoint,
        byId: id => `${API_RESOURCES.rooms.endpoint}/${id}`,
        byCode: code => `${API_RESOURCES.rooms.endpoint}/by-code/${code}`,
        searchWithBuildingId: (buildingId, searchQuery) => `${API_RESOURCES.rooms.endpoint}?filter[isDeleted]=eq:false&filter[building.id]=eq:${buildingId}&filter[name]=like:${searchQuery}&page[size]=1000`,
        search: (searchQuery) => `${API_RESOURCES.rooms.endpoint}?filter[isDeleted]=eq:false&filter[name]=like:${searchQuery}&page[size]=1000`,
    },
    roomMaintenance: {
        endpoint: API_RESOURCES.roomMaintenance.endpoint,
        list: fetchAll(API_RESOURCES.roomMaintenance.endpoint, true),
        calculation: `${API_RESOURCES.roomMaintenance.endpoint}/calculation`,
        byId: id => `${API_RESOURCES.roomMaintenance.endpoint}/${id}`,
        byRoomId: roomId => `${API_RESOURCES.roomMaintenance.endpoint}/?filter[meetingRoom.id]=eq:${roomId}`,
    },
    equipment: {
        export: API_RESOURCES.equipment.export.endpoint,
        vcCodecs: {
            endpoint: API_RESOURCES.equipment.vcCodecs.endpoint,
            byRoomId: roomId => `${API_RESOURCES.equipment.vcCodecs.endpoint}?filter[isDeleted]=eq:false&filter[meetingRoomId]=eq:${roomId}`,
            byId: id => `${API_RESOURCES.equipment.vcCodecs.endpoint}/${id}`,
        },
        airMediaUnits: {
            endpoint: API_RESOURCES.equipment.airMediaUnits.endpoint,
            byRoomId: roomId => `${API_RESOURCES.equipment.airMediaUnits.endpoint}?filter[isDeleted]=eq:false&filter[meetingRoomId]=eq:${roomId}`,
            byId: id => `${API_RESOURCES.equipment.airMediaUnits.endpoint}/${id}`,
        },
    },
    sharing: {
        tenants: {
            list: fetchAll(API_RESOURCES.sharing.tenants.endpoint, true),
        },
        requests: {
            endpoint: API_RESOURCES.sharing.requests.endpoint,
            byRoomId: roomId => `${API_RESOURCES.sharing.requests.endpoint}?roomId=${roomId}`
        }
    },
    accessControl: {
        meetingRoomAccessControl: {
            endpoint: API_RESOURCES.accessControl.meetingRoomAccessControl.endpoint,
            byRoomId: roomId => `${API_RESOURCES.accessControl.meetingRoomAccessControl.endpoint}/${roomId}`
        },
        enableSmartAclManagement: {
            endpoint: API_RESOURCES.accessControl.enableSmartAclManagement.endpoint
        },
        enableMeetingRoom: {
            endpoint: API_RESOURCES.accessControl.enableMeetingRoom.endpoint
        },
        disableMeetingRoom: {
            endpoint: API_RESOURCES.accessControl.disableMeetingRoom.endpoint
        },
        dedicateMeetingRoom: {
            endpoint: API_RESOURCES.accessControl.dedicateMeetingRoom.endpoint
        },
        unDedicateMeetingRoom: {
            endpoint: API_RESOURCES.accessControl.unDedicateMeetingRoom.endpoint
        }
    }
});

function fetchAll(route, doFetchDeleted = false) {
    if (route.indexOf('?') > 0) throw new Error('"route" should not contain any query string');

    let queryStringObj = {
        filter: [],
        page: [],
    };

    if (!doFetchDeleted) {
        queryStringObj.filter['isDeleted'] = 'eq:false';
    }

    queryStringObj.page['size'] = 3000;

    const queryStringStr = qs.stringify(queryStringObj);
    return `${route}?${queryStringStr}`;
}
