import * as PropTypes from 'prop-types';
import {ApiDtoShape} from "../../shapes";

export const AirMediaUnitShape = {
    ...ApiDtoShape,
    model: PropTypes.string,
    ipAddress: PropTypes.string,
    macAddress: PropTypes.string,
    vsatDeviceNumber: PropTypes.string,
    meetingRoomId: PropTypes.string,
    googleCalendarEmail: PropTypes.string,
    buildingCode: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
};
