import * as PropTypes from 'prop-types';
import {SyncStatusShape} from "../shapes";
import {BuildingShape} from "../buildings/shapes";

export const AclRuleShape = {
    googleId: PropTypes.string,
    role: PropTypes.string,
    scopeType: PropTypes.string,
    scopeValue: PropTypes.string,
};

export const MeetingRoomGoogleCalendarShape = {
    calendarId: PropTypes.string,
    calendarEmail: PropTypes.string,
    isEnabled: PropTypes.bool,
    aclRules: PropTypes.arrayOf(PropTypes.shape(AclRuleShape)),
    syncStatus: PropTypes.shape(SyncStatusShape),
};

export const MeetingBotShape = {
    isEnabled: PropTypes.bool,
};

export const RecurringEventsLimiterShape = {
    // TODO: Define
};

export const MeetingRoomShape = {
    building: PropTypes.shape(BuildingShape),
    name: PropTypes.string,
    emoji: PropTypes.string,
    floor: PropTypes.string,
    floorSection: PropTypes.string,
    number: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    capacity: PropTypes.number,
    features: PropTypes.arrayOf(PropTypes.string),
    isEnabled: PropTypes.bool,
    isEnabledMessage: PropTypes.string,
    googleCalendar: PropTypes.shape(MeetingRoomGoogleCalendarShape),
    meetingBot: PropTypes.shape(MeetingBotShape),
    recurringEventsLimiter: PropTypes.shape(RecurringEventsLimiterShape),
};
