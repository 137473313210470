export const VC_CODECS_FETCHING = 'api/equipment/vc-codecs/list/FETCHING';
export const VC_CODECS_FETCHED = 'api/equipment/vc-codecs/list/FETCHED';
export const VC_CODECS_FETCH_FAILED = 'api/equipment/vc-codecs/list/FETCH_FAILED';

export const VC_CODEC_SAVING = 'api/equipment/vc-codecs/entity/SAVING';
export const VC_CODEC_SAVED = 'api/equipment/vc-codecs/entity/SAVED';
export const VC_CODEC_SAVE_FAILED = 'api/equipment/vc-codecs/entity/SAVE_FAILED';

export const VC_CODEC_CREATING = 'api/equipment/vc-codecs/entity/CREATING';
export const VC_CODEC_CREATED = 'api/equipment/vc-codecs/entity/CREATED';
export const VC_CODEC_CREATE_FAILED = 'api/equipment/vc-codecs/entity/CREATE_FAILED';

export const VC_CODEC_DELETING = 'api/equipment/vc-codecs/entity/DELETING';
export const VC_CODEC_DELETED = 'api/equipment/vc-codecs/entity/DELETED';
export const VC_CODEC_DELETE_FAILED = 'api/equipment/vc-codecs/entity/DELETE_FAILED';

export const fetchEntitiesAction = roomId => ({
    type: VC_CODECS_FETCHING,
    payload: {
        roomId,
    },
});

export const fetchEntitiesResultAction = (roomId, entities) => ({
    type: VC_CODECS_FETCHED,
    payload: {
        roomId,
        entities,
    },
});

export const fetchEntitiesFailedAction = (roomId, error) => ({
    type: VC_CODECS_FETCH_FAILED,
    payload: {
        roomId,
        error,
    },
});

export const createEntityAction = entity => ({
    type: VC_CODEC_CREATING,
    payload: {
        entity,
    },
});

export const createEntityResultAction = entity => ({
    type: VC_CODEC_CREATED,
    payload: {
        entity,
    },
});

export const createEntityFailedAction = (entity, error) => ({
    type: VC_CODEC_CREATE_FAILED,
    payload: {
        entity,
        error,
    },
});

export const saveEntityAction = entity => ({
    type: VC_CODEC_SAVING,
    payload: {
        entity,
    },
});

export const saveEntityResultAction = entity => ({
    type: VC_CODEC_SAVED,
    payload: {
        entity,
    },
});

export const saveEntityFailedAction = (entity, error) => ({
    type: VC_CODEC_SAVE_FAILED,
    payload: {
        entity,
        error,
    },
});

export const deleteEntityAction = entity => ({
    type: VC_CODEC_DELETING,
    payload: {
        entity,
    },
});

export const deleteEntityResultAction = entity => ({
    type: VC_CODEC_DELETED,
    payload: {
        entity,
    },
});

export const deleteEntityFailedAction = (entity, error) => ({
    type: VC_CODEC_DELETE_FAILED,
    payload: {
        entity,
        error,
    },
});
