import {takeLatest} from "@redux-saga/core/effects";
import {httpPost, processApiResponse} from "../helpers";
import {API} from "../apiRoutes";
import {
    EQUIPMENT_EXPORT_TRIGGERING,
    triggerEquipmentExportResultAction,
    triggerEquipmentExportFailedAction
} from "./actions";
import {vcCodecsSagas} from "./vc-codecs/sagas";
import {airMediaUnitsSagas} from "./air-media-units/sagas";

function* triggerEquipmentExport(action) {
    const response = yield httpPost(API.equipment.export, {});
    yield* processApiResponse(
        response,
        triggerEquipmentExportResultAction,
        triggerEquipmentExportFailedAction,
    );
}

export const equipmentSagas = [
    takeLatest(EQUIPMENT_EXPORT_TRIGGERING, triggerEquipmentExport),
    ...vcCodecsSagas,
    ...airMediaUnitsSagas,
];
