import {put, takeEvery} from 'redux-saga/effects';
import {
    fetchEntityAction as fetchMeetingRoomAccessControlAction,
    ACCESS_CONTROL_SMART_ACL_ENABLED,
    ACCESS_CONTROL_MEETING_ROOM_ENABLED,
    ACCESS_CONTROL_MEETING_ROOM_DISABLED,
    ACCESS_CONTROL_MEETING_ROOM_DEDICATED,
    ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED
} from '../../../api/access-control/actions';

function* reFetchMeetingRoomAccessControl(action) {
    const entity = {...action.payload.entity};
    yield put(fetchMeetingRoomAccessControlAction(entity.roomId));
}

export const accessControlViewSagas = [
    takeEvery(ACCESS_CONTROL_SMART_ACL_ENABLED, reFetchMeetingRoomAccessControl),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_ENABLED, reFetchMeetingRoomAccessControl),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_DISABLED, reFetchMeetingRoomAccessControl),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_DEDICATED, reFetchMeetingRoomAccessControl),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED, reFetchMeetingRoomAccessControl),
];
