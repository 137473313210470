import _ from "lodash";
import {
  MAINTENANCE_CALCULATION_FETCHED, MAINTENANCE_WINDOW_CREATED, MAINTENANCE_WINDOW_DELETED, MAINTENANCE_WINDOW_FETCHED,
  MAINTENANCE_WINDOW_SAVED,
  MAINTENANCE_WINDOWS_FETCHED
} from "./actions";

const initialState = {
  entities: [],
  calculationResult: null,
};

const upsertEntity = (entities, entity) => {
  const entitiesClone = _.clone(entities);
  const entityClone = _.cloneDeep(entity);

  const index = _.findIndex(entitiesClone, r => r.id === entity.id);
  // Room not found
  if (index === -1) {
    // Insert
    entitiesClone.unshift(entityClone);
  } else {
    // Room found. Replace
    entitiesClone[index] = entityClone;
  }
  return entitiesClone;
};

export const roomMaintenanceApiReducer = (reducerState = initialState, action) => {
  switch (action.type) {
    case MAINTENANCE_WINDOWS_FETCHED:
      // Don't use `const newState`. It doesn't work in `switch`
      if (!_.isEmpty(action.payload.entities)) {
        return {...reducerState, entities: action.payload.entities};
      }

      return reducerState;

    case MAINTENANCE_CALCULATION_FETCHED:
      // Don't use `const newState`. It doesn't work in `switch`
      if (!_.isEmpty(action.payload.calculationResult)) {
        return {...reducerState, calculationResult: action.payload.calculationResult};
      }
      else {
        return {...reducerState, calculationResult: null};
      }

    case MAINTENANCE_WINDOW_FETCHED:
    case MAINTENANCE_WINDOW_CREATED:
    case MAINTENANCE_WINDOW_SAVED: {
      const newState = {...reducerState};
      newState.entities = upsertEntity(newState.entities, action.payload.entity);
      return newState;
    }

    case MAINTENANCE_WINDOW_DELETED: {
      const newState = {...reducerState, deleteResult: action.payload};
      newState.entities = upsertEntity(newState.entities, action.payload.entity);
      return newState;
    }

    default:
      return reducerState;
  }
};
