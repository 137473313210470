import React from 'react';
import { connect } from 'react-redux';
import Text from "@bookingcom/bui-react/components/Text";
import { logoutAction } from '../_app/actions';

class LogoutPage extends React.Component {
  componentDidMount() {
    this.props.logoutAction();
  }

  render() {
    return (
        <Text variant="display_three">Logged out</Text>
    );
  }
}

export default connect(null, { logoutAction })(LogoutPage);
