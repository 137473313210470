import _ from 'lodash';
import {put, select, takeLatest} from 'redux-saga/effects';
import {httpGet, processJsonApiResponse, throwOnError} from '../helpers';
import {API} from "../apiRoutes";
import {BUILDINGS_FETCHING, fetchBuildingsResultAction} from './actions';

function* fetchBuildings() {
    // Check whether do we have entities cached already
    let entities = yield select(state => state.api.buildings.entities);

    if (_.isEmpty(entities)) {
        // If no entities,
        const response = yield httpGet(API.buildings.list);
        yield* processJsonApiResponse(
            response,
            fetchBuildingsResultAction,
            throwOnError("Failed to fetch buildings from the API"));
    }
    else {
        yield put(fetchBuildingsResultAction(entities));
    }
}


export const buildingsSagas = [
    takeLatest(BUILDINGS_FETCHING, fetchBuildings),
];
