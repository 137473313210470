import * as _ from "lodash";
import {
    MAINTENANCE_WINDOW_DELETING,
    MAINTENANCE_WINDOW_DELETED,
    MAINTENANCE_WINDOWS_FETCHED,
    MAINTENANCE_WINDOWS_FETCHING
} from "../../api/room-maintenance/actions";

const initialState = {
    isLoadingEntities: false,
    entitiesBeingDeletedIds: [],
};

// `{...state}` makes a copy of object `state`
// `{ ...state.rooms, ...newRooms }` merges two objects into a new one
// Details: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment

// IMPORTANT: Make sure that you return new copy of a `state`.
// IMPORTANT: Don't mutate objects from `state`. Make a copy and only then mutate them.

export const roomMaintenanceListPageReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case MAINTENANCE_WINDOWS_FETCHING:
            return { ...reducerState, isLoadingEntities: true };

        case MAINTENANCE_WINDOWS_FETCHED:
            return { ...reducerState, isLoadingEntities: false };

        case MAINTENANCE_WINDOW_DELETING: {
            const newState = {...reducerState};
            newState.entitiesBeingDeletedIds.push(action.payload.id);
            return newState;
        }

        case MAINTENANCE_WINDOW_DELETED: {
            const newState = {...reducerState};
            _.pull(newState.entitiesBeingDeletedIds, action.payload.id);
            return newState;
        }

        default:
            /**
             * It's important to return the previous state for any unknown action.
             *
             * https://redux.js.org/basics/reducers
             */
            return reducerState;
    }
};
