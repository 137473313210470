import {takeLatest, takeEvery} from 'redux-saga/effects';
import {select} from "@redux-saga/core/effects";
import {
    httpGet,
    httpJsonApiPatch,
    httpJsonApiPost,
    httpPost,
    processApiResponse,
    processJsonApiResponse
} from '../helpers';
import {API} from "../apiRoutes";
import {
    MAINTENANCE_WINDOWS_FETCHING,
    MAINTENANCE_WINDOW_FETCHING,
    MAINTENANCE_WINDOW_CREATING,
    MAINTENANCE_CALCULATION_FETCHING,
    MAINTENANCE_WINDOW_SAVING,
    MAINTENANCE_WINDOW_DELETING,
    fetchEntitiesResultAction,
    createEntityResultAction,
    createEntityFailedAction,
    fetchCalculationResultAction,
    saveEntityResultAction,
    fetchEntityResultAction,
    deleteEntityResultAction,
} from './actions';
import {ApplicationError} from "../../_app/ApplicationError";
import {obsoleteSelectEntity} from "../selectors";

function* fetchEntities(action) {
    const roomId = action.payload.roomId;

    let url;
    if (!!roomId) {
        url = API.roomMaintenance.byRoomId(roomId);
    }
    else {
        url = API.roomMaintenance.list;
    }

    const response = yield httpGet(url);
    yield* processJsonApiResponse(response,
        fetchEntitiesResultAction,
        error => { throw ApplicationError("Unexpected error", "Failed to fetch room maintenance windows from the API"); });
}

function* fetchEntity(action) {
    const response = yield* httpGet(API.roomMaintenance.byId(action.payload.id));
    yield* processJsonApiResponse(
        response,
        fetchEntityResultAction,
        error => { throw ApplicationError("Unexpected error", `Failed to fetch room maintenance window '${action.payload.id}' from the API`); });
}

function* fetchCalculation(action) {
    const response = yield httpPost(API.roomMaintenance.calculation, action.payload.entity);
    yield* processApiResponse(
        response,
        calculationResult => fetchCalculationResultAction(calculationResult),
        error => { debugger/*error*/; throw ApplicationError("Unexpected error", "Failed to fetch room maintenance calculation from the API"); },
    );
}

function* createEntity(action) {
    const response = yield* httpJsonApiPost(API.roomMaintenance.endpoint, action.payload.entity);
    yield* processJsonApiResponse(response, createEntityResultAction, createEntityFailedAction);
}

function* saveEntity(action) {
    const response = yield* httpJsonApiPatch(API.roomMaintenance.byId(action.payload.entity.id), action.payload.entity);
    yield* processJsonApiResponse(
        response,
        saveEntityResultAction,
        error => { throw ApplicationError("Unexpected error", `Failed to cancel room maintenance window '${action.payload.id}'`); });
}

function* deleteEntity(action) {
    yield* fetchEntity(action);
    const entityToDelete = yield select(state => obsoleteSelectEntity(state.api.roomMaintenance.entities, action.payload.id));
    entityToDelete.isDeleted = true;

    const response = yield* httpJsonApiPatch(API.roomMaintenance.byId(entityToDelete.id), entityToDelete);
    yield* processJsonApiResponse(
        response,
        deleteEntityResultAction,
        error => { throw ApplicationError("Unexpected error", `Failed to cancel room maintenance window '${action.payload.id}'`); });
}

export const roomMaintenanceSagas = [
    takeLatest(MAINTENANCE_WINDOWS_FETCHING, fetchEntities),
    takeEvery(MAINTENANCE_WINDOW_FETCHING, fetchEntity),
    takeEvery(MAINTENANCE_CALCULATION_FETCHING, fetchCalculation),
    takeEvery(MAINTENANCE_WINDOW_CREATING, createEntity),
    takeEvery(MAINTENANCE_WINDOW_SAVING, saveEntity),
    takeEvery(MAINTENANCE_WINDOW_DELETING, deleteEntity),
];
