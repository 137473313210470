import {connect} from 'react-redux';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as yup from 'yup';
import {Address4} from 'ip-address';
import MacAddress from 'is-mac-address';
import {MODEL_STATE} from '../../lib/MODEL_STATE';
import {createEntityAction, saveEntityAction} from "../../api/equipment/air-media-units/actions";
import {selectItemById} from "../../api/selectors";
import {ErrorShape} from "../../_app/appPropShapes";
import {AirMediaUnitShape} from "../../api/equipment/air-media-units/shapes";
import ModelEditor from '../model-editor/ModelEditor';

const validationSchema = yup.object().shape({
    macAddress: yup.string().label('MAC Address').test('macAddressFormat', 'MAC Address is not a valid hardware address', value => MacAddress.isMACAddress(value)).required(),
    ipAddress: yup.string().label('IP Address').test('ipAddressFormat', 'IP Address is not a valid IPv4 address', value => new Address4(value).isValid()).required(),
});

class AirMediaEditorView extends React.Component {
    grid = [
        { fieldName: 'macAddress', label: 'MAC Address', size: 6/*, mask: '**:**:**:**:**:**'*/ },
        { fieldName: 'ipAddress', label: 'IP Address', size: 6/*, mask: '999.999.999.999'*/ },
    ];

    constructor(props) {
        super(props);

        this.state = {
            entity: {...props.entity},
            modelErrors: {},
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.saveError && this.props.modelState !== prevProps.modelState && this.props.modelState === MODEL_STATE.SYNCED) {
            this.props.onSave();
        }
    }

    onSave = entity => {
        if (!!entity.lastUpdatedTimestamp) {
            this.props.saveEntityAction(entity);
        } else {
            this.props.createEntityAction(entity);
        }
    };

    render = () =>
        <ModelEditor
            legend="Air Media Unit"
            model={this.state.entity}
            modelSchema={validationSchema}
            grid={this.grid}
            onSave={this.onSave}
            onCancel={this.props.onCancel}
            isSaving={this.props.modelState === MODEL_STATE.SYNCING}
            saveError={this.props.saveError}
        />;
}

const mapDispatchToProps = {
    createEntityAction,
    saveEntityAction,
};

const mapStateToProps = (state, ownProps) => ({
    modelState: selectItemById(state.pages.roomEdit.equipment.airMediaUnitStates, ownProps.entity.id),
    saveError: selectItemById(state.pages.roomEdit.equipment.airMediaUnitErrors, ownProps.entity.id),
});

AirMediaEditorView.propTypes = {
    entity: PropTypes.shape(AirMediaUnitShape).isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,

    // Actions
    createEntityAction: PropTypes.func,
    saveEntityAction: PropTypes.func,

    // Redux
    modelState: PropTypes.oneOf(Object.values(MODEL_STATE)),
    saveError: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(AirMediaEditorView);
