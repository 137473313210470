import Text from "@bookingcom/bui-react/components/Text";
import React from "react";
import {Alert, Badge, GridColumn} from "@bookingcom/bui-react";
import {RBAC_ROOM_PERMISSIONS} from "../../../constants";
import RbacButton from "../../../components/rbac-button/RbacButton";
import Modal from "@bookingcom/bui-react/components/Modal";
import * as PropTypes from "prop-types";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import Rocket from "@bookingcom/bui-react/icons/Rocket";
import {ErrorShape, UserShape} from "../../../_app/appPropShapes";
import {MeetingRoomAccessControlResponseShape} from "../../../api/access-control/shapes";
import {connect} from "react-redux";
import {
    enableSmartAclAction
} from '../../../api/access-control/actions';
import {
    DedicatingStatuses,
    PassportPoliciesAction,
    SmartAclManagementStatus
} from "./MeetingRoomAccessControlView.constants";
import _ from "lodash";
import Divider from "@bookingcom/bui-react/components/Divider";

class SmartAclManagementDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shouldDisplayAddEntityModal: false,
            isReadyToSubmit: false
        };
    }

    onEnableSmartAclManagementClicked = () => {
        this.setState({shouldDisplayAddEntityModal: true, isReadyToSubmit: true});
    };

    onEnableSmartAclManagementClose = () => {
        this.setState({shouldDisplayAddEntityModal: false, isReadyToSubmit: false});
    }

    enableSmartAclClicked = () => {
        this.setState(
            {
                shouldDisplayAddEntityModal: false,
                isReadyToSubmit: false
            },
            () => {
                this.props.enableSmartAclAction(this.getSmartAclManagementRequest());
            }
        );
    }

    getSmartAclManagementRequest = () => {
        return {
            resourceEmail: this.props.room.googleCalendar.calendarEmail,
            resourceId: this.props.room.googleCalendar.calendarId,
            roomId: this.props.room.id,
            roomCode: this.props.room.code,
            roomName: this.props.room.name,
            roomNumber: this.props.room.number,
            floor: this.props.room.number,
            buildingId: this.props.room.building.code
        };
    }

    showSmartAclManagementAlert = () => {
        const smartAclStatus = this.props.meetingRoomAccessControl.smartAclManagementStatus;
        switch (smartAclStatus.toUpperCase()) {
            case SmartAclManagementStatus.ENABLED: {
                return <Alert
                    title='Enabled!'
                    text="You can now use Passport Policies to manage who has access to book and manage Specific Meeting Rooms."
                    variant='success'
                    className={"bui-spacer"}
                />
            }
            case SmartAclManagementStatus.PROCESSING: {
                return <Alert
                    title='Processing!'
                    text="Smart ACL Management is being processed, it might take up-to 1 business day for completion."
                    variant='info'
                    className={"bui-spacer"}
                />
            }
            default: {
                return <Alert
                    title='Notice!'
                    text="Smart ACL Management uses Passport Policies to manage who has access to book and manage Specific Meeting Rooms."
                    variant=''
                    className={"bui-spacer"}
                />
            }
        }
    }

    getSmartAclBadge = () => {
        switch (this.props.meetingRoomAccessControl.smartAclManagementStatus.toUpperCase()) {
            case SmartAclManagementStatus.ENABLED:
                return <Badge text="Enabled" variant="primary"/>
            case SmartAclManagementStatus.PROCESSING:
                return <Badge text="Processing" variant="callout"/>
            default:
                return <Badge text="Disabled" variant="outline"/>
        }
    }

    showSmartAclManagementButton = () => {
        if (this.props.meetingRoomAccessControl.smartAclManagementStatus.toUpperCase() === SmartAclManagementStatus.DISABLED
            && this.props.meetingRoomAccessControl.isEnabled === false
            && this.props.meetingRoomAccessControl.dedicatingStatus.toUpperCase() === DedicatingStatuses.UN_DEDICATED) {
            return <RbacButton className="bui-spacer" rbacRight={RBAC_ROOM_PERMISSIONS}
                               text="Enable Smart ACL Managmement"
                               icon={<Rocket/>}
                               onClick={this.onEnableSmartAclManagementClicked}
                               disabled={true}/>
        }
        switch (this.props.meetingRoomAccessControl.smartAclManagementStatus.toUpperCase()) {
            case SmartAclManagementStatus.ENABLED:
                return <RbacButton className="bui-spacer" rbacRight={RBAC_ROOM_PERMISSIONS}
                                   text="Enable Smart ACL Managmement"
                                   icon={<Rocket/>}
                                   onClick={this.onEnableSmartAclManagementClicked}
                                   disabled={true}/>
            case SmartAclManagementStatus.PROCESSING:
                return <RbacButton className="bui-spacer" rbacRight={RBAC_ROOM_PERMISSIONS}
                                   text="Enable Smart ACL Managmement"
                                   icon={<Rocket/>}
                                   onClick={this.onEnableSmartAclManagementClicked}
                                   disabled={true}/>
            default:
                return <RbacButton className="bui-spacer" rbacRight={RBAC_ROOM_PERMISSIONS}
                                   text="Enable Smart ACL Managmement"
                                   icon={<Rocket/>}
                                   onClick={this.onEnableSmartAclManagementClicked}
                                   disabled={false}/>
        }
    }

    showPassportPolicy = (passportPolicy) => {
        switch (passportPolicy.action.toUpperCase()) {
            case PassportPoliciesAction.BOOK.action: {
                return <>
                    <Badge text={PassportPoliciesAction.BOOK.label} variant="default"/>
                    <Text variant="caption" href={"https://www.google.com"}
                          className="">{PassportPoliciesAction.BOOK.description}</Text>
                    <Text variant="emphasized"
                          className=""><a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={PassportPoliciesAction.BASE_POLICY_URL + passportPolicy.id}>
                        {passportPolicy.target}</a></Text>
                </>
            }
            default: {
                return <>
                    <Badge text={PassportPoliciesAction.MANAGE.label} variant="default"/>
                    <Text variant="caption"
                          className="">{PassportPoliciesAction.MANAGE.description}</Text>
                    <Text variant="emphasized"
                          className=""><a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={PassportPoliciesAction.BASE_POLICY_URL + passportPolicy.id}>{passportPolicy.target}</a></Text>
                </>
            }
        }
    }

    showPassportPolicies = (passportPolicies) => {
        const result = [];
        if (_.isEmpty(passportPolicies)) {
            return;
        }
        result.push(<GridColumn className="bui-spacer" size="full"><Divider/></GridColumn>);
        result.push(<Text variant="heading">Passport Policies</Text>);
        result.push(<Text className="bui-spacer" variant="caption">These are the passport policy to access the
            meeting room.</Text>);
        _.map(passportPolicies, passportPolicy => {
            result.push(this.showPassportPolicy(passportPolicy));
        });
        return result;
    }

    showPassportPoliciesIfExists = () => {
        switch (this.props.meetingRoomAccessControl.smartAclManagementStatus.toUpperCase()) {
            case SmartAclManagementStatus.PROCESSING:
            case SmartAclManagementStatus.ENABLED: {
                return this.showPassportPolicies(this.props.meetingRoomAccessControl.passportPolicies)
            }
            default:
                return;
        }
    }

    render() {
        return <>
            <Text variant="heading" className="bui-spacer">Smart ACL Management {this.getSmartAclBadge()}</Text>
            {this.showSmartAclManagementAlert()}
            {this.showSmartAclManagementButton()}
            {this.showPassportPoliciesIfExists()}
            <Modal
                title={`Enable Smart ACL Management on ${this.props.room.code}`}
                closeAriaLabel="Close Modal"
                onClose={this.onEnableSmartAclManagementClose}
                active={this.state.shouldDisplayAddEntityModal}
                buttons={[{
                    text: 'Enable', type: 'submit', disabled: !this.state.isReadyToSubmit,
                    onClick: this.enableSmartAclClicked
                }]}
            ><Alert
                title='Notice!'
                text="Smart ACL Management can take up to 1 business days to complete, so please make sure this is a valid use-case."
                variant='info'
            />
            </Modal>
        </>;
    }
}

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    enableSmartAclAction
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
    // User
    user: state.oidc.user,
});

SmartAclManagementDetails.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    // Action
    enableSmartAclAction: PropTypes.func,

    // Redux
    user: PropTypes.shape(UserShape),
    meetingRoomAccessControl: PropTypes.shape(MeetingRoomAccessControlResponseShape),
    accessControlErrors: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(SmartAclManagementDetails);