import React from "react";
import Text from "@bookingcom/bui-react/components/Text";
import {Alert, GridColumn} from "@bookingcom/bui-react";
import RbacButton from "../../../components/rbac-button/RbacButton";
import {RBAC_ROOM_PERMISSIONS} from "../../../constants";
import Modal from "@bookingcom/bui-react/components/Modal";
import * as PropTypes from "prop-types";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import LockClosed from "@bookingcom/bui-react/icons/LockClosed";
import styles from "../details/RoomMainDetails.module.css";
import EyeCrossedOut from "@bookingcom/bui-react/icons/EyeCrossedOut";
import {ErrorShape, UserShape} from "../../../_app/appPropShapes";
import {MeetingRoomAccessControlResponseShape} from "../../../api/access-control/shapes";
import {connect} from "react-redux";
import {
    enableMeetingRoomAction,
    disableMeetingRoomAction,
    dedicateMeetingRoomAction,
    unDedicateMeetingRoomAction
} from '../../../api/access-control/actions';
import {DedicatingStatuses, SmartAclManagementStatus} from "./MeetingRoomAccessControlView.constants";
import Eye from "@bookingcom/bui-react/icons/Eye";
import LockOpen from "@bookingcom/bui-react/icons/LockOpen";

class MeetingRoomEnablementDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldDisplayMeetingRoomEnablementModal: false,
            shouldDisplayDedicatingMeetingRoomModal: false,
            isReadyToSubmit: false,
            showEnableMeetingRoomBtn: false,
            showDisableMeetingRoomBtn: false,
            showDedicateMeetingRoomBtn: false,
            showUnDedicateMeetingRoomBtn: false
        };
    }

    onEnableMeetingRoomClicked = () => {
        this.setState({
            shouldDisplayMeetingRoomEnablementModal: true,
            showDisableMeetingRoomBtn: true,
            isReadyToSubmit: true
        });
    };

    onDisableMeetingRoomClicked = () => {
        this.setState({
            shouldDisplayMeetingRoomEnablementModal: true,
            showEnableMeetingRoomBtn: true,
            isReadyToSubmit: true
        });
    };

    onDedicateMeetingRoomClicked = () => {
        this.setState({
            shouldDisplayDedicatingMeetingRoomModal: true,
            showUnDedicateMeetingRoomBtn: true,
            isReadyToSubmit: true
        });
    }

    onUnDedicateMeetingRoomClicked = () => {
        this.setState({
            shouldDisplayDedicatingMeetingRoomModal: true,
            showDedicateMeetingRoomBtn: true,
            isReadyToSubmit: true
        });
    }

    getMeetingRoomEnablementRequest = () => {
        return {
            resourceEmail: this.props.room.googleCalendar.calendarEmail,
            roomId: this.props.room.id
        }
    }

    enableMeetingRoom = () => {
        this.setState(
            {
                shouldDisplayMeetingRoomEnablementModal: false,
                isReadyToSubmit: false
            },
            () => {
                this.props.enableMeetingRoomAction(this.getMeetingRoomEnablementRequest());
            }
        );
    }

    disableMeetingRoom = () => {
        this.setState(
            {
                shouldDisplayMeetingRoomEnablementModal: false,
                isReadyToSubmit: false
            },
            () => {
                this.props.disableMeetingRoomAction(this.getMeetingRoomEnablementRequest());
            }
        );
    }

    dedicateMeetingRoom = () => {
        this.setState(
            {
                shouldDisplayDedicatingMeetingRoomModal: false,
                isReadyToSubmit: false
            },
            () => {
                this.props.dedicateMeetingRoomAction(this.getMeetingRoomEnablementRequest());
            }
        );
    }

    unDedicateMeetingRoom = () => {
        this.setState(
            {
                shouldDisplayDedicatingMeetingRoomModal: false,
                isReadyToSubmit: false
            },
            () => {
                this.props.unDedicateMeetingRoomAction(this.getMeetingRoomEnablementRequest());
            }
        );
    }

    onMeetingRoomEnablementClose = () => {
        this.setState({
            shouldDisplayMeetingRoomEnablementModal: false,
            showDisableMeetingRoomBtn: false,
            showEnableMeetingRoomBtn: false,
            isReadyToSubmit: false
        });
    }

    onDedicateMeetingRoomClose = () => {
        this.setState({
            shouldDisplayDedicatingMeetingRoomModal: false,
            showDedicateMeetingRoomBtn: false,
            showUnDedicateMeetingRoomBtn: false,
            isReadyToSubmit: false
        });
    }

    showUnDedicateButton = (isDisabled) => {
        return <RbacButton className="bui-button--secondary" rbacRight={RBAC_ROOM_PERMISSIONS}
                           text="Un-Dedicate Meeting Room"
                           icon={<Eye/>}
                           disabled={isDisabled}
                           onClick={this.onUnDedicateMeetingRoomClicked}/>
    }

    showDedicateButton = (isDisabled) => {
        return <RbacButton className="bui-button--secondary" rbacRight={RBAC_ROOM_PERMISSIONS}
                           text="Dedicate Meeting Room"
                           icon={<EyeCrossedOut/>}
                           disabled={isDisabled}
                           onClick={this.onDedicateMeetingRoomClicked}/>
    }

    showEnableButton = (isDisabled) => {
        return <RbacButton className="bui-button--secondary" rbacRight={RBAC_ROOM_PERMISSIONS}
                           text="Enable Meeting Room"
                           icon={<LockOpen/>}
                           disabled={isDisabled}
                           onClick={this.onEnableMeetingRoomClicked}
        />
    }

    showDisableButton = (isDisabled) => {
        return <RbacButton className="bui-button--secondary" rbacRight={RBAC_ROOM_PERMISSIONS}
                           text="Disable Meeting Room"
                           icon={<LockClosed/>}
                           disabled={isDisabled}
                           onClick={this.onDisableMeetingRoomClicked}/>
    }


    showMeetingRoomEnablementButtons = () => {
        const response = [];
        const dedicatingStatus = this.props.meetingRoomAccessControl.dedicatingStatus.toUpperCase();
        if (dedicatingStatus.toUpperCase() === DedicatingStatuses.DEDICATED) {
            response.push(this.showUnDedicateButton(false));
            response.push(this.showEnableButton(true));
            return response
        }
        if (dedicatingStatus.toUpperCase() === DedicatingStatuses.UN_DEDICATED &&
            this.props.meetingRoomAccessControl.isEnabled === false) {
            response.push(this.showDedicateButton(false));
            response.push(this.showEnableButton(false));
            return response;
        }
        response.push(this.showDedicateButton(false));
        response.push(this.showDisableButton(false));
        return response;
    }

    getDedicatingMeetingRoomModalTitle = () => {
        return this.props.meetingRoomAccessControl.dedicatingStatus.toUpperCase() === DedicatingStatuses.DEDICATED ?
            `Un-Dedicate Meeting Room on ${this.props.room.code}` :
            `Dedicate Meeting Room on ${this.props.room.code}`;
    }

    getMeetingRoomEnablementModalTitle = () => {
        return this.props.meetingRoomAccessControl.isEnabled ?
            `Disable Meeting Room on ${this.props.room.code}` :
            `Enable Meeting Room on ${this.props.room.code}`;
    }

    showMeetingRoomEnablementModalButtons = () => {
        return this.props.meetingRoomAccessControl.isEnabled ?
            {
                text: 'Disable', variant: 'destructive', type: 'submit', disabled: !this.state.isReadyToSubmit,
                onClick: this.disableMeetingRoom
            } :
            {
                text: 'Enable', variant: 'destructive', type: 'submit', disabled: !this.state.isReadyToSubmit,
                onClick: this.enableMeetingRoom
            }
    }

    showDedicatingMeetingRoomModalButtons = () => {
        return this.props.meetingRoomAccessControl.dedicatingStatus.toUpperCase() === DedicatingStatuses.DEDICATED ?
            {
                text: 'Un-Dedicate', variant: 'destructive', type: 'submit', disabled: !this.state.isReadyToSubmit,
                onClick: this.unDedicateMeetingRoom
            } :
            {
                text: 'Dedicate', variant: 'destructive', type: 'submit', disabled: !this.state.isReadyToSubmit,
                onClick: this.dedicateMeetingRoom
            }

    }

    showDefaultAlertMessage = () => {
        return "This action will affect the book-ability of this meeting room, so please make sure this is a valid use-case.";
    }
    showTextOfDedicatingMeetingRoomModalAlert = () => {
        return this.props.meetingRoomAccessControl.smartAclManagementStatus.toUpperCase() === SmartAclManagementStatus.ENABLED ?
            "This action will affect the book-ability of this meeting room and may take up to 1 business day for completion, so please make sure this is a valid use-case." :
            this.showDefaultAlertMessage();
    }

    isSmartAclProcessing = () => {
        return this.props.meetingRoomAccessControl.smartAclManagementStatus.toUpperCase() === SmartAclManagementStatus.PROCESSING;
    }

    showSmartAclProcessing = () => {
        return <Alert variant="primary"
                      text="You cannot take actions on room's access control untill Smart ACL Management is done."/>;
    }

    showMeetingRoomEnablementBy = () => {
        switch (this.props.meetingRoomAccessControl.dedicatingStatus.toUpperCase()) {
            case DedicatingStatuses.DEDICATING_REQUEST: {
                return <Alert variant="primary"
                              text="You cannot take actions on room's access control because the room's already (requested to be dedicated) and it's still processing."/>;
            }
            case DedicatingStatuses.PASSPORT_POLICIES_CREATED: {
                return <Alert variant="primary"
                              text="You cannot take actions on room's access control because the room's already (requested to be dedicated), passport policies are created and it's still processing."/>;
            }
            case DedicatingStatuses.PENDING_UN_DEDICATING: {
                return <Alert variant="primary"
                              text="You cannot take actions on room's access control because the room's already (requested to be un-dedicated) and it's still processing."/>;
            }
            case DedicatingStatuses.PASSPORT_POLICIES_DELETED: {
                return <Alert variant="primary"
                              text="You cannot take actions on room's access control because the room's already (requested to be un-dedicated), passport policies are removed and it's still processing."/>;
            }
            default: {
                return <>
                    <GridColumn size="half" className={styles['buttons-container']}>
                        {this.showMeetingRoomEnablementButtons()}
                    </GridColumn>
                    <Modal
                        title={this.getMeetingRoomEnablementModalTitle()}
                        closeAriaLabel="Close Modal"
                        onClose={this.onMeetingRoomEnablementClose}
                        active={this.state.shouldDisplayMeetingRoomEnablementModal}
                        buttons={[this.showMeetingRoomEnablementModalButtons()]}
                    ><Alert
                        title='Notice!'
                        text="This action will affect the book-ability of this meeting room, so please make sure this is a valid use-case."
                        variant='info'
                    />
                    </Modal>
                    <Modal
                        title={this.getDedicatingMeetingRoomModalTitle()}
                        closeAriaLabel="Close Modal"
                        onClose={this.onDedicateMeetingRoomClose}
                        active={this.state.shouldDisplayDedicatingMeetingRoomModal}
                        buttons={[this.showDedicatingMeetingRoomModalButtons()]}
                    ><Alert
                        title='Notice!'
                        text={this.showTextOfDedicatingMeetingRoomModalAlert()}
                        variant='info'
                    />
                    </Modal>
                </>;
            }
        }
    }

    showMeetingRoomEnablementSection = () => {
        if (this.isSmartAclProcessing()) {
            return this.showSmartAclProcessing();
        }
        return this.showMeetingRoomEnablementBy(DedicatingStatuses.DEDICATING_REQUEST);
    }

    render() {
        return <>
            <Text variant="heading">Meeting Room Enablement</Text>
            <Text variant="caption" className="bui-spacer">This controls the book-ability of the meeting room on google
                calendar</Text>
            {this.showMeetingRoomEnablementSection()}
        </>;
    }
}

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    enableMeetingRoomAction,
    disableMeetingRoomAction,
    dedicateMeetingRoomAction,
    unDedicateMeetingRoomAction
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
    // User
    user: state.oidc.user,
});

MeetingRoomEnablementDetails.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    // Action
    enableMeetingRoomAction: PropTypes.func,
    disableMeetingRoomAction: PropTypes.func,
    dedicateMeetingRoomAction: PropTypes.func,
    unDedicateMeetingRoomAction: PropTypes.func,

    // Redux
    user: PropTypes.shape(UserShape),
    meetingRoomAccessControl: PropTypes.shape(MeetingRoomAccessControlResponseShape),
    accessControlErrors: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoomEnablementDetails);