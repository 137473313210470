import {
    TENANTS_FETCHED,
} from './actions';

const initialState = {
    entities: {},
};

export const upsertSharingEntities = (entities, newEntities) => {
    return newEntities;
};

export const tenantsApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case TENANTS_FETCHED: {
            const entities = upsertSharingEntities(reducerState.entities, action.payload.entities);
            return {
                ...reducerState,
                entities,
            };
        }
        default:
            return reducerState;
    }
};
