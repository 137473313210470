import React from 'react';
import * as PropTypes from 'prop-types';
import Button from '@bookingcom/bui-react/components/Button';
import InputText from '@bookingcom/bui-react/components/InputText';
import './styles.css';
import Label from "@bookingcom/bui-react/icons/Label";

class CopyToClipboardId extends React.Component {
    constructor(props) {
        super(props);

        this.myRef = React.createRef();
    }

    onClick = () => {
        const inputText = document.createElement('input');
        inputText.value = this.props.text;
        this.myRef.current.appendChild(inputText);
        inputText.select();
        document.execCommand('copy');
        this.myRef.current.removeChild(inputText);
    };

    render = () =>
        <div ref={this.myRef} className="mrt-copy-id-to-clipboard">
            <InputText
                label={this.props.label}
                value={this.props.text}
                name="copy-to-clipboard-id"
                suffix={<Button
                    variant="secondary"
                    icon={<Label/>}
                    onClick={this.onClick}
                />}
                attributes={{title: `Copy ID to clipboard: ${this.props.text}`}}
            />
        </div>;
}

CopyToClipboardId.propTypes = {
    text: PropTypes.string.isRequired,
};

export default CopyToClipboardId;
