import _ from "lodash";
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Alert from "@bookingcom/bui-react/components/Alert";
import Grid from "@bookingcom/bui-react/components/Grid";
import {GridColumn} from "@bookingcom/bui-react";
import Accordion from "@bookingcom/bui-react/components/Accordion";
import Text from "@bookingcom/bui-react/components/Text";
import Icon from "@bookingcom/bui-react/components/Icon";
import Settings from "@bookingcom/bui-react/icons/Settings";
import {navigateToAndReloadAction, updateUrlAction} from "../../../_app/actions";
import {saveEntityAction} from "../../../api/rooms/actions";
import {auditLogSelector} from "../../../api/audit-log/selectors";
import {hasEquipmentRbacRight} from "../../../helpers";
import {resetRoomDetailsViewStateAction} from "./actions";
import {RoomMainDetails} from "./RoomMainDetails";
import RoomChangeLog from "./RoomChangeLog";
import {GoogleCalendarDetails} from "./GoogleCalendarDetails";
import styles from './RoomDetailsView.module.css';
import CopyToClipboardText from "../../../components/copy-to-clipboard-text/CopyToClipboardText";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import {ErrorShape, UserShape} from "../../../_app/appPropShapes";
import {fetchAuditLogAction} from "../../../api/audit-log/actions";
import List from "@bookingcom/bui-react/icons/List";
import {API_RESOURCES} from "../../../api/apiRoutes";

class RoomDetailsView extends React.Component {

    initialState = null;

    constructor(props) {
        super(props);

        this.initialState = {
            room: props.room,
            auditLog: [],
            isEntityLoading: true,
            isEntitySaved: false,
        };

        this.state = {...this.initialState};
    }

    /**
     * Calculate the state delta using the given props.
     * @param newProps
     * @param prevState
     */
    static getDerivedStateFromProps(newProps, prevState) {
        const delta = {};

        if (!_.isEmpty(prevState.room) && !_.isEmpty(newProps.room) &&
            prevState.room.revision !== newProps.room.revision) {

            delta.room = _.cloneDeep(newProps.room);
        }

        if (_.isEmpty(prevState.auditLog) && newProps.auditLog) {
            delta.auditLog = _.cloneDeep(newProps.auditLog);
        }

        if (newProps.isEntityLoading !== undefined && prevState.isEntityLoading !== newProps.isEntityLoading) {
            delta.isEntityLoading = newProps.isEntityLoading;
        }

        if (prevState.isEntitySaved !== newProps.isEntitySaved) {
            delta.isEntitySaved = newProps.isEntitySaved;
        }

        return delta;
    }

    componentDidMount() {
        if (this.state.isEntityLoading) {
            // TODO: Move to <RoomChangeLog /> but make it refetchable from API when needed
            this.props.fetchAuditLogAction(API_RESOURCES.rooms.type, this.state.room.id);
        }
    }

    onSave = entity => {
        this.props.saveEntityAction(entity);
    };

    onReset = () => {
        this.props.resetRoomDetailsViewStateAction();
    };

    render() {
        let saveResult = '';

        if (this.props.isEntitySaved) {
            saveResult = <>
                <Alert variant="success"
                       className="bui-spacer"
                       size="large"
                       title="Room changes are saved"
                >
                    <p>It will take a couple of minutes to propagate changes to Google Suite, so please be patient.</p>
                </Alert>
            </>;
        }

        const roomDetails = <RoomMainDetails
            user={this.props.user}
            entity={this.state.room}
            modelErrors={this.props.modelErrors}
            entitySaveError={this.props.entitySaveError}
            onSave={this.onSave}
            onReset={this.onReset}
            isSaving={this.props.isEntitySaving}
        />;

        const extraInfo = <>
            <Grid className="bui-spacer">
                <GridColumn size="half">
                    <CopyToClipboardText
                        label="Room ID"
                        name="id"
                        text={this.state.room.id}
                    />
                </GridColumn>
            </Grid>

            <GoogleCalendarDetails googleCalendar={this.state.room.googleCalendar} />
        </>;

        // let roomAdvancedInfo = this.buildAdvancedPartial(extraInfo);
        let roomAdvancedInfo = <div className={styles['advanced-panel']}>
            <Accordion
                activeIndex={hasEquipmentRbacRight(this.props.user) ? 0 : null}
                items={[
                    {
                        key: 'Advanced',
                        title: <Text variant="heading"><Icon svg={<Settings />} size="larger" /> Advanced</Text>,
                        content: extraInfo,
                    },
                    {
                        key: 'Change log',
                        title: <Text variant="heading"><Icon svg={<List />} size="larger" /> Change log</Text>,
                        content: <RoomChangeLog changeLog={this.state.auditLog} />,
                    },
                ]}
            />
        </div>;

        return <>
            {saveResult}
            {roomDetails}
            {roomAdvancedInfo}
        </>;
    }
}


/**
 * Injects action functions' dispatchers to props.
 *
 * https://react-redux.js.org/api/connect#mapdispatchtoprops-object-dispatch-ownprops-object
 * https://redux.js.org/api/store#dispatch
 * https://redux.js.org/glossary#action
 *
 * **Note**:
 *
 * Action function `doWork` could be dispatched in that way `this.props.doWork("paramValue")` instead of `this.props.dispatch(doWork("paramValue"))`.
 */
const mapDispatchToProps = {
    saveEntityAction,
    resetRoomDetailsViewStateAction,
    navigateToAndReloadAction,
    updateUrlAction,
    fetchAuditLogAction,
};

/**
 * Connects React component to the Redux store.
 *
 * https://react-redux.js.org/api/connect#connect
 *
 * **Usage**:
 *
 * `export default connect(mapReduxStateToProps, mapDispatchToProps)(HomePage);`
 * `export default connect(null, mapDispatchToProps)(HomePage);`
 * `export default connect(mapReduxStateToProps)(HomePage);`
 * `export default connect()(HomePage);`
 */
const mapStateToProps = (state, ownProps) => ({
    // User
    user: state.oidc.user,

    // Room
    isEntitySaving: state.pages.roomEdit.details.isEntitySaving,
    isEntitySaved: state.pages.roomEdit.details.isEntitySaved,
    entitySaveError: state.pages.roomEdit.details.entitySaveError,
    modelErrors: state.pages.roomEdit.details.modelErrors,

    // Room change log
    auditLog: auditLogSelector(state.api.auditLogs, API_RESOURCES.rooms.type, ownProps.room.id),
});

RoomDetailsView.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    // Redux
    user: PropTypes.shape(UserShape),

    isEntitySaving: PropTypes.bool,
    isEntitySaved: PropTypes.bool,
    entitySaveError: PropTypes.shape(ErrorShape),
    modelErrors: PropTypes.object,

    // Actions
    updateUrlAction: PropTypes.func,
    saveEntityAction: PropTypes.func,
    resetRoomDetailsViewStateAction: PropTypes.func,
    fetchAuditLogAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomDetailsView);
