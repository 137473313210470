import _ from "lodash";
import * as React from 'react';
import * as PropTypes from 'prop-types';
import moment from "moment";
import {connect} from 'react-redux';
import {GridColumn} from '@bookingcom/bui-react';
import Grid from '@bookingcom/bui-react/components/Grid';
import IconPlus from '@bookingcom/bui-react/icons/Plus';
import Modal from '@bookingcom/bui-react/components/Modal';
import Alert from "@bookingcom/bui-react/components/Alert";
import Text from "@bookingcom/bui-react/components/Text";
import IconCheckmarkBold from '@bookingcom/bui-react/icons/CheckmarkBold';
import CloseCircle from "@bookingcom/bui-react/icons/CloseCircle";
import Spinner from "@bookingcom/bui-react/components/Spinner";
import {createEntityAction, fetchCalculationAction, fetchEntitiesAction} from "../../../api/room-maintenance/actions";
import {roomMaintenanceViewResetStateAction} from "./actions";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import {RenderIf} from "../../../components/render-if/RenderIf";
import MaintenanceList from "../../../components/room-maintenance-list/MaintenanceList";
import {MaintenanceWindowShape} from "../../../api/room-maintenance/shapes";
import {selectByRoomId} from "../../../api/room-maintenance/selectors";
import {buildNewRoomMaintenanceWindow} from "../../../factories";
import {ErrorShape, UserShape} from "../../../_app/appPropShapes";
import styles from './RoomMaintenanceView.module.css';
import {hasRoomMaintenanceRbacRight} from "../../../helpers";
import RbacButton from "../../../components/rbac-button/RbacButton";
import {RBAC_ROOM_MAINTENANCE} from "../../../constants";
import WindowDetails from "./WindowDetails";

class RoomMaintenanceView extends React.Component {

    initialState = null;
    initialEntity = null;

    constructor(props) {
        super(props);

        this.initialEntity = buildNewRoomMaintenanceWindow(props.room);

        this.initialState = {
            shouldDisplayScheduleMaintenanceModal: false,
            shouldDisplayScheduleMaintenancePrompt: false,
            entitySaveError: null,
            isEntitySaved: false,
            isReadyToSubmit: false,
            entityToSave: {...this.initialEntity},
        };

        this.state = {...this.initialState};
    }

    /**
     * Calculate the state delta using the given props.
     * @param newProps
     * @param prevState
     */
    static getDerivedStateFromProps(newProps, prevState) {
        const delta = {};

        if (!_.isEqual(prevState.isEntitySaved, newProps.isEntitySaved)) {
            delta.isEntitySaved = newProps.isEntitySaved;

            // Close modal only on transition to `true`
            if (newProps.isEntitySaved === true) {
                delta.shouldDisplayCreateEntityModal = false;
                delta.shouldDisplayScheduleMaintenancePrompt = false;
                delta.isReadyToSubmit = false;
            }
        }

        if (!_.isEqual(prevState.entitySaveError, newProps.entitySaveError)) {
            delta.entitySaveError = {...newProps.entitySaveError};
        }

        if (!_.isEqual(prevState.calculationResult, newProps.calculationResult)) {
            delta.calculationResult = {...newProps.calculationResult};
        }

        if (!_.isEqual(prevState.isCalculationFetching, newProps.isCalculationFetching)) {
            delta.isCalculationFetching = newProps.isCalculationFetching;
        }

        return delta;
    }

    componentWillUnmount() {
        this.props.roomMaintenanceViewResetStateAction();
    }

    componentDidMount() {
        this.props.fetchEntitiesAction(this.props.room.id);
    }

    openScheduleMaintenanceModal = () => {
        this.resetScheduleMaintenanceModals();

        this.setState({
            shouldDisplayScheduleMaintenanceModal: true,
        });
    };

    openScheduleMaintenancePrompt = () => {
        this.props.fetchCalculationAction(this.processBeforeSaving(this.state.entityToSave));

        this.setState({
            shouldDisplayScheduleMaintenancePrompt: true,
        });
    };

    resetScheduleMaintenanceModals = () => {
        this.setState({...this.initialState});
    };

    onSave = () => {
      this.props.createEntityAction(this.processBeforeSaving(this.state.entityToSave));

      this.setState({
        shouldDisplayEntitySaveError: true,
      });
    };

    onIsValidChanged = value => {
        this.setState({isReadyToSubmit: value});
    };

    render = () => <>
        <Grid className="bui-spacer">
            <GridColumn size="full">
                <Alert variant="error" size="large" title="Warning" className="mrt-error-message">
                    <Text variant="featured">Do not use room maintenance to take a building offline. To take building offline please disable the room</Text>
                </Alert>
            </GridColumn>
            <GridColumn size="full" className={styles['__buttons']}>
                <RbacButton
                    rbacRight={RBAC_ROOM_MAINTENANCE}
                    text="Schedule maintenance"
                    icon={<IconPlus/>}
                    onClick={this.openScheduleMaintenanceModal}
                    loading={this.props.isEntitySaving}
                    disabled={!hasRoomMaintenanceRbacRight(this.props.user)}
                />
            </GridColumn>
        </Grid>

        <RenderIf condition={this.props.isEntitySaved} render={() =>
            <Alert
                variant="success"
                className="bui-spacer"
                text="Maintenance window is scheduled. The calendar should start being blocked in a few moments"/>}
        />
        <MaintenanceList entities={this.props.entities} />

        <Modal
            title={<>Room {this.props.room.code}</>}
            subtitle="Schedule room maintenance window"
            closeAriaLabel="Close modal"
            active={this.state.shouldDisplayScheduleMaintenanceModal}
            onClose={this.resetScheduleMaintenanceModals}
            buttons={[{
                text: 'Schedule', type: 'submit', disabled: !this.state.isReadyToSubmit,
                onClick: this.openScheduleMaintenancePrompt
            }]}
        >
            <WindowDetails
                entity={this.state.entityToSave}
                onChange={value => this.setState({entityToSave: value})}
                onIsValidChanged={this.onIsValidChanged}
            />
            <RenderIf
                condition={this.state.shouldDisplayEntitySaveError && !_.isEmpty(this.state.entitySaveError)}
                render={() =>
                    <GridColumn size="full">
                        <Alert variant="error" size="large">{this.state.entitySaveError}</Alert>
                    </GridColumn>}
            />
        </Modal>

        {this.renderScheduleMaintenancePrompt()}
    </>;

    renderScheduleMaintenancePrompt() {
        if (_.isEmpty(this.state.entityToSave)) return '';

        // TODO: Show detailed information about events
        const calculationResult = this.state.calculationResult;
        const eventsAmount = !_.isEmpty(calculationResult) && _.isArray(calculationResult.events) ?
            calculationResult.events.length :
            0;

        const eventStr = eventsAmount <= 1 ? 'event' : 'events';
        const eventsBlock = eventsAmount > 0 ?
            <Alert variant="error" text={
                <>
                    <Text variant="strong" className="bui-spacer">Destructive action</Text>
                    <Text>
                        <strong>{eventsAmount} {eventStr}</strong> will be moved to other rooms. It can not be undone
                    </Text>
                </>}
            />:
            <Alert variant="info" text={<Text>No events will be affected by the maintenance</Text>} />;

        const postButtonText = eventsAmount > 0 ?
            'Move events, block the room' :
            'Block the room';

        return (
            <Modal
                className={styles['__schedule-modal']}
                title={<><CloseCircle /> Warning</>}
                closeAriaLabel="Close modal"
                active={this.state.shouldDisplayScheduleMaintenancePrompt}
                onClose={this.resetScheduleMaintenanceModals}
                buttons={[
                    {
                        text: postButtonText,
                        variant: 'destructive',
                        icon: <IconCheckmarkBold/>,
                        loading: this.props.isEntitySaving,
                        disabled: this.state.isCalculationFetching,
                        onClick: this.onSave,
                    },
                    { text: 'Cancel', variant: 'secondary', onClick: this.resetScheduleMaintenanceModals },
                ]}
            >
                <Text variant="emphasized" className="bui-spacer">Make sure you understand what is about to happen</Text>
                <table className="bui-table bui-table--no-border bui-spacer">
                    <thead className="bui-table__head">
                    <tr className="bui-table__row">
                        <th className="bui-table__cell bui-table__cell--head" colSpan="2">Maintenance window</th>
                    </tr>
                    </thead>
                    <tbody className="bui-table__body">
                    <tr className="bui-table__row">
                        <td className="bui-table__cell bui-table__cell--head">Room</td>
                        <td className="bui-table__cell">{this.props.room.code} {this.props.room.name}</td>
                    </tr>
                    <tr className="bui-table__row">
                        <td className="bui-table__cell bui-table__cell--head">From</td>
                        <td className="bui-table__cell">{this.state.entityToSave.from ? this.state.entityToSave.from.format('LL') : ''}</td>
                    </tr>
                    <tr className="bui-table__row">
                        <td className="bui-table__cell bui-table__cell--head">To</td>
                        <td className="bui-table__cell">{this.state.entityToSave.to ? this.state.entityToSave.to.format('LL') : ''}</td>
                    </tr>
                    <tr className="bui-table__row">
                        <td className="bui-table__cell bui-table__cell--head">Reason</td>
                        <td className="bui-table__cell">{this.state.entityToSave.reason}</td>
                    </tr>
                    </tbody>
                </table>

                {this.state.isCalculationFetching ? <Spinner size="large" /> : eventsBlock}
            </Modal>);
    }

    processBeforeSaving(entityToSave) {
        const entity = {...entityToSave};
        entity.from = entity.from.format('YYYY-MM-DD');
        entity.to = moment(entity.to).add(1, 'days').format('YYYY-MM-DD');
        return entity;
    }
}

/**
 * Injects action functions' dispatchers to props.
 *
 * https://react-redux.js.org/api/connect#mapdispatchtoprops-object-dispatch-ownprops-object
 * https://redux.js.org/api/store#dispatch
 * https://redux.js.org/glossary#action
 *
 * **Note**:
 *
 * Action function `doWork` could be dispatched in that way `this.props.doWork("paramValue")` instead of `this.props.dispatch(doWork("paramValue"))`.
 */
const mapDispatchToProps = {
    createEntityAction,
    fetchEntitiesAction,
    fetchCalculationAction,
    roomMaintenanceViewResetStateAction,
};

/**
 * Connects React component to the Redux store.
 *
 * https://react-redux.js.org/api/connect#connect
 *
 * **Usage**:
 *
 * `export default connect(mapReduxStateToProps, mapDispatchToProps)(HomePage);`
 * `export default connect(null, mapDispatchToProps)(HomePage);`
 * `export default connect(mapReduxStateToProps)(HomePage);`
 * `export default connect()(HomePage);`
 */
const mapStateToProps = (state, ownProps) => ({
    // User
    user: state.oidc.user,

    // API data
    entities: selectByRoomId(state.api.roomMaintenance.entities, ownProps.room.id),
    calculationResult: state.api.roomMaintenance.calculationResult,
    deleteResult: state.api.roomMaintenance.deleteResult,

    // Page data
    isEntitySaving: state.pages.roomEdit.maintenance.isEntitySaving,
    isEntitySaved: state.pages.roomEdit.maintenance.isEntitySaved,
    entitySaveError: state.pages.roomEdit.maintenance.entitySaveError,
    isCalculationFetching: state.pages.roomEdit.maintenance.isCalculationFetching,

    // External page data
    entitiesBeingDeletedIds: state.pages.roomMaintenanceList.entitiesBeingDeletedIds,
});

RoomMaintenanceView.propTypes = {
    // User props
    room: PropTypes.shape(MeetingRoomShape).isRequired,
    entities: PropTypes.arrayOf(PropTypes.shape(MaintenanceWindowShape)),

    // Actions
    createEntityAction: PropTypes.func,
    fetchEntitiesAction: PropTypes.func,
    fetchCalculationAction: PropTypes.func,
    roomMaintenanceViewResetStateAction: PropTypes.func,

    // Redux
    user: PropTypes.shape(UserShape),
    isEntitySaving: PropTypes.bool,
    isEntitySaved: PropTypes.bool,
    entitySaveError: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomMaintenanceView);
