export const SHARING_REQUESTS_FETCHING = 'api/sharing/sharing-requests/list/FETCHING';
export const SHARING_REQUESTS_FETCHED = '/sharing/sharing-requests/list/FETCHED';
export const SHARING_REQUESTS_FETCH_FAILED = '/sharing/sharing-requests/list/FETCH_FAILED';

export const SHARING_REQUEST_CREATING = '/sharing/sharing-requests/entity/CREATING';
export const SHARING_REQUEST_CREATED = '/sharing/sharing-requests/entity/CREATED';
export const SHARING_REQUEST_CREATE_FAILED = '/sharing/sharing-requests/entity/CREATE_FAILED';

export const SHARING_REQUEST_CANCELING = '/sharing/sharing-requests/entity/CANCELING';
export const SHARING_REQUEST_CANCELED = '/sharing/sharing-requests/entity/CANCELED';
export const SHARING_SYNC_APPROVED_FAILED = '/sharing/sharing-requests/entity/APPROVED_FAILED';
export const SHARING_REQUEST_CANCEL_FAILED = '/sharing/sharing-requests/entity/CANCEL_FAILED';
export const SHARING_SYNC_APPROVED = '/sharing/sharing-requests/entity/APPROVED';
export const SHARING_SYNC_APPROVING = '/sharing/sharing-requests/entity/APPROVING';
export const fetchEntitiesAction = roomId => ({
    type: SHARING_REQUESTS_FETCHING,
    payload: {
        roomId,
    },
});

export const fetchEntitiesResultAction = (roomId, entities) => ({
    type: SHARING_REQUESTS_FETCHED,
    payload: {
        roomId,
        entities,
    },
});

export const fetchEntitiesFailedAction = (roomId, error) => ({
    type: SHARING_REQUESTS_FETCH_FAILED,
    payload: {
        roomId,
        error,
    },
});

export const createEntityAction = entity => ({
    type: SHARING_REQUEST_CREATING,
    payload: {
        entity,
    },
});

export const createEntityResultAction = entity => ({
    type: SHARING_REQUEST_CREATED,
    payload: {
        entity,
    },
});

export const approveEntityAction = (entity) => ({
    type: SHARING_SYNC_APPROVING,
    payload: {
        entity
    },
});

export const approveEntityResultAction = (roomId, entity) => ({
    type: SHARING_SYNC_APPROVED,
    payload: {
        roomId,
        entity
    },
});

export const approveEntityFailedAction = (roomId, error) => ({
    type: SHARING_SYNC_APPROVED_FAILED,
    payload: {
        error
    },
});

export const createEntityFailedAction = (error) => ({
    type: SHARING_REQUEST_CREATE_FAILED,
    payload: {
        error
    },
});


export const cancelEntityAction = (entity) => ({
    type: SHARING_REQUEST_CANCELING,
    payload: {
        entity
    },
});

export const cancelEntityResultAction = entity => ({
    type: SHARING_REQUEST_CANCELED,
    payload: {
        entity,
    },
});

export const cancelEntityFailedAction = (error) => ({
    type: SHARING_REQUEST_CANCEL_FAILED,
    payload: {
        error,
    },
});
