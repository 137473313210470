import _ from 'lodash';
import {normalizeRoom} from "../../normalizers";
import {ROOM_CREATED, ROOM_FETCHED, ROOM_SAVED, ROOMS_FETCHED} from './actions';

const initialState = {
    entities: [],
};

const upsertEntity = (entities, entity) => {
    const roomsClone = _.clone(entities);
    const normalizedEntity = normalizeRoom(entity);

    const index = _.findIndex(roomsClone, r => r.id === entity.id);
    // Room not found
    if (index === -1) {
        // Insert
        roomsClone.unshift(normalizedEntity);
    } else {
        // Room found. Replace
        roomsClone[index] = normalizedEntity;
    }
    return roomsClone;
};

export const roomsApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case ROOMS_FETCHED: {
            return {
                ...reducerState,
                entities: action.payload.entities,
            };
        }
        case ROOM_CREATED:
        case ROOM_SAVED:
        case ROOM_FETCHED: {
            const newState = {...reducerState};
            newState.entities = upsertEntity(newState.entities, action.payload.entity);
            return newState;
        }
        default:
            return reducerState;
    }
};
