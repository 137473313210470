export const AIR_MEDIA_UNITS_FETCHING = 'api/equipment/air-media-units/list/FETCHING';
export const AIR_MEDIA_UNITS_FETCHED = 'api/equipment/air-media-units/list/FETCHED';
export const AIR_MEDIA_UNITS_FETCH_FAILED = 'api/equipment/air-media-units/list/FETCH_FAILED';

export const AIR_MEDIA_UNIT_CREATING = 'api/equipment/air-media-units/entity/CREATING';
export const AIR_MEDIA_UNIT_CREATED = 'api/equipment/air-media-units/entity/CREATED';
export const AIR_MEDIA_UNIT_CREATE_FAILED = 'api/equipment/air-media-units/entity/CREATE_FAILED';

export const AIR_MEDIA_UNIT_SAVING = 'api/equipment/air-media-units/entity/SAVING';
export const AIR_MEDIA_UNIT_SAVED = 'api/equipment/air-media-units/entity/SAVED';
export const AIR_MEDIA_UNIT_SAVE_FAILED = 'api/equipment/air-media-units/entity/SAVE_FAILED';

export const AIR_MEDIA_UNIT_DELETING = 'api/equipment/air-media-units/entity/DELETING';
export const AIR_MEDIA_UNIT_DELETED = 'api/equipment/air-media-units/entity/DELETED';
export const AIR_MEDIA_UNIT_DELETE_FAILED = 'api/equipment/air-media-units/entity/DELETE_FAILED';

export const fetchEntitiesAction = roomId => ({
    type: AIR_MEDIA_UNITS_FETCHING,
    payload: {
        roomId,
    },
});

export const fetchEntitiesResultAction = (roomId, entities) => ({
    type: AIR_MEDIA_UNITS_FETCHED,
    payload: {
        roomId,
        entities,
    },
});

export const fetchEntitiesFailedAction = (roomId, error) => ({
    type: AIR_MEDIA_UNITS_FETCH_FAILED,
    payload: {
        roomId,
        error,
    },
});

export const createEntityAction = entity => ({
    type: AIR_MEDIA_UNIT_CREATING,
    payload: {
        entity,
    },
});

export const createEntityResultAction = entity => ({
    type: AIR_MEDIA_UNIT_CREATED,
    payload: {
        entity,
    },
});

export const createEntityFailedAction = (entity, error) => ({
    type: AIR_MEDIA_UNIT_CREATE_FAILED,
    payload: {
        entity,
        error,
    },
});

export const saveEntityAction = entity => ({
    type: AIR_MEDIA_UNIT_SAVING,
    payload: {
        entity,
    },
});

export const saveEntityResultAction = entity => ({
    type: AIR_MEDIA_UNIT_SAVED,
    payload: {
        entity,
    },
});

export const saveEntityFailedAction = (entity, error) => ({
    type: AIR_MEDIA_UNIT_SAVE_FAILED,
    payload: {
        entity,
        error,
    },
});

export const deleteEntityAction = entity => ({
    type: AIR_MEDIA_UNIT_DELETING,
    payload: {
        entity,
    },
});

export const deleteEntityResultAction = entity => ({
    type: AIR_MEDIA_UNIT_DELETED,
    payload: {
        entity,
    },
});

export const deleteEntityFailedAction = (entity, error) => ({
    type: AIR_MEDIA_UNIT_DELETE_FAILED,
    payload: {
        entity,
        error,
    },
});
