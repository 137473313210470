import { CALLBACK_PAGE } from '../appRoutes';
import {API_BASE_URL} from "../constants";
import {API} from "../api/apiRoutes";
import {createUserManager} from "redux-oidc";

const environments = {
  'Staging': {
    clientId: '0oamq4pvjFdT3ePqs416',
    authority: 'https://okta.booking.com/oauth2/ausmq5jmeU9gtBuzO416',
  },
  'Production': {
    clientId: '0oamybjhq0CPYCs89416',
    authority: 'https://okta.booking.com/oauth2/ausxe36qsvWUxAcUB416',
  },
};

// eslint-disable-next-line
const oktaConfig = {
  /**
   * OAuth2 app ID
   * */
  client_id: '<ENVIRONMENT>',
  /**
   * Callback URL that Identity Provider will call after successful SSO login.
   * Check `CallbackPage` for details.
   */
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${CALLBACK_PAGE}`,
  /**
   * OpenID Connect flow.
   *
   * Use 'code' for **authorization code** flow.
   *
   * https://tools.ietf.org/html/rfc6749#section-4.1
   *
   * https://medium.com/@darutk/diagrams-of-all-the-openid-connect-flows-6968e3990660
   */
  response_type: 'code',
  /**
   * Scope for the access token
   * Minimum is 'openid email profile'
   */
  scope: 'openid email profile',
  // Okta custom authorization server
  authority: '<ENVIRONMENT>',
  automaticSilentRenew: false,
  // No silent redirect is configured/implemented
  // silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  filterProtocolClaims: true,
  // Should be `true` for Okta
  loadUserInfo: true,
  // The window of time (in seconds) to allow the current time to deviate when validating id_token's iat, nbf, and exp values.
  clockSkew: 600,
};

// eslint-disable-next-line
// const adfsConfig = {
//   /**
//    * OAuth2 Client ID. Should be **properly** registered in Identity Provider (ADFS).
//    * */
//   client_id: 'ba-staging-meeting-rooms',
//   /**
//    * Callback URL that Identity Provider will call after successful SSO login.
//    * Check `CallbackPage` for details.
//    */
//   redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}${CALLBACK_PAGE}`,
//   /**
//    * OpenID Connect flow.
//    *
//    * Use 'code' for **authorization code** flow.
//    *
//    * https://tools.ietf.org/html/rfc6749#section-4.1
//    *
//    * https://medium.com/@darutk/diagrams-of-all-the-openid-connect-flows-6968e3990660
//    */
//   response_type: 'code',
//   /**
//    * Scope for the access token
//    * Minimum is 'openid email profile'
//    */
//   scope: 'openid email profile',
//   // ADFS authority
//   authority: 'https://federate.booking.com/adfs',
//   // TODO: Test and remove/update
//   silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
//   // TODO: Test and remove/update
//   automaticSilentRenew: true,
//   filterProtocolClaims: true,
//   // Should be `false` to not call disabled ADFS `https://federate.booking.com/adfs/userinfo` endpoint
//   loadUserInfo: false,
//   // Hardcoded ADFS OpenID Connect configuration because of disabled CORS on endpoint `https://federate.booking.com/adfs/.well-known/openid-configuration`
//   metadata: {
//     authorization_endpoint: 'https://federate.booking.com/adfs/oauth2/authorize',
//     // token_endpoint: 'https://federate.booking.com/adfs/oauth2/token',
//     // ADFS hack
//     token_endpoint: `${API_BASE_URL}/adfs-token`,
//     jwks_uri: "https://federate.booking.com/adfs/discovery/keys",
//     issuer: "https://federate.booking.com/adfs",
//     access_token_issuer: "http://federate.booking.com/adfs/services/trust",
//   },
//   // The window of time (in seconds) to allow the current time to deviate when validating id_token's iat, nbf, and exp values.
//   clockSkew: 600,
// };

// noinspection UnnecessaryLocalVariableJS
/**
 * OpenID Connect configuration for `oidc-client` which is used by `redux-oidc`.
 * 
 * @type {import('oidc-client').UserManagerSettings}
 * 
 * https://tools.ietf.org/html/rfc6749
 * 
 * https://github.com/maxmantz/redux-oidc
 * 
 * https://github.com/IdentityModel/oidc-client-js
 */
const userManagerConfig = oktaConfig;

const userManagerPromise = new Promise((resolve, reject) => {
  const configPromise = fetch(new Request(`${API_BASE_URL}${API.appConfig.endpoint}`, {
    method: 'GET',
  }));
  configPromise
      .then((response) => response.json())
      .then(appConfig => {
        const environment = appConfig.environment || appConfig.Environment;

        const env = environment === 'Production'
            ? environments['Production']
            : environments['Staging'];

        userManagerConfig.client_id = env.clientId;
        userManagerConfig.authority = env.authority;

        const userManager = createUserManager(userManagerConfig);
        resolve(userManager);
      });
});
export default userManagerPromise;
