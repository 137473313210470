import _ from 'lodash';
import * as PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import Grid from '@bookingcom/bui-react/components/Grid';
import GridColumn from "@bookingcom/bui-react/components/Grid/GridColumn";
import Tab from '@bookingcom/bui-react/components/Tab';
import Breadcrumb from '@bookingcom/bui-react/components/Breadcrumb';
import Text from '@bookingcom/bui-react/components/Text';
import Spinner from "@bookingcom/bui-react/components/Spinner";
import Card from "@bookingcom/bui-react/components/Card";
import {ROOM_EDIT_PAGE_FUNC, ROOM_EDIT_TABS, ROOMS_BY_SEARCH_FUNC} from '../../appRoutes';
import {ErrorShape, MatchShape, UserShape} from "../../_app/appPropShapes";
import {navigateToAction, updateUrlAction} from '../../_app/actions';
import {isUuid} from "../../helpers";
import {fetchEntityAction} from "../../api/rooms/actions";
import {roomSelector} from "../../api/rooms/selectors";
import {ErrorMessage} from "../../_app/ErrorMessage";
import RoomDetailsView from "./details/RoomDetailsView";
import RoomMaintenanceView from "./maintenance/RoomMaintenanceView";
import MeetingRoomVcListView from "./equipment/MeetingRoomVcListView";
import styles from './page.module.css';
import DocumentTitle from "../../components/document-title/DocumentTitle";
import MeetingRoomShareListView from "./sharing/MeetingRoomShareListView";
import MeetingRoomAccessControlView from "./access-control/MeetingRoomAccessControlView";

const DEFAULT_TAB = ROOM_EDIT_TABS.DETAILS;

class RoomEditPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            entity: {},
            tabItems: [],
        };
    }

    static buildTabItems = (entity) => [
        {
            text: 'Room details',
            href: ROOM_EDIT_PAGE_FUNC(entity.code, ROOM_EDIT_TABS.DETAILS),
            content: <RoomDetailsView room={entity}/>,
        },
        {
            text: 'Equipment',
            href: ROOM_EDIT_PAGE_FUNC(entity.code, ROOM_EDIT_TABS.EQUIPMENT),
            content: <MeetingRoomVcListView room={entity}/>,
        },
        {
            text: 'Sharing',
            href: ROOM_EDIT_PAGE_FUNC(entity.code, ROOM_EDIT_TABS.SHARING),
            content: <MeetingRoomShareListView room={entity}/>,
        }
        ,
        {
            text: 'Maintenance',
            href: ROOM_EDIT_PAGE_FUNC(entity.code, ROOM_EDIT_TABS.ROOM_MAINTENANCE),
            content: <RoomMaintenanceView room={entity}/>,
        },
        {
            text: 'Access Control',
            href: ROOM_EDIT_PAGE_FUNC(entity.code, ROOM_EDIT_TABS.ACCESS_CONTROL),
            content: <MeetingRoomAccessControlView room={entity}/>,
        }
    ];

    /**
     * Calculate the state delta using the given props.
     * @param newProps
     * @param prevState
     */
    static getDerivedStateFromProps(newProps, prevState) {
        const delta = {};

        // When loading entity first time or reloading entity (revision was changed)
        if (RoomEditPage.isLoadingEntity(prevState, newProps) || RoomEditPage.isReloadingUpdatedEntity(prevState, newProps)) {
            delta.entity = _.cloneDeep(newProps.entity);
            delta.tabItems = RoomEditPage.buildTabItems(newProps.entity);
        }

        return delta;
    }

    static isLoadingEntity(prevState, newProps) {
        // Prev state was empty but there is `entity` in props
        return _.isEmpty(prevState.entity) && newProps.entity;
    }

    static isReloadingUpdatedEntity(prevState, newProps) {
        // Prev state wasn't empty, new props entity not empty and revision is different
        return !_.isEmpty(prevState.entity) && !_.isEmpty(newProps.entity) &&
            prevState.entity.revision !== newProps.entity.revision;
    }

    static isReloadingWithNewRoomCode(prevState, newProps) {
        // Prev state wasn't empty, new props entity is empty because selector can't find a room by old code
        return !_.isEmpty(prevState.entity) && _.isEmpty(newProps.entity);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let roomIdentifier = null;

        if (RoomEditPage.isLoadingEntity(prevState, this.props)) {
            if (isUuid(this.props.match.params.identifier)) {
                // If identifier is a UUID, we update URL with the room code
                roomIdentifier = this.props.entity.code;
            }
        }

        if (RoomEditPage.isReloadingWithNewRoomCode(prevState, this.props)) {
            // Room was edited and room code was changed. Loading by ID
            roomIdentifier = prevState.entity.id;
        }

        if (roomIdentifier) {
            const tab = this.props.match.params.tab;
            const pageUrl = !_.isEmpty(tab)
                ? `${roomIdentifier}/${tab}`
                : roomIdentifier;
            this.props.updateUrlAction(ROOM_EDIT_PAGE_FUNC(pageUrl));
        }
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.match.params) && !_.isEmpty(this.props.match.params.identifier)) {
            const identifier = this.props.match.params.identifier;

            this.props.fetchEntityAction(identifier);
        }
    }

    buildTitle() {
        const identifier = this.props.match.params.identifier;

        let tabTitle;
        const tab = this.props.match.params.tab || DEFAULT_TAB;
        switch (tab) {
            case ROOM_EDIT_TABS.DETAILS:
                tabTitle = 'Details';
                break;
            case ROOM_EDIT_TABS.EQUIPMENT:
                tabTitle = 'Equipment';
                break;
            case ROOM_EDIT_TABS.ROOM_MAINTENANCE:
                tabTitle = 'Room maintenance';
                break;
            default:
                break;
        }

        const result = `Room edit - ${identifier} - ${tabTitle}`;
        return result;
    }

    getActiveTabIndex(tabItems) {
        if (!_.isArray(tabItems) || tabItems.length === 0) {
            return null;
        }

        const currentTab = this.props.match.params.tab || DEFAULT_TAB;
        const result = _.findIndex(tabItems, i => _.endsWith(i.href, `/${(currentTab)}`));
        return result;
    };

    renderBreadcrumbs() {
        // noinspection RequiredAttributes
        return <>
            {
                !_.isEmpty(this.state.entity) ?
                    <Breadcrumb back={true} items={[{
                        text: this.state.entity.building.code,
                        href: ROOMS_BY_SEARCH_FUNC(this.state.entity.building.code),
                    }]}/> :
                    ''
            }
        </>;
    }

    renderPageHeader() {
        return <Text variant="display_one">{`${this.state.entity.code} - ${this.state.entity.name}`}</Text>;
    }

    render() {
        let contents;
        if (this.props.isEntityLoading) {
            contents = <Grid className="bui-spacer">
                <GridColumn size="full">
                    <Spinner size="largest" className={styles['spinner']}/>
                </GridColumn>
            </Grid>;
        } else if (!_.isEmpty(this.props.entityLoadingError)) {
            contents = <ErrorMessage error={this.props.entityLoadingError}/>;
        } else {
            contents = <>
                <Grid className="bui-spacer--medium">
                    <GridColumn size="full">{this.renderBreadcrumbs()}</GridColumn>
                    <GridColumn size="full">{this.renderPageHeader()}</GridColumn>
                </Grid>
                <Card className={styles['room-edit-panel']}>
                    <Tab
                        items={this.state.tabItems}
                        activeIndex={this.getActiveTabIndex(this.state.tabItems)}
                        moreLabel="More"
                        onTabChange={item => this.props.updateUrlAction(item.href)}/>
                </Card>
            </>;
        }

        return <DocumentTitle title={this.buildTitle()}>
            {contents}
        </DocumentTitle>
    }
}

const mapStateToProps = (state, ownProps) => ({
    // User
    user: state.oidc.user,

    // Entity to display (by ID / roomCode)
    entity: roomSelector(state.api.rooms.entities, ownProps.match.params.identifier),

    isEntityLoading: state.pages.roomEdit.details.isEntityLoading,
    entityLoadingError: state.pages.roomEdit.details.entityLoadingError,
});

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    updateUrlAction,
    navigateToAction,
    fetchEntityAction,
};

RoomEditPage.propTypes = {
    // Router match
    match: PropTypes.shape(MatchShape).isRequired,

    // Redux
    user: PropTypes.shape(UserShape),
    entity: PropTypes.object,
    isEntityLoading: PropTypes.bool,
    entityLoadingError: PropTypes.shape(ErrorShape),

    // Actions
    updateUrlAction: PropTypes.func,
    navigateToAction: PropTypes.func,
    fetchEntityAction: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomEditPage);
