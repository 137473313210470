import {USER_NOTIFICATIONS_FETCHED} from "./actions";

const initialState = {
  entities: [],
};

export const userNotificationsApiReducer = (reducerState = initialState, action) => {
  switch (action.type) {
    // Don't use `const newState`. It doesn't work in `switch`
    case USER_NOTIFICATIONS_FETCHED:
      const entities = action.payload.entities;
      return  {
        ...reducerState,
        entities,
      };

    default:
      return reducerState;
  }
};
