import React from 'react';
import * as PropTypes from "prop-types";
import Icon from '@bookingcom/bui-react/components/Icon';
import InfoSign from '@bookingcom/bui-react/icons/InfoSign';
import styles from './Info.module.css';

const VARIANTS = Object.freeze({
    LEFT: 'left',
    RIGHT: 'right',
});

const CSS_CLASS = 'mrt-info';

const Info = props => {
    let className = styles[CSS_CLASS];
    if (props.variant === VARIANTS.LEFT) {
        className += ` ${styles[`${CSS_CLASS}--left`]}`;
    }
    else if (props.variant === VARIANTS.RIGHT) {
        className += ` ${styles[`${CSS_CLASS}--right`]}`;
    }

    return <Icon svg={<InfoSign />} className={className} attributes={{title: props.title}} />;
};

Info.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

export default Info;
