import _ from 'lodash';
import * as yup from 'yup';
import React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@bookingcom/bui-react/components/Grid';
import {GridColumn} from '@bookingcom/bui-react';
import InputText from '@bookingcom/bui-react/components/InputText';
import InputTextarea from '@bookingcom/bui-react/components/InputTextarea';
import InputCheckbox from '@bookingcom/bui-react/components/InputCheckbox';
import Button from '@bookingcom/bui-react/components/Button';
import IconCheckmarkBold from '@bookingcom/bui-react/icons/CheckmarkBold';
import IconLoading from '@bookingcom/bui-react/icons/Loading';
import Divider from '@bookingcom/bui-react/components/Divider';
import InputSelect from '@bookingcom/bui-react/components/InputSelect';
import Alert from '@bookingcom/bui-react/components/Alert';
import Text from "@bookingcom/bui-react/components/Text";
import Update from "@bookingcom/bui-react/icons/Update";
import Info from "../../../components/info/Info";
import {buildNewMeetingRoom} from "../../../factories";
import {ErrorMessage} from "../../../_app/ErrorMessage";
import MeetingBotDetails from "./MeetingBotDetails";
import RecurringEventsLimiterDetails from "./RecurringEventsLimiterDetails";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import {BuildingShape} from "../../../api/buildings/shapes";
import {ErrorShape} from "../../../_app/appPropShapes";
import {RenderEither} from "../../../components/render-either/RenderEither";
import {RenderIf} from "../../../components/render-if/RenderIf";
import styles from "./RoomMainDetails.module.css";
import {RBAC_ROOMS} from "../../../constants";
import RbacButton from "../../../components/rbac-button/RbacButton";

const AVAILABLE_ROOM_FEATURES = [
    {label: 'Phone', key: 'Phone'},
    {label: 'Whiteboard', key: 'Whiteboard'},
    {label: 'Television', key: 'Television'},
    {label: 'Video Conferencing', key: 'Video Conferencing'},
    {label: 'Wheelchair Accessible', key: 'Wheelchair Accessible'},
    {label: 'Projector', key: 'Projector'},
];

const AVAILABLE_EMOJIS = [
    {label: '', key: ''},
    {label: '📞', key: '📞'},
    {label: '💡', key: '💡'},
];

const BUILDING_CODE_PATTERN = /^[A-Z]{3}\d{1,3}$/;
const MAX_CAPACITY_IN_ONE_ROOM = 1000;
const MAX_ROOM_DESCRIPTION_LENGTH = 1000;
const MIN_ROOM_NUMBER_PER_FLOOR = 0; // It's not `1` because currently we have tens or hundreds of rooms without a number (`room.number === 0`).
const MAX_ROOM_NUMBER_PER_FLOOR = 999;
const FLOOR_PATTERN = /^([A-Z]?\d{1,3})|(\d{1,3}[A-Z]?)$/;
// const MIN_FLOOR_NUMBER = -5;
// // https://www.worldatlas.com/articles/which-buildings-have-the-highest-number-of-floors-in-the-world.html
// const MAX_FLOOR_NUMBER = 163;
const ROOM_NAME_PATTERN = /^[a-zA-Z0-9 -]*$/;


const entityEditSchema = yup.object().shape({
    id: yup.string().label('Room ID'),
    isEnabled: yup.bool().label('Room status').required(),
    isEnabledMessage: yup.string().min(4).max(100).required('Why rooms status was changed?').label('Room status change reason'),
    buildingCode: yup.string().matches(BUILDING_CODE_PATTERN).required().label('Building Code'),
    floor: yup.string().matches(FLOOR_PATTERN, 'Floor should be like "5", "D5" or "5D"').required().label('Floor'),
    number: yup.number().integer().min(MIN_ROOM_NUMBER_PER_FLOOR).max(MAX_ROOM_NUMBER_PER_FLOOR).nullable(true).label('Number'),
    capacity: yup.number().integer().min(1).max(MAX_CAPACITY_IN_ONE_ROOM).required().label('Capacity'),
    name: yup.string().min(3).max(40).matches(ROOM_NAME_PATTERN).required().label('Name'),
    description: yup.string().max(MAX_ROOM_DESCRIPTION_LENGTH).nullable().label('Description'),
    emoji: yup.string().nullable().label('Emoji'),
    features: yup.array().of(yup.string()),
    isMeetingBotEnabled: yup.bool().label('Meeting Bot integration'),
    doLimitRecurringEvents: yup.bool().label('Limit recurring events'),
});

const MODE = Object.freeze({
    EDIT: 'EDIT',
    CREATE: 'CREATE',
});

export class RoomMainDetails extends React.Component {

    constructor(props) {
        super(props);

        let entity;
        let mode = MODE.CREATE;
        if (!_.isEmpty(props.entity)) {
            entity = _.cloneDeep(props.entity);
            mode = MODE.EDIT;
        } else {
            entity = buildNewMeetingRoom();
        }

        // TODO: Comment the way model errors should work.
        this.state = {
            initialEntity: _.cloneDeep(entity),
            entity: entity,
            mode: mode,
            modelErrors: props.modelErrors || {},
            entitySaveError: null,
            isShared: false
        };
    }

    static getDerivedStateFromProps(newProps, prevState) {
        const delta = {};

        if (!_.isEmpty(prevState.entity) && !_.isEmpty(newProps.entity) &&
            prevState.entity.revision !== newProps.entity.revision) {

            // If `revision` is different, then new entity version is fetched from API

            delta.entity = _.cloneDeep(newProps.entity);

            // Treat current entity as the "initial" one
            delta.initialEntity = _.cloneDeep(delta.entity);
        }

        if (!_.isEmpty(newProps.modelErrors)) {
            delta.modelErrors = {...newProps.modelErrors};
        }

        if (!_.isEqual(prevState.entitySaveError, newProps.entitySaveError)) {
            delta.entitySaveError = {...newProps.entitySaveError};
        }

        return delta;
    }

    /**
     *
     * @returns {*[]}
     */
    allBuildings = () =>
        [
            {name: 'Select a building', code: ''},
            ...(this.props.buildings || []),
        ];

    /**
     * Modifies a specific feature on the current room.
     */
    onFeatureChanged = ({name, value}) => {
        const entity = _.cloneDeep(this.state.entity);
        if (!value) {
            // Turned off. Filter it out
            entity.features = entity.features.filter(v => v !== name);
        } else {
            // Turned on. Add
            entity.features.unshift(name);
        }
        entity.features.sort();

        this.setState({entity});
    };

    /**
     * Modifies a specific field on the current room.
     */
    onValueChanged = ({name, value}) => {
        const entity = _.cloneDeep(this.state.entity);
        entity[name] = value;
        this.setState({entity});
    };

    onIsDedicatedChanged = ({name, value}) => {
        const entity = _.cloneDeep(this.state.entity);
        entity[name] = value;

        if (value === true) {
            entity.isEnabled = false;
            entity.isEnabledMessage = "Room has been made dedicated";
        }

        this.setState({entity});
    };

    onBuildingCodeChanged = ({name, value}) => {
        const building = _.isEmpty(value) ?
            null :
            this.props.buildings.find(b => b.code === value);

        const entity = _.cloneDeep(this.state.entity);
        entity[name] = value;
        entity.building = building;
        this.setState({entity});
    };

    /**
     * Handler function for the "Save" button.
     */
    onSaveClicked = () => {
        // TODO: Validation when creating shouldn't include roomCode, and allow features.
        const modelErrors = {};
        try {
            entityEditSchema.validateSync(this.state.entity, {abortEarly: false, convert: true});
            this.props.onSave(this.state.entity);
        } catch (error) {
            for (const err of error.inner) {
                modelErrors[err.path] = err.message;
            }
        }
        this.setState({modelErrors});
    };

    onResetClicked = () => {
        const entity = _.cloneDeep(this.state.initialEntity);
        const modelErrors = {};
        const entitySaveError = null;
        this.setState({entity, modelErrors, entitySaveError});

        this.props.onReset();
    };

    onFieldBlur = e => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        const modelErrors = {...this.state.modelErrors};

        try {
            entityEditSchema.fields[name].validateSync(value);
            delete modelErrors[name];
        } catch (error) {
            modelErrors[name] = error.message;
        }

        this.setState({modelErrors});
    };

    /**
     * Returns `true` when the current room entity is different from the initial entity.
     * @returns {boolean} Room was changed
     */
    isDirty = () => {
        if (_.isEmpty(this.state.initialEntity)) {
            return false;
        }

        if (!_.isEmpty(this.state.initialEntity) && !_.isEmpty(this.state.entity)) {
            return !_.isEqual(this.state.initialEntity, this.state.entity);
        }

        return false;
    };

    render() {
        if (this.state.mode === MODE.EDIT && _.isEmpty(this.state.entity.code)) {
            return '';
        }
        return <>
            <Grid className="bui-spacer">
                <GridColumn size="12">
                    <InputText
                        error={this.state.modelErrors.isEnabledMessage}
                        disabled={false}
                        label="Last room status change reason"
                        name="isEnabledMessage"
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        value={this.state.entity.isEnabledMessage}
                    />
                </GridColumn>
                <GridColumn size="3">
                    {this.renderBuildingCodePartial()}
                </GridColumn>
                <GridColumn size="3">
                    <InputText
                        error={this.state.modelErrors.floor}
                        label="Floor"
                        name="floor"
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        value={this.state.entity.floor}
                    />
                </GridColumn>
                <GridColumn size="3">
                    <InputText
                        error={this.state.modelErrors.number}
                        inputAttributes={{type: 'tel'}}
                        label="Number"
                        name="number"
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        value={this.state.entity.number}
                    />
                </GridColumn>
                <GridColumn size="3">
                    <InputText
                        error={this.state.modelErrors.capacity}
                        inputAttributes={{type: 'number'}}
                        label="Capacity"
                        name="capacity"
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        value={this.state.entity.capacity}
                    />
                </GridColumn>
            </Grid>
            <Grid className="bui-spacer">
                <GridColumn size="11">
                    <InputText
                        error={this.state.modelErrors.name}
                        label="Name"
                        name="name"
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        prefix={this.state.entity.code}
                        value={this.state.entity.name}
                    />
                </GridColumn>

                <GridColumn size="1">
                    <InputSelect
                        error={this.state.modelErrors.name}
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        label="Emoji"
                        value={this.state.entity.emoji || ""}
                        name="emoji"
                        options={
                            AVAILABLE_EMOJIS.map(v => ({
                                    text: v.label,
                                    value: v.key,
                                    key: v.key,
                                })
                            )}
                    />
                </GridColumn>

            </Grid>
            <Grid className="bui-spacer">
                <GridColumn size="full">
                    <InputTextarea
                        error={this.state.modelErrors.description}
                        label="Description"
                        name="description"
                        onBlur={this.onFieldBlur}
                        onChange={this.onValueChanged}
                        value={this.state.entity.description}
                    />
                </GridColumn>
            </Grid>

            <Grid className="bui-spacer">
                <GridColumn size="full">
                    <Text variant="heading">Calendar features</Text>
                </GridColumn>
                {
                    AVAILABLE_ROOM_FEATURES.map(value =>
                        <GridColumn size="2" key={value.key}>
                            <InputCheckbox
                                label={value.label}
                                name={value.key}
                                onChange={this.onFeatureChanged}
                                value={(this.state.entity.features && _.includes(this.state.entity.features, value.key)) || false}
                            />
                        </GridColumn>)
                }
                <RenderIf condition={this.state.mode !== MODE.CREATE}
                          render={() => <>
                              <GridColumn size="full"><Divider/></GridColumn>
                              <GridColumn size="half">
                                  <MeetingBotDetails
                                      label="Meeting Bot"
                                      name="meetingBot"
                                      value={this.state.entity.meetingBot}
                                      onChanged={this.onValueChanged}
                                  />
                              </GridColumn>
                              <GridColumn size="half">
                                  <RecurringEventsLimiterDetails
                                      label="Recurring events limiter"
                                      name="recurringEventsLimiter"
                                      value={this.state.entity.recurringEventsLimiter}
                                      onChanged={this.onValueChanged}/>
                              </GridColumn>
                          </>}
                />
                <GridColumn size="half" className={styles['buttons-container']}>
                    {this.renderAlertPartial()}
                    <RbacButton
                        rbacRight={RBAC_ROOMS}
                        loading={this.props.isSaving}
                        icon={this.props.isSaving ? <IconLoading/> : <IconCheckmarkBold/>}
                        onClick={this.onSaveClicked}
                        disabled={this.props.isSaving || !this.isDirty()}
                        text="Save"
                    />
                    <RenderIf condition={this.state.mode === MODE.EDIT} render={() => <>
                        <Button
                            variant="secondary"
                            icon={<Update/>}
                            onClick={this.onResetClicked}
                            disabled={this.props.isSaving || !this.isDirty()}
                            text={<>Reset <Info title="Reset all changes in the form "/></>}
                        />
                    </>}
                    />
                </GridColumn>
            </Grid>
        </>;
    }

    renderAlertPartial = () =>
        <RenderIf condition={!_.isEmpty(this.state.modelErrors) || !!this.props.entitySaveError} render={() =>
            <GridColumn size="full">
                <RenderIf condition={!_.isEmpty(this.state.modelErrors)} render={() =>
                    <Alert
                        className="bui-spacer"
                        variant="error"
                        inline={true}
                        text="Please fill in the form correctly"
                    />}
                />
                <ErrorMessage error={this.props.entitySaveError}/>
            </GridColumn>}
        />;

    // When in MODE.EDIT, the interface should render a disabled input with
    // the current room's building code, but during creation a select should
    // be displayed with all the buildings as options for the user to select.
    renderBuildingCodePartial = () =>
        <RenderEither condition={this.state.mode === MODE.EDIT}
                      isTrue={() =>
                          <InputText
                              label="Building Code"
                              name="buildingCode"
                              value={this.state.entity.building.code}
                              disabled={true}
                          />}
                      isFalse={() =>
                          <InputSelect
                              label="Building Code"
                              name="buildingCode"
                              options={this.allBuildings().map(b => this.formatBuilding(b))}
                              onChange={this.onBuildingCodeChanged}
                              error={this.state.modelErrors.buildingCode}
                              onBlur={this.onFieldBlur}
                          />}
        />;

    formatBuilding(building) {
        const text = _.isEmpty(building.name)
            ? building.code
            : `${building.code} - ${building.name}`;

        return {
            text: text,
            value: building.code,
            key: building.code,
        };
    };
}

RoomMainDetails.propTypes = {
    user: PropTypes.object,
    entity: PropTypes.shape(MeetingRoomShape),
    modelErrors: PropTypes.object,
    entitySaveError: PropTypes.shape(ErrorShape),
    onSave: PropTypes.func,
    onReset: PropTypes.func,
    buildings: PropTypes.arrayOf(PropTypes.shape(BuildingShape)),
    isSaving: PropTypes.bool,
};
