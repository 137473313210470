import Grid from "@bookingcom/bui-react/components/Grid";
import {Badge, GridColumn} from "@bookingcom/bui-react";
import * as React from "react";
import Divider from "@bookingcom/bui-react/components/Divider";
import SmartAclManagementDetails from "./SmartAclManagementDetails";
import MeetingRoomEnablementDetails from "./MeetingRoomEnablementDetails";
import Text from "@bookingcom/bui-react/components/Text";
import * as PropTypes from "prop-types";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import _ from "lodash";
import Hourglass from "@bookingcom/bui-react/icons/Hourglass";
import EmptyState from "@bookingcom/bui-react/components/EmptyState";
import {connect} from "react-redux";
import {
    fetchEntityAction as fetchMeetingRoomAccessControlAction
} from '../../../api/access-control/actions';
import {ErrorShape, UserShape} from "../../../_app/appPropShapes";
import {MeetingRoomAccessControlResponseShape} from "../../../api/access-control/shapes";
import {CurrentRoomStatuses, DedicatingStatuses} from "./MeetingRoomAccessControlView.constants";
import TextWithSpinner from "../../../components/text-with-spinner/TextWithSpinner";
import {RenderEither} from "../../../components/render-either/RenderEither";
import Wind from "@bookingcom/bui-react/icons/Wind";

class MeetingRoomAccessControlView extends React.Component {

    componentDidMount() {
        if (this.props.room) {
            this.props.fetchMeetingRoomAccessControlAction(this.props.room.id);
        }
    }

    currentRoomStatus = () => {
        switch (this.props.meetingRoomAccessControl.dedicatingStatus.toUpperCase()) {
            case DedicatingStatuses.DEDICATED:
            case DedicatingStatuses.DEDICATING_REQUEST:
            case DedicatingStatuses.PASSPORT_POLICIES_CREATED:
                return CurrentRoomStatuses.DEDICATED;
            case DedicatingStatuses.PENDING_UN_DEDICATING:
            case DedicatingStatuses.PASSPORT_POLICIES_DELETED:
            case DedicatingStatuses.UN_DEDICATED: {
                if (this.props.meetingRoomAccessControl.isEnabled === false) {
                    return CurrentRoomStatuses.NON_BOOKABLE;
                }
                return CurrentRoomStatuses.BOOKABLE
            }
            default:
                return CurrentRoomStatuses.BOOKABLE
        }
    };

    hasAnyAccessControl = () => {
        return !_.isEmpty(this.props.meetingRoomAccessControl);
    }

    render() {
        if ((_.isEmpty(this.props.room.googleCalendar) || _.isEmpty(this.props.room.googleCalendar.calendarId))) {
            return <>
                <EmptyState
                    className="bui-spacer"
                    icon={<Hourglass size="larger"/>}
                    text="Google Calendar has not been created yet it can take up to 15 minutes to be created. Check back here in a few minutes"/>
            </>;
        }
        return <>
            <RenderEither condition={this.props.isLoadingRoomAccessControl} isTrue={() =>
                <TextWithSpinner>Our rodents are busy searching for meeting room's access-control, hang on
                    a second...
                </TextWithSpinner>
            } isFalse={() =>
                <RenderEither condition={this.hasAnyAccessControl()} isTrue={() =>
                    <Grid className="bui-spacer">
                        <GridColumn size="half">
                            <Text variant="heading">Current Room Status <Badge text={this.currentRoomStatus().status}
                                                                               variant={this.currentRoomStatus().variant}/></Text>
                            <Text variant="caption">{this.currentRoomStatus().description}</Text>
                        </GridColumn>
                        <GridColumn size="full"><Divider/></GridColumn>
                        <GridColumn size="full">
                            <SmartAclManagementDetails room={this.props.room}
                                                       meetingRoomAccessControl={this.props.meetingRoomAccessControl}/>
                        </GridColumn>
                        <GridColumn size="full"><Divider/></GridColumn>
                        <GridColumn size="full">
                            <MeetingRoomEnablementDetails room={this.props.room}
                                                          meetingRoomAccessControl={this.props.meetingRoomAccessControl}/>
                        </GridColumn>
                    </Grid>
                } isFalse={() =>
                    <EmptyState icon={<Wind/>}
                                text="There are no access-control in this meeting room"/>}
                />
            }
            />
        </>;
    }
}

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    fetchMeetingRoomAccessControlAction
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
    // User
    user: state.oidc.user,

    // API data
    meetingRoomAccessControl: state.api.accessControl.entity,

    // Page data
    isLoadingRoomAccessControl: state.pages.roomEdit.accessControl.isLoadingRoomAccessControl,
    accessControlErrors: state.pages.roomEdit.accessControl.accessControlErrors
});

MeetingRoomAccessControlView.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    // Actions
    fetchMeetingRoomAccessControlAction: PropTypes.func,

    // Redux
    user: PropTypes.shape(UserShape),
    meetingRoomAccessControl: PropTypes.shape(MeetingRoomAccessControlResponseShape),
    isLoadingRoomAccessControl: PropTypes.bool,
    accessControlErrors: PropTypes.shape(ErrorShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoomAccessControlView);
