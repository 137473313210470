import moment from "moment";
import "moment-timezone";
import React from "react";
import * as PropTypes from 'prop-types';
import ArrowRightBold from "@bookingcom/bui-react/icons/ArrowRightBold";
import CloseBold from "@bookingcom/bui-react/icons/CloseBold";
import Calendar from "@bookingcom/bui-react/icons/Calendar";
import {Icon} from "@bookingcom/bui-react";
import CheckmarkBold from "@bookingcom/bui-react/icons/CheckmarkBold";
import CopyToClipboardId from "../copy-to-clipboard-id/CopyToClipboardId";
import {enumToCssClass} from "../../helpers";
import styles from './MaintenanceListItem.module.css';
import {MaintenanceWindowShape} from "../../api/room-maintenance/shapes";
import {ROOM_EDIT_PAGE_FUNC, ROOM_EDIT_TABS} from "../../appRoutes";
import Link from "react-router-dom/Link";
import RbacButton from "../rbac-button/RbacButton";
import {RBAC_ROOM_MAINTENANCE} from "../../constants";

/**
 * Enum for item status.
 * @readonly
 * @enum {{string}}
 */
const Status = Object.freeze({
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED',
    IN_PROGRESS: 'IN_PROGRESS',
    PLANNED: 'PLANNED',
});

const MaintenanceListItem = props => {

    const getStatus = () => {
        if (props.entity.isDeleted) {
            return Status.CANCELLED;
        }
        else {
            const now = moment();

            if (toMoment < now) {
                return Status.COMPLETED;
            }
            else if (fromMoment < now && now < toMoment) {
                return Status.IN_PROGRESS;
            }

            return Status.PLANNED;
        }
    };

    const renderStatus = status => {
        let icon = null;
        let hint = null;

        switch (status) {
            case Status.CANCELLED:
                icon = <CloseBold />;
                hint = "Cancelled";
                break;
            case Status.IN_PROGRESS:
                icon = <ArrowRightBold/>;
                hint = "In progress";
                break;
            case Status.COMPLETED:
                icon = <CheckmarkBold />;
                hint = "Completed";
                break;
            case Status.PLANNED:
            default:
                icon = <Calendar />;
                hint = "Planned";
                break;
        }

        return <Icon svg={icon} size="medium" attributes={{title: hint}} />;
    };

    // Parsing from ISO-8601 string like '2019-04-08'
    const fromMoment = moment(props.entity.from).tz(props.entity.meetingRoom.building.timeZone);
    const fromTimestamp = fromMoment.format("DD MMM YYYY HH:mm:ss");

    const toMoment = moment(props.entity.to).tz(props.entity.meetingRoom.building.timeZone);
    const toTimestamp = toMoment.format("DD MMM YYYY HH:mm:ss");

    const meetingRoom = props.entity.meetingRoom;
    const meetingRoomLink = <Link to={ROOM_EDIT_PAGE_FUNC(meetingRoom.id, ROOM_EDIT_TABS.ROOM_MAINTENANCE)}>
        {meetingRoom.code} - {meetingRoom.name}
    </Link>;

    const status = getStatus();

    const componentCssClass = styles['component'];
    const componentModifiedCssClass = styles[`component--${enumToCssClass(status)}`];
    const trClassName = `bui-table__row ${componentCssClass} ${componentModifiedCssClass}`;

    return (
        <tr className={trClassName}>
            <td className="bui-table__cell">
                <CopyToClipboardId text={props.entity.id} />
            </td>
            <td className="bui-table__cell">
                {renderStatus(status)}
            </td>
            <td className={`bui-table__cell ${styles['component__from']}`}>
                {fromTimestamp}<br />
                {toTimestamp}
            </td>
            <td className={`bui-table__cell ${styles['component__room']}`}>
                {meetingRoomLink}
            </td>
            <td className={`bui-table__cell ${styles['component__reason']}`}>
                {props.entity.reason}
            </td>
            <td className={`bui-table__cell ${styles['component__user']}`}>
                {props.entity.userId}
            </td>
            <td className="bui-table__cell bui-table__cell--align-end">
                {
                    status === Status.IN_PROGRESS || status === Status.PLANNED ?
                        <RbacButton
                            rbacRight={RBAC_ROOM_MAINTENANCE}
                            className={styles['component__cancel']}
                            icon={<CloseBold />}
                            variant="secondary"
                            loading={props.isLoading}
                            onClick={() => props.onCancelClicked(props.entity.id)}
                            attributes={{title:"Cancel room maintenance"}} /> :
                        ''
                }
                {/*<Button disabled={true} text="Details" />*/}
                {/*<Link to={MAINTENANCE_DETAILS_PAGE_FUNC(props.item.id)}*/}
                      {/*className="bui-button bui-button--primary">Details</Link>*/}
            </td>
        </tr>
    );
};

MaintenanceListItem.propTypes = {
    entity: PropTypes.shape(MaintenanceWindowShape).isRequired,
    isLoading: PropTypes.bool,
    onCancelClicked: PropTypes.func.isRequired,
};

export default MaintenanceListItem;
