import _ from "lodash";
import React, {useState} from "react";
import * as PropTypes from "prop-types";
import Text from "@bookingcom/bui-react/components/Text";
import InputCheckbox from "@bookingcom/bui-react/components/InputCheckbox";
import Alert from "@bookingcom/bui-react/components/Alert";
import {RenderIf} from "../../../components/render-if/RenderIf";
import {RecurringEventsLimiterShape} from "../../../api/rooms/shapes";

const RecurringEventsLimiterDetails = props => {
    const [entity, setEntity] = useState(props.value);

    if (_.isEmpty(props.value)) return '';

    if (props.value !== entity) {
        // Update entity whenever `props` is updated
        setEntity(props.value);
    }

    const onValueChanged = ({name, value}) => {
        setEntity(newEntity => {
            // Update state
            newEntity[name] = value;
            // Trigger callback
            props.onChanged({
                name: props.name,
                value: newEntity,
            });
            return newEntity;
        });
    };

    return <>
        <Text variant="heading" className="bui-spacer">{props.label}</Text>
        <InputCheckbox
            label="Limit recurring events"
            name="isEnabled"
            onChange={onValueChanged}
            value={entity.isEnabled}
        />
        <br/>

        <RenderIf condition={props.isEnabled}
          render={() =>
              <Alert
                  text={`Recurring events in this room will be limited by ${props.maxDuration.humanize()} maximum.`}
                  variant='info'
              />}
        />
    </>;
};

RecurringEventsLimiterDetails.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.shape(RecurringEventsLimiterShape),
    onChanged: PropTypes.func,
};

export default RecurringEventsLimiterDetails;
