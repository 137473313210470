import {takeEvery} from 'redux-saga/effects';
import {httpGet, processApiResponse} from '../../helpers';
import {API} from "../../apiRoutes";
import {
    TENANTS_FETCHING,
    fetchEntitiesResultAction,
    fetchEntitiesFailedAction
} from './actions';

function* fetchEntities() {

    const response = yield* httpGet(API.sharing.tenants.list);
    yield* processApiResponse(
        response,
        fetchEntitiesResultAction,
        fetchEntitiesFailedAction,
    );
}

export const tenantsSagas = [
    takeEvery(TENANTS_FETCHING, fetchEntities)
];