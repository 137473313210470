import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@bookingcom/bui-react/components/Grid';
import {GridColumn} from '@bookingcom/bui-react';
import InputSelect from '@bookingcom/bui-react/components/InputSelect';
import {MeetingRoomShape} from "../../../../api/rooms/shapes";
import {buildNewAirMediaUnit, buildNewVcCodec} from "../../../../factories";
import AirMediaEditorView from "../../../../components/air-media-editor-view/AirMediaEditorView";
import VcCodecEditorView from "../../../../components/vc-codec-editor-view/VcCodecEditorView";
import {RenderIf} from "../../../../components/render-if/RenderIf";

/**
 * Enum for item status.
 * @readonly
 * @enum {{string}}
 */
const DeviceTypeOptions = Object.freeze({
    NOT_SELECTED: 'NOT_SELECTED',
    VC_CODEC: 'VC_CODEC',
    AIR_MEDIA_UNIT: 'AIR_MEDIA_UNIT',
});

const DeviceTypes = [
    { name: 'Select a device type', id: DeviceTypeOptions.NOT_SELECTED },
    { name: 'Video Conference Unit', id: DeviceTypeOptions.VC_CODEC },
    { name: 'Air Media', id: DeviceTypeOptions.AIR_MEDIA_UNIT },
];

class AddVcDevice extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addView: null,
            newEntity: null,
        };
    }

    onDeviceTypeChanged = ({value}) => {
        let addView;
        let newEntity;

        switch (value) {
            case DeviceTypeOptions.AIR_MEDIA_UNIT:
                addView = AirMediaEditorView;
                newEntity = buildNewAirMediaUnit(this.props.room);
                break;
            case DeviceTypeOptions.VC_CODEC:
                addView = VcCodecEditorView;
                newEntity = buildNewVcCodec(this.props.room);
                break;
            default:
                return;
        }

        this.setState({addView, newEntity});
    };

    render() {
        let AddView = this.state.addView;

        return <Grid>
            <GridColumn size="full">
                <InputSelect
                    label="Device type"
                    name="deviceType"
                    options={
                        DeviceTypes.map(v => ({
                            text: v.name,
                            value: v.id,
                            key: v.id,
                        })
                    )}
                    onChange={this.onDeviceTypeChanged}
                />
            </GridColumn>
            <RenderIf condition={!!AddView} render={() =>
                <GridColumn size="full">
                    <AddView
                        entity={this.state.newEntity}
                        onSave={this.props.onSave}
                        onCancel={this.props.onCancel}
                    />
                </GridColumn>}
            />
        </Grid>;
    }
}

AddVcDevice.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    onCancel: PropTypes.func,
    onSave: PropTypes.func,
};

export default AddVcDevice;
