import {takeEvery} from 'redux-saga/effects';

import {
    ACCESS_CONTROL_FETCHING,
    ACCESS_CONTROL_MEETING_ROOM_DEDICATING,
    ACCESS_CONTROL_MEETING_ROOM_DISABLING,
    ACCESS_CONTROL_MEETING_ROOM_ENABLING,
    ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATING,
    ACCESS_CONTROL_SMART_ACL_ENABLING,
    dedicateMeetingRoomFailedAction,
    dedicateMeetingRoomResultAction,
    disableMeetingRoomFailedAction,
    disableMeetingRoomResultAction,
    enableMeetingRoomFailedAction,
    enableMeetingRoomResultAction,
    enableSmartAclFailedAction,
    enableSmartAclResultAction,
    unDedicateMeetingRoomFailedAction,
    unDedicateMeetingRoomResultAction
} from "./actions";
import {API} from "../apiRoutes";
import {httpGet, httpPut, processApiResponse} from "../helpers";
import {fetchEntityFailedAction, fetchEntityResultAction} from "./actions";

function* fetchEntity(action) {
    const {roomId} = action.payload;
    const response = yield* httpGet(API.accessControl.meetingRoomAccessControl.byRoomId(roomId));
    yield* processApiResponse(
        response,
        entity => fetchEntityResultAction(roomId, entity),
        error => fetchEntityFailedAction(roomId, error),
    );
}

function* enableSmartAclManagement(action) {
    const {entity} = action.payload;
    const response = yield* httpPut(API.accessControl.enableSmartAclManagement.endpoint, entity);
    yield* processApiResponse(response,
        () => enableSmartAclResultAction(entity),
        error => enableSmartAclFailedAction(entity, error));
}

function* enableMeetingRoom(action) {
    const {entity} = action.payload;
    const response = yield* httpPut(API.accessControl.enableMeetingRoom.endpoint, entity);
    yield* processApiResponse(response,
        () => enableMeetingRoomResultAction(entity),
        error => enableMeetingRoomFailedAction(entity, error));
}

function* disableMeetingRoom(action) {
    const {entity} = action.payload;
    const response = yield* httpPut(API.accessControl.disableMeetingRoom.endpoint, entity);
    yield* processApiResponse(response,
        () => disableMeetingRoomResultAction(entity),
        error => disableMeetingRoomFailedAction(entity, error));
}

function* dedicateMeetingRoom(action) {
    const {entity} = action.payload;
    const response = yield* httpPut(API.accessControl.dedicateMeetingRoom.endpoint, entity);
    yield* processApiResponse(response,
        () => dedicateMeetingRoomResultAction(entity),
        error => dedicateMeetingRoomFailedAction(entity, error));
}

function* unDedicateMeetingRoom(action) {
    const {entity} = action.payload;
    const response = yield* httpPut(API.accessControl.unDedicateMeetingRoom.endpoint, entity);
    yield* processApiResponse(response,
        () => unDedicateMeetingRoomResultAction(entity),
        error => unDedicateMeetingRoomFailedAction(entity, error));
}

export const accessControlSagas = [
    takeEvery(ACCESS_CONTROL_FETCHING, fetchEntity),
    takeEvery(ACCESS_CONTROL_SMART_ACL_ENABLING, enableSmartAclManagement),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_ENABLING, enableMeetingRoom),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_DISABLING, disableMeetingRoom),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_DEDICATING, dedicateMeetingRoom),
    takeEvery(ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATING, unDedicateMeetingRoom)
];