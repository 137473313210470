import * as _ from 'lodash';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import Grid from '@bookingcom/bui-react/components/Grid';
import { Button, GridColumn } from '@bookingcom/bui-react';
import IconCheckmarkBold from '@bookingcom/bui-react/icons/CheckmarkBold';
import Form from '../form/Form';
import { ErrorMessage } from "../../_app/ErrorMessage";
import { ErrorShape } from "../../_app/appPropShapes";
import { RenderIf } from '../render-if/RenderIf';

class ModelEditor extends React.Component {
    constructor(props) {
        super(props);

        const model = _.cloneDeep(props.model);
        // Treat current value as the "initial" one
        const initialModel = _.cloneDeep(props.model);

        this.state = {
            model,
            initialModel,
            isValid: false,
            modelErrors: {},
        };
    }

    /**
     * Returns `true` when the current model is different from the initial one.
     * @returns {boolean} Model was changed
     */
    isDirty = () => {
        if (_.isEmpty(this.state.initialModel)) {
            return false;
        }

        if (!_.isEmpty(this.state.initialModel) && !_.isEmpty(this.state.model)) {
            return !_.isEqual(this.state.initialModel, this.state.model);
        }

        return false;
    };

    isReadyToSubmit = () => this.isDirty() && this.state.isValid;

    onBlur = e => {
        const name = e.name || (!!e.currentTarget && e.currentTarget.name);
        const value = e.value || (!!e.currentTarget && e.currentTarget.value);

        const modelErrors = { ...this.state.modelErrors };

        try {
            this.props.modelSchema.fields[name].validateSync(value);
            delete modelErrors[name];
        } catch (error) {
            modelErrors[name] = error.message;
        }

        let isValid = true;
        const model = { ...this.state.model };
        model[name] = value;

        try {
            this.props.modelSchema.validateSync(model, { abortEarly: false, convert: true });
        }
        catch (error) {
            console.warn(error);
            isValid = false;
        }

        this.setState({ model, isValid, modelErrors });
    };

    onSave = () => {
        const modelErrors = {};
        let isValid = true;
        try {
            // Validate
            this.props.modelSchema.validateSync(this.state.model, { abortEarly: false, convert: true });
            // If validation not failed, then save
            this.props.onSave(this.state.model);
        } catch (error) {
            isValid = false;
            // Validation failed
            for (const err of error.inner) {
                modelErrors[err.path] = err.message;
            }
        }
        this.setState({ modelErrors, isValid });
    };

    render = () =>
        <Grid>
            <GridColumn size="full">
                <Form
                    legend={this.props.legend}
                    model={this.state.model}
                    grid={this.props.grid}
                    modelErrors={this.state.modelErrors}
                    onBlur={this.onBlur}
                // onValueChange={this.onValueChange}
                />
                <ErrorMessage error={this.props.saveError} />
            </GridColumn>
            <RenderIf condition={this.props.footer !== undefined} render={() =>
                <GridColumn size="full">
                    {this.props.footer()}
                </GridColumn>}>
            </RenderIf>
            <GridColumn size="full">
                <Button
                    loading={this.props.isSaving}
                    disabled={!this.isReadyToSubmit()}
                    onClick={this.onSave}
                    icon={<IconCheckmarkBold />}
                    text="Save"
                />
                &nbsp;
                <Button
                    onClick={this.props.onCancel}
                    variant="secondary"
                    text="Cancel"
                />
            </GridColumn>
        </Grid>;
}

ModelEditor.propTypes = {
    legend: PropTypes.string,
    model: PropTypes.object.isRequired,
    grid: PropTypes.array.isRequired,
    modelSchema: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    footer: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    isSaving: PropTypes.bool,
    saveError: PropTypes.shape(ErrorShape),
};

export default ModelEditor;
