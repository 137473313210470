import * as PropTypes from 'prop-types';

export const USER_NOTIFICATION_VARIANT = Object.freeze({
    UNKNOWN: 'Unknown',
    WARNING: 'Warning',
    INFO: 'Info',
});

export const UserNotificationShape = {
    id: PropTypes.string,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    variant: PropTypes.oneOf(Object.values(USER_NOTIFICATION_VARIANT)),
    contactEmail: PropTypes.string,
    contactWorkplaceId: PropTypes.string,
    contactWorkplaceName: PropTypes.string,
    effectiveFrom: PropTypes.string,
    effectiveTo: PropTypes.string,
    showToUsers: PropTypes.arrayOf(PropTypes.string),
};
