import * as PropTypes from "prop-types";
import React from "react";
import {RenderEither} from "../render-either/RenderEither";

export const RenderIf = props =>
    <RenderEither
        condition={props.condition}
        isTrue={props.render}
        isFalse={() => ''}
    />;

RenderIf.propTypes = {
    condition: PropTypes.bool,
    render: PropTypes.func,
};
