import {all} from 'redux-saga/effects';
import {appSagas} from './_app/sagas';
import {apiSagas} from './api/sagas';
import {viewSagas} from "./pages/sagas";

/*
 * Sagas are used for pieces of functionality with any side effects.
 *
 * https://redux-saga.js.org/docs/ExternalResources.html
 *
 */

/**
 * The `function*` declaration (`function` keyword followed by an asterisk) defines a *generator function*, which returns a `Generator` object.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators
 *
 *
 */

export function* rootSaga() {
    yield all([
        ...appSagas,
        ...apiSagas,
        ...viewSagas,
    ]);
}
