import React, {useState} from "react";
import * as PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Button} from "@bookingcom/bui-react";
import {hasRbacRight} from "../../helpers";
import styles from "./styles.module.css";
import Modal from "@bookingcom/bui-react/components/Modal";

const RbacButton = props => {
    const doesntHaveRequiredRbacRight = !hasRbacRight(props.user, props.rbacRight);
    const buttonProps = {...props};

    let className = props.className;

    if (doesntHaveRequiredRbacRight) {
        if (!buttonProps.attributes) {
            buttonProps.attributes = {};
        }
        buttonProps.attributes.title = `You don't have required RBAC right \`${props.rbacRight}\` for this operation`;
        buttonProps.disabled = true;

        if (!className) {
            className = styles['__disabled'];
        }
        else {
            className = `${className} ${styles['__disabled']}`;
        }
    }

    const [shouldDisplayModal, setShouldDisplayModal] = useState(false);
    const onButtonClick = !!props.prompt ?
        () => { setShouldDisplayModal(true); } :
        props.onClick;
    const onModalClose = () =>  { setShouldDisplayModal(false); };

    const onModalSubmit = props.onClick;

    let modalElement = null;
    if (!!props.prompt) {
        modalElement = <Modal
            title={props.prompt.title}
            subtitle={props.prompt.subtitle}
            closeAriaLabel="Close modal"
            active={shouldDisplayModal}
            onClose={onModalClose}
            buttons={[{
                text: props.prompt.buttonText,
                variant: 'destructive',
                type: 'submit',
                // loading: this.isCancelling(this.state.idCancelPrompt),
                onClick: onModalSubmit,
            }]}
        />;
    }

    return <>
        <Button {...buttonProps} onClick={onButtonClick} className={className} />

        {modalElement}
    </>;
};

const mapStateToProps = state => ({
    // User
    user: state.oidc.user,
});

const promptShape = {
    buttonText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
};

RbacButton.propTypes = {
    rbacRight: PropTypes.string.isRequired,
    prompt: PropTypes.shape(promptShape),
};

export default connect(mapStateToProps)(RbacButton);
