import React from "react";
import * as PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import Icon from "@bookingcom/bui-react/components/Icon";
import CheckmarkUnselected from "@bookingcom/bui-react/icons/CheckmarkUnselected";
import Stop from "@bookingcom/bui-react/icons/Stop";
import {ROOM_EDIT_PAGE_FUNC} from "../../appRoutes";
import {MeetingRoomShape} from "../../api/rooms/shapes";
import './RoomListItem.css';
import {RenderEither} from "../../components/render-either/RenderEither";

export const RoomListItem = props => (
    <tr className="bui-table__row mrt-room-list-item">
        <td className="bui-table__cell">
            {props.room.name}
        </td>
        <td className="bui-table__cell">
            {props.room.building.code}
        </td>
        <td className="bui-table__cell">
            {props.room.floor}
        </td>
        <td className="bui-table__cell">
            {props.room.number}
        </td>
        <td className="bui-table__cell">
            <RenderEither
                condition={props.room.isEnabled}
                isTrue={() =>
                    <Icon svg={<CheckmarkUnselected/>} size="largest" color="constructive"/>}
                isFalse={() =>
                    <Icon svg={<Stop/>} size="largest" color="destructive"/>}
            />
        </td>
        <td className="bui-table__cell bui-table__cell--align-end">
            <Link to={ROOM_EDIT_PAGE_FUNC(props.room.code)} className="bui-button bui-button--primary">Edit</Link>
        </td>
    </tr>
);

RoomListItem.propTypes = {
    room: PropTypes.shape(MeetingRoomShape),
};
