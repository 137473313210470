import {useEffect} from 'react';
import * as PropTypes from 'prop-types';
import {APP_TITLE} from "../../constants";

const DocumentTitle = props => {
    useEffect(
        () => { document.title = `${APP_TITLE} - ${props.title}`; },
        [props.title]);

    return props.children;
};

DocumentTitle.propTypes = {
    title: PropTypes.string.isRequired,
};

export default DocumentTitle;
