import {
    ACCESS_CONTROL_FETCHED,
    ACCESS_CONTROL_SMART_ACL_ENABLED,
    ACCESS_CONTROL_MEETING_ROOM_DISABLED,
    ACCESS_CONTROL_MEETING_ROOM_DEDICATED,
    ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED,
    ACCESS_CONTROL_MEETING_ROOM_ENABLED
} from './actions';
import {upsertEntity} from "../../reducerHelpers";

const initialState = {
    entity: {},
};

export const accessControlApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case ACCESS_CONTROL_FETCHED: {
            const entity = {...action.payload.entity};
            return {
                ...reducerState,
                entity
            };
        }
        case ACCESS_CONTROL_SMART_ACL_ENABLED:
        case ACCESS_CONTROL_MEETING_ROOM_ENABLED:
        case ACCESS_CONTROL_MEETING_ROOM_DISABLED:
        case ACCESS_CONTROL_MEETING_ROOM_DEDICATED:
        case ACCESS_CONTROL_MEETING_ROOM_UN_DEDICATED: {
            const entity = {...action.payload.entity};
            return {
                ...reducerState,
                entity: upsertEntity(reducerState.entity, action.payload.entity.roomId, entity),
            };
        }
        default:
            return reducerState;
    }
};