/**
 * Finds the audit log for entity by its type and ID.
 * @param auditLog
 * @param entityType
 * @param entityId
 * @returns {*}
 */
export const auditLogSelector = (auditLog, entityType, entityId) => {
    if (!!auditLog.entitiesByType && !!auditLog.entitiesByType[entityType] && !!auditLog.entitiesByType[entityType][entityId]) {
        return auditLog.entitiesByType[entityType][entityId];
    }

    return null;
};
