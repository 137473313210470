export const DedicatingStatuses = {
    UN_DEDICATED: "UN_DEDICATED",
    DEDICATED: "DEDICATED",
    DEDICATING_REQUEST: "DEDICATING_REQUEST",
    PASSPORT_POLICIES_CREATED: "PASSPORT_POLICIES_CREATED",
    PASSPORT_POLICIES_DELETED: "PASSPORT_POLICIES_DELETED",
    PENDING_UN_DEDICATING: "PENDING_UN_DEDICATING"
}

export const SmartAclManagementStatus = {
    ENABLED: "ENABLED",
    DISABLED: "DISABLED",
    PROCESSING: "PROCESSING"
}

export const PassportPoliciesAction = {
    BASE_POLICY_URL: "https://passport.booking.com/policy/",
    MANAGE: {
        action: "MANAGE",
        label: "Manage Policy",
        description: "This policy provides read and write access to the calendar, private events will appear to users with writer access, and event details will be visible",
    },
    BOOK: {
        action: "BOOK",
        label: "Book Policy",
        description: "This policy gives permission to only book the room, and you'll see all events as freeBusyReader"
    }
}

export const CurrentRoomStatuses = {
    BOOKABLE: {
        status: "Bookable",
        description: "This room can be booked by anyone",
        variant: "constructive"
    },
    DEDICATED: {
        status: "Dedicated",
        description: "This room can be booked by specific groups.",
        variant: "complement"
    },
    NON_BOOKABLE: {
        status: "Non-Bookable",
        description: "This room cannot be booked by anyone.",
        variant: "destructive"
    },
}