import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from "prop-types";
import React from 'react';
import {UserNotification} from '../user-notification/UserNotification';
import {UserNotificationShape} from '../../api/user-notifications/shapes';
import {getUserName} from '../../_auth/userHelpers';

export const UserNotificationSet = props => {
    const currentLocation = document.location.pathname;
    const toDisplay = props.entities.filter(x => shouldDisplay(x, currentLocation, props.user));

    return toDisplay.map(x => <UserNotification entity={x} key={x.id} />);
};

function shouldDisplay(userNotification, currentLocation, currentUser) {
    let isMatchingRoute = false;
    let isMatchingUser = false;
    let isMatchingDateTime = false;

    if (userNotification.route === '*') {
        isMatchingRoute = true;
    }
    else {
        isMatchingRoute = userNotification.route === currentLocation;
    }

    if (!_.isEmpty(userNotification.showToUsers)) {
        const userId = getUserName(currentUser);
        if (_.includes(userNotification.showToUsers, userId)) {
            isMatchingUser = true;
        }
    }
    else {
        isMatchingUser = true;
    }

    if (!_.isEmpty(userNotification.effectiveFrom) || !_.isEmpty(userNotification.effectiveTo)) {
        const nowMoment = moment().utc();
        const fromMoment = moment(userNotification.effectiveFrom);
        const toMoment = moment(userNotification.effectiveTo);

        if (fromMoment < nowMoment && nowMoment < toMoment) {
            isMatchingDateTime = true;
        }
    }
    else {
        isMatchingDateTime = true;
    }

    return isMatchingRoute && isMatchingUser && isMatchingDateTime;
}

UserNotificationSet.propTypes = {
    entities: PropTypes.arrayOf(PropTypes.shape(UserNotificationShape)),
};
