/** Internal route. Don't use it */
export const LOGIN_PAGE = '/';

/** Internal route. Don't use it */
export const LOGOUT_PAGE = '/logout';

/** Internal route. Don't use it */
export const CALLBACK_PAGE = '/callback';


/** Empty page for tests */
export const EMPTY_PAGE = '/empty';


/** Room list page */
export const ROOMS_PAGE = '/rooms';

/**
 * Creates route for rooms search by parameters.
 *
 * @param buildingCode
 * @param searchQuery
 * @returns {string} Route
 *
 * @example
 * ROOMS_BY_SEARCH_FUNC("AMS9"); // -> "/rooms?buildingCode=AMS9"
 * @example
 * ROOMS_BY_SEARCH_FUNC(null, "Ta"); // -> "/rooms?searchQuery=Ta"
 * @example
 * ROOMS_BY_SEARCH_FUNC("AMS9", "Ta"); // -> "/rooms?buildingCode=AMS9&searchQuery=Ta"
 */
export const ROOMS_BY_SEARCH_FUNC = (buildingCode, searchQuery = '') => {
    let queryString = '';

    if (buildingCode) {
        queryString += `buildingCode=${buildingCode}`;
    }

    if (searchQuery) {
        if (queryString) {
            queryString += '&';
        }

        queryString += `searchQuery=${searchQuery}`;
    }

    return `${ROOMS_PAGE}?${queryString}`;
};


/** Room edit page */
// `identifier` could be either ID or Room code
export const ROOM_EDIT_PAGE = `${ROOMS_PAGE}/:identifier`;

export const ROOM_EDIT_TABS = Object.freeze({
    DETAILS: 'details',
    EQUIPMENT: 'equipment',
    SHARING: 'sharing',
    ROOM_MAINTENANCE: 'room-maintenance',
    ACCESS_CONTROL: 'access-control'
});

// `identifier` could be either ID or Room code
export const ROOM_EDIT_PAGE_FUNC = (identifier, tab = '') => `${ROOMS_PAGE}/${identifier}/${tab}`;


/** New Room page */
export const ROOM_CREATE_PAGE = `${ROOMS_PAGE}/new`;


/** Room maintenance */
export const MAINTENANCE_PAGE = '/room-maintenance';

export const MAINTENANCE_DETAILS_PAGE = `${MAINTENANCE_PAGE}/:id`;

/** Room deployments */
export const ROOM_DEPLOYMENTS_PAGE = '/room-deployments';
