import React from 'react';
import * as PropTypes from 'prop-types';
import {Redirect} from 'react-router';
import {Route} from 'react-router-dom';
import {LOGIN_PAGE} from '../../appRoutes';
import {LocationShape} from "../../_app/appPropShapes";

/**
 * AuthenticatedRoute protects a route using the given `authFn` function.
 * @param Component
 * @param authFn
 * @param rest
 * @returns {*}
 * @constructor
 */
const AuthenticatedRoute = ({component: Component, authFn, ...rest}) => <Route
    {...rest}
    render={props =>
        authFn(props)
            ? <Component {...props}/>
            : <Redirect to={{
                pathname: LOGIN_PAGE,
                state: {from: props.location},
            }}
            />}
/>;

AuthenticatedRoute.propTypes = {
    location: PropTypes.shape(LocationShape),
    component: PropTypes.elementType.isRequired,
    authFn: PropTypes.func.isRequired,
};

export default AuthenticatedRoute;
