import React from 'react';
import Card from "@bookingcom/bui-react/components/Card";
import AllIcons from "../components/all-icons/AllIcons";

/** Test page */
export default () => (
    <Card className="bui-f-depth-1">
        <AllIcons />
    </Card>
);
