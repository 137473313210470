import _ from "lodash";

export const obsoleteSelectEntity = (entities, id) => {
    if (_.isArray(entities) && entities.length > 0) {
        const entity = entities.find(r => r.id === id);
        if (!_.isEmpty(entity)) {
            return entity;
        }
    }

    return null;
};

export const selectItemById = (entities, id) => {
    if (!_.isEmpty(entities)) {
        const entity = entities[id];
        if (!!entity) {
            return entity;
        }
    }

    return null;
};
