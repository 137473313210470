import {
    SHARING_REQUESTS_FETCHED,
    SHARING_REQUEST_CREATED,
    SHARING_REQUEST_CANCELED,
    SHARING_SYNC_APPROVED,
} from './actions';
import {upsertEntity} from '../../reducerHelpers';

const initialState = {
    entities: {},
};


export const upsertSharingEntities = (entities, newEntities) => {
    const result = {};

    for (const entity of newEntities) {
        result[entity.tenantId] = entity;
    }
    

    return result;
};

export const sharingRequestsApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case SHARING_REQUESTS_FETCHED: {
            const entities = upsertSharingEntities(reducerState.entities, action.payload.entities);

            return {
                ...reducerState,
                entities
            };
        }
        case SHARING_SYNC_APPROVED: {
            const entities = {}
            Object.keys(reducerState.entities).forEach(key => {
                entities[key] = action.payload.entity.status;
            });

            return {
                ...reducerState,
                sharingRoomRequests: entities
            };
        }
        case SHARING_REQUEST_CREATED:
        case SHARING_REQUEST_CANCELED: {
            const entity = {...action.payload.entity};
            return {
                ...reducerState,
                entities: upsertEntity(reducerState.entities, action.payload.entity.tenantId, entity),
            };
        }
        default:
            return reducerState;
    }
};
