import React from 'react';
import * as PropTypes from 'prop-types';
import * as yup from "yup";
import moment from "moment";
import {GridColumn} from '@bookingcom/bui-react';
import Grid from '@bookingcom/bui-react/components/Grid';
import InputText from '@bookingcom/bui-react/components/InputText';
import Alert from '@bookingcom/bui-react/components/Alert';
import Calendar from "@bookingcom/bui-react/components/Calendar";
import {MaintenanceWindowShape} from "../../../api/room-maintenance/shapes";
import Text from "@bookingcom/bui-react/components/Text";

const MAX_REASON_LENGTH = 100;

function isMoment(value) {
    return !!value && value._isAMomentObject;
}

// eslint-disable-next-line
const isNotMomentErrorMessage = '${path} is not a `moment` object';

function whenFrom(from, schema) {
    if (!from) {
        return schema;
    }

    return schema
        .test('is-moment', isNotMomentErrorMessage, isMoment)
        .test(
            'max-date',
            'Maintenance window can be only 2 weeks long max. Consider disabling the room instead',
            value => {
                if (!value) {
                    return false;
                }

                // 13 days will be actually 14 days (because last day is fully included)
                const maxDate = moment(from).add(13, 'days');
                const difference = maxDate.diff(value);
                return difference >= 0;
            });
}

const entityValidationSchema = yup.object().shape({
    meetingRoom: yup.object().required().label('Room'),
    reason: yup.string().required().min(4).max(MAX_REASON_LENGTH).label('Reason'),
    from: yup.mixed().test('is-moment', isNotMomentErrorMessage, isMoment).required().label('Start'),
    to: yup.mixed().test('is-moment', isNotMomentErrorMessage, isMoment).required().when('from', whenFrom).label('End'),
});

class WindowDetails extends React.Component {
    calendarMinDate = moment().add(1, 'days').toDate();

    constructor(props) {
        super(props);

        // TODO: Comment the way model errors should work.
        this.state = {
            modelErrors: props.modelErrors || {},
        };
    }

    onFieldBlur = e => {
        const name = e.name || (!!e.currentTarget && e.currentTarget.name);
        const value = e.value || (!!e.currentTarget && e.currentTarget.value);

        // HACK: name2/value2 are for <Calendar> updates. It gives only both dates at the same time.
        const name2 = !!e.currentTarget && e.currentTarget.name2;
        const value2 = !!e.currentTarget && e.currentTarget.value2;

        const modelErrors = {...this.state.modelErrors};
        const entity = {...this.props.entity};

        // Update the entity
        entity[name] = value;

        if (name2) {
            entity[name2] = value2;
        }

        // Validate updated field
        try {
            entityValidationSchema.validateSyncAt(name, entity);
            delete modelErrors[name];
        } catch (error) {
            modelErrors[name] = error.message;
        }

        // Validate updated field (secondary; e.g. calendar)
        if (name2) {
            try {
                entityValidationSchema.validateSyncAt(name2, entity);
                delete modelErrors[name2];
            } catch (error) {
                modelErrors[name2] = error.message;
            }
        }

        // Set errors
        this.setState({modelErrors});

        // Trigger `onChange`
        this.props.onChange(entity);

        try {
            // Notify about `isValid` state change
            const isValid = entityValidationSchema.isValidSync(entity);
            this.props.onIsValidChanged(isValid);
        }
        catch (error) {
            // This should never happen
            debugger;
            this.props.onIsValidChanged(false);
        }
    };

    onDateChange = e => {
        const from = e.startDate ? moment(e.startDate) : null;
        const to = e.endDate ? moment(e.endDate) : null;
        this.onFieldBlur({
            currentTarget: {
                name: 'from',
                value: from,
                name2: 'to',
                value2: to,
        }});
    };

    renderAlert = () => {
        return this.state.modelErrors['_model'] ?
            <Alert
                className="bui-spacer"
                variant={'error'}
                text={this.state.modelErrors['_model']}
            /> :
            null;
    };

    render = () => <>
        {this.renderAlert()}
        <Grid>
            <GridColumn size="full">
                <InputText
                    label="Reason"
                    name="reason"
                    onBlur={this.onFieldBlur}
                    onChange={this.onFieldBlur}
                    // onChange={this.onFieldChange}
                    error={this.state.modelErrors.reason}
                />
            </GridColumn>
            <GridColumn size="full">
                <Calendar
                    renderOnHover={true}
                    onDateChange={this.onDateChange}
                    singleDate={false}
                    allowSameDateSelection={true}
                    minDate={this.calendarMinDate}
                    dayNames={{
                        monday: 'Mon',
                        tuesday: 'Tue',
                        wednesday: 'Wed',
                        thursday: 'Thu',
                        friday: 'Fri',
                        saturday: 'Sat',
                        sunday: 'Sun'
                    }}
                    monthNames={{
                        january: 'January',
                        february: 'February',
                        march: 'March',
                        april: 'April',
                        may: 'May',
                        june: 'June',
                        july: 'July',
                        august: 'August',
                        september: 'September',
                        october: 'October',
                        november: 'November',
                        december: 'December'
                    }}
                />
            </GridColumn>
            <GridColumn size="half">
                <InputText
                    label="Start"
                    name="from"
                    value={this.props.entity.from ? this.props.entity.from.format('LL') : ''}
                    error={this.state.modelErrors.from}
                    disabled={true}
                />
            </GridColumn>
            <GridColumn size="half">
                <InputText
                    label="End"
                    name="to"
                    value={this.props.entity.to ? this.props.entity.to.format('LL') : ''}
                    error={this.state.modelErrors.to}
                    disabled={true}
                />
                <Text variant="caption">Inclusive</Text>
            </GridColumn>
        </Grid>
    </>;
}

WindowDetails.propTypes = {
    entity: PropTypes.shape(MaintenanceWindowShape).isRequired,
    onChange: PropTypes.func.isRequired,
    onIsValidChanged: PropTypes.func.isRequired,
};

export default WindowDetails;
