import _ from "lodash";
import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';
import {reducer as oidcReducer} from 'redux-oidc';
import {LOGOUT} from './_app/actions';
import {appReducer} from './_app/reducer';
import {buildingsApiReducer} from './api/buildings/reducer';
import {roomsApiReducer} from './api/rooms/reducer';
import {auditLogsApiReducer} from './api/audit-log/reducer';
import {airMediaUnitsApiReducer} from './api/equipment/air-media-units/reducer';
import {sharingRequestsApiReducer} from './api/sharing/reducer';
import {tenantsApiReducer} from './api/sharing/tenants/reducer';
import {roomMaintenanceApiReducer} from "./api/room-maintenance/reducer";
import {vcCodecsApiReducer} from "./api/equipment/vc-codecs/reducer";
import {roomsListPageReducer} from "./pages/Rooms.List/reducer";
import {roomCreatePageReducer} from './pages/Rooms.Create/reducer';
import {roomEquipmentViewReducer} from './pages/Rooms.Edit/equipment/reducer';
import {sharingViewReducer} from './pages/Rooms.Edit/sharing/reducer';
import {roomDetailsViewReducer} from './pages/Rooms.Edit/details/reducer';
import {roomMaintenanceViewReducer} from "./pages/Rooms.Edit/maintenance/reducer";
import {roomMaintenanceListPageReducer} from "./pages/RoomMaintenance.List/reducer";
import {userNotificationsApiReducer} from "./api/user-notifications/reducer";
import {accessControlApiReducer} from "./api/access-control/reducer";
import {accessControlViewReducer} from "./pages/Rooms.Edit/access-control/reducer";

/**
 * Creates a root reducer function.
 *
 * https://redux.js.org/api/combinereducers
 */
export const createRootReducer = history => {
    const rootReducer = combineReducers({
        /** https://github.com/supasate/connected-react-router */
        router: connectRouter(history),
        /** https://github.com/maxmantz/redux-oidc */
        oidc: oidcReducer,
        app: appReducer,
        pages: combineReducers({
            roomsList: roomsListPageReducer,
            roomEdit: combineReducers({
                details: roomDetailsViewReducer,
                equipment: roomEquipmentViewReducer,
                sharing: sharingViewReducer,
                maintenance: roomMaintenanceViewReducer,
                accessControl: accessControlViewReducer
            }),
            roomCreate: roomCreatePageReducer,
            roomMaintenanceList: roomMaintenanceListPageReducer,
        }),
        api: combineReducers({
            userNotifications: userNotificationsApiReducer,
            buildings: buildingsApiReducer,
            rooms: roomsApiReducer,
            sharing: combineReducers({
                requests: sharingRequestsApiReducer,
                tenants: tenantsApiReducer
            }),
            roomMaintenance: roomMaintenanceApiReducer,
            auditLogs: auditLogsApiReducer,
            equipment: combineReducers({
                airMediaUnits: airMediaUnitsApiReducer,
                vcCodecs: vcCodecsApiReducer,
            }),
            accessControl: accessControlApiReducer
        }),
    });

    return (state, action) => {
        // In case of logout action,
        if (action.type === LOGOUT) {
            // Reset user
            const oidc = {...state.oidc};
            oidc.user = null;

            const router = {...state.router};

            // Reset the state
            return {
                router,
                oidc,
            };
        }

        // Or process other actions
        return rootReducer(state, action);
    };
};

export const upsertEntities = (entities, newEntities) => {
    const result = {...entities};

    for (const entity of newEntities) {
        result[entity.id] = entity;
    }

    return result;
};

export const upsertEntity = (entities, id, value) => {
    const result = {...entities};

    result[id] = value;

    return result;
};

export const updateEntity = (entities, id, lambda) => {
    if (_.isObject(entities) && !!entities[id]) {
        const result = {...entities};
        // Mutate
        lambda(result[id]);
        return result;
    }

    // Bypass
    return entities;
};

export const deleteEntity = (entities, id) => {
    const result = {...entities};

    delete (result[id]);

    return result;
};
