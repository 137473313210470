import {
    AIR_MEDIA_UNITS_FETCHED,
    AIR_MEDIA_UNIT_CREATED,
    AIR_MEDIA_UNIT_SAVED,
    AIR_MEDIA_UNIT_DELETED
} from './actions';
import {deleteEntity, upsertEntities, upsertEntity} from '../../../reducerHelpers';
import {normalizeEntities, normalizeMacAddress} from '../../../normalizers';

const initialState = {
    entities: {},
};

export const airMediaUnitsApiReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case AIR_MEDIA_UNITS_FETCHED: {
            const fetchedEntities = upsertEntities(reducerState.entities, action.payload.entities);
            const entities = normalizeEntities(fetchedEntities, normalizeEntity);
            return {
                ...reducerState,
                entities,
            };
        }
        case AIR_MEDIA_UNIT_CREATED:
        case AIR_MEDIA_UNIT_SAVED: {
            const entity = {...action.payload.entity};
            normalizeEntity(entity);
            return {
                ...reducerState,
                entities: upsertEntity(reducerState.entities, action.payload.entity.id, entity),
            };
        }
        case AIR_MEDIA_UNIT_DELETED: {
            return {
                ...reducerState,
                entities: deleteEntity(reducerState.entities, action.payload.entity.id),
            };
        }
        default:
            return reducerState;
    }
};

const normalizeEntity = entity => {
    entity.macAddress = normalizeMacAddress(entity.macAddress);
    entity.model = "Air Media";
};
