import * as PropTypes from 'prop-types';
import {ApiDtoShape} from "../shapes";

export const BuildingShape = {
    ...ApiDtoShape,
    officeApiId: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    timeZone: PropTypes.string,
    // TODO: Make it `string[]`
    floors: PropTypes.arrayOf(PropTypes.string),
    buildingCode: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    // TODO: Define `BuildingGoogleCalendarShape`
    googleCalendar: PropTypes.object,
};
