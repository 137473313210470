import _ from 'lodash';
import {ConnectedRouter} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {NavLink} from 'react-router-dom';
import ToastProvider from '@bookingcom/bui-react/components/Toast/ToastProvider';
import {Container, GridColumn} from '@bookingcom/bui-react';
import Grid from '@bookingcom/bui-react/components/Grid';
import Text from '@bookingcom/bui-react/components/Text';
import RoomCreatePage from '../pages/Rooms.Create/page';
import RoomEditPage from '../pages/Rooms.Edit/page';
import RoomsPage from '../pages/Rooms.List/page';
import MaintenancePage from '../pages/RoomMaintenance.List/page';
import {
    CALLBACK_PAGE,
    EMPTY_PAGE,
    LOGIN_PAGE,
    LOGOUT_PAGE,
    MAINTENANCE_DETAILS_PAGE,
    MAINTENANCE_PAGE,
    ROOM_CREATE_PAGE,
    ROOM_EDIT_PAGE,
    ROOMS_PAGE,
    ROOM_DEPLOYMENTS_PAGE
} from '../appRoutes';
import {getUser} from '../selectors';
import {history} from '../store';
import CallbackPage from '../_auth/CallbackPage';
import LoginPage from '../_auth/LoginPage';
import LogoutPage from '../_auth/LogoutPage';
import AuthenticatedRoute from '../components/authenticated-route/AuthenticatedRoute';
import {APP_TITLE, RBAC_BASIC_VIEWER} from '../constants';
import {hasViewerRbacRight, throwApplicationError, hasDeploymentsRbacRight} from '../helpers';
import {RenderIf} from '../components/render-if/RenderIf';
import {UserNotificationSet} from '../components/user-notification-set/UserNotificationSet';
import {fetchUserNotificationsAction} from '../api/user-notifications/actions';
import {getUserInitials, getUserName} from '../_auth/userHelpers';
import EmptyPage from './EmptyPage';
import ErrorBoundary from './ErrorBoundary';
import RoomDeploymentsRedirectPage from './RoomDeploymentsRedirectPage';

/**
 * @class App
 *
 */
export class App extends React.Component {

    authFn = () => {
        const isAuthenticated = this.props.user && !this.props.user.expired;

        if (isAuthenticated && !hasViewerRbacRight(this.props.user)) {
            throwApplicationError(
                `You are not authorized to use this application.\nPlease check whether you have RBAC right '${RBAC_BASIC_VIEWER}' enabled.`,
                'RBAC',
                `https://rbac.support.booking.com/AccessRequest/CreateRequest?group=${RBAC_BASIC_VIEWER}`);
        }

        return isAuthenticated;
    };

    /**
     * Component was updated in DOM.
     *
     * Good place to react on update of local state and trigger some actions.
     *
     * Tip:
     * You can use `this.state` and `this.props` inside the method.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEmpty(this.props.user)) {
            if (!this.props.isLoadingUserNotifications && !this.props.hasLoadedUserNotifications) {
                this.props.fetchUserNotificationsAction();
            }
        }
    }

    render() {
        return (
            // https://github.com/supasate/connected-react-router
            // IMPORTANT: <ConnectedRouter /> and <Switch /> with routes must be in the same component. Routing doesn't work properly instead.
            <ConnectedRouter history={history}>
                <ToastProvider>
                    <header className="header s-header">
                        <Container centered={true}>
                            <Grid className="s-header--top">
                                <GridColumn size="10"><Text variant="display_one">{APP_TITLE}</Text></GridColumn>
                                <GridColumn size="2">
                                    {this.renderUser()}
                                </GridColumn>
                            </Grid>
                        </Container>
                    </header>
                    {this.renderContent()}
                </ToastProvider>
            </ConnectedRouter>
        );
    };

    renderUser() {
        if (this.props.user && this.props.user.profile) {
            return (
                <div className="bui-avatar-block bui-avatar-block--small bui-u-pull-end">
                    <div className="bui-avatar bui-avatar--text bui-avatar--callout">
                        <span className="bui-avatar__label">{getUserInitials(this.props.user)}</span>
                    </div>
                    <div className="bui-avatar-block__text">
                        <span className="bui-avatar-block__title">{getUserName(this.props.user)}</span>
                    </div>
                </div>)
        } else {
            return '';
        }
    };

    renderNav = () => (
        <nav className="bui-tab bui-container s-container--tab-bleed">
            <div className="bui-container bui-container--center s-container--bleed">
                <ul className="bui-tab__nav" role="navigation">
                    <li className="bui-tab__item">
                        <NavLink
                            exact={true}
                            to={{pathname: ROOMS_PAGE, state: 'navBar'}}
                            className="bui-tab__link"
                            activeClassName="bui-tab__link--selected"
                            role="tab">Rooms</NavLink>
                    </li>
                    <li className="bui-tab__item">
                        <NavLink
                            exact={true}
                            to={ROOM_CREATE_PAGE}
                            className="bui-tab__link"
                            activeClassName="bui-tab__link--selected"
                            role="tab">Create room</NavLink>
                    </li>
                    <li className="bui-tab__item">
                        <NavLink
                            exact={true}
                            to={MAINTENANCE_PAGE}
                            className="bui-tab__link"
                            activeClassName="bui-tab__link--selected"
                            role="tab">Room maintenance</NavLink>
                    </li>
                    <RenderIf condition={this.props.user && hasDeploymentsRbacRight(this.props.user)} render={
                        () =>
                            <li className="bui-tab__item">
                                <NavLink
                                    exact={true}
                                    to={ROOM_DEPLOYMENTS_PAGE}
                                    className="bui-tab__link"
                                    activeClassName="bui-tab__link--selected"
                                    role="tab">Room deployments</NavLink>
                            </li>
                        }
                    />
                </ul>
            </div>
        </nav>
    );

    renderContent = () => (
        // Error boundary can catch only React errors (e.g. not Saga or Redux errors)
        <ErrorBoundary>
          {this.renderNav()}
          <UserNotificationSet entities={this.props.userNotifications} user={this.props.user} />
          <div className="bui-container bui-container--center s-main-body">
            <Switch>
              <Route exact path={LOGIN_PAGE} component={LoginPage}/>
              <Route exact path={CALLBACK_PAGE} component={CallbackPage}/>
              <Route exact path={LOGOUT_PAGE} component={LogoutPage}/>
              <AuthenticatedRoute exact path={ROOMS_PAGE} component={RoomsPage} authFn={this.authFn}/>
              <AuthenticatedRoute exact path={ROOM_CREATE_PAGE} component={RoomCreatePage} authFn={this.authFn}/>
              <AuthenticatedRoute exact path={ROOM_EDIT_PAGE} component={RoomEditPage} authFn={this.authFn}/>
              <AuthenticatedRoute exact path={`${ROOM_EDIT_PAGE}/:tab`} component={RoomEditPage} authFn={this.authFn}/>
              <AuthenticatedRoute exact path={MAINTENANCE_PAGE} component={MaintenancePage} authFn={this.authFn}/>
              <AuthenticatedRoute exact path={MAINTENANCE_DETAILS_PAGE} component={EmptyPage} authFn={this.authFn}/>
              <Route exact path={ROOM_DEPLOYMENTS_PAGE} component={RoomDeploymentsRedirectPage}/>
              {/* Empty page is just for testing */}
              <Route path={EMPTY_PAGE} component={EmptyPage}/>
              <Route render={x => <div>Error: Route '{x.location.pathname}' not found</div>}/>
            </Switch>
          </div>
        </ErrorBoundary>
    );
}

const mapStateToProps = state => ({
    user: getUser(state),
    router: state.router,
    userNotifications: state.api.userNotifications.entities,
    isLoadingUserNotifications: state.app.isLoadingUserNotifications,
    hasLoadedUserNotifications: state.app.hasLoadedUserNotifications,
});

const mapDispatchToProps = {
    fetchUserNotificationsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
