/** Room deployments redirect page */
export default () => {
    var redirectUrl;

    switch (window.location.host)
    {
        case 'meetingrooms.booking.com':
            redirectUrl = 'https://mrdeploy.support.booking.com/';
            break;
        case 'its-staging-meetingroomsuite.azurefd.net':
            redirectUrl = 'https://its-staging-meetingroomsuite-dpment-blz.azurewebsites.net/';
            break;
        default:
            redirectUrl = 'https://localhost:44392/'; // your Blazor host
            break;
    }

    window.location.replace(redirectUrl);
    return null;
};
