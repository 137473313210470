import React from 'react';
import * as PropTypes from "prop-types";
import _ from "lodash";
import Info from "../info/Info";
import MaintenanceListItem from './MaintenanceListItem';
import styles from './MaintenanceList.module.css';
import Container from "@bookingcom/bui-react/components/Container";
import {RenderEither} from "../render-either/RenderEither";
import EmptyState from "@bookingcom/bui-react/components/EmptyState";
import Calendar from "@bookingcom/bui-react/icons/Calendar";
import Card from "@bookingcom/bui-react/components/Card";
import {deleteEntityAction} from "../../api/room-maintenance/actions";
import {navigateToAction} from "../../_app/actions";
import {connect} from "react-redux";
import Modal from "@bookingcom/bui-react/components/Modal";
import Text from "@bookingcom/bui-react/components/Text";
import TextWithSpinner from "../text-with-spinner/TextWithSpinner";
import InfoSign from "@bookingcom/bui-react/icons/InfoSign";
import Icon from "@bookingcom/bui-react/components/Icon";

class MaintenanceList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldDisplayCancelMaintenanceModal: false,
            idCancelPrompt: null,
        };
    }

    /**
     * Calculate the state delta using the given props.
     * @param newProps
     * @param prevState
     */
    static getDerivedStateFromProps(newProps, prevState) {
        const delta = {};

        if (!_.isEqual(prevState.deleteResult, newProps.deleteResult)) {
            delta.deleteResult = newProps.deleteResult;

            // Close modal only on transition to `true`
            if (newProps.deleteResult) {
                delta.shouldDisplayCancelMaintenanceModal = false;
                delta.idCancelPrompt = null;
            }
        }

        return delta;
    }

    render = () => <>
        <Text variant="caption" className="bui-spacer"><Icon svg={<InfoSign />} /> List is sorted in the reversed order</Text>

        <RenderEither
            condition={this.props.isLoadingEntities}
            isTrue={() =>
                <Card className="bui-f-depth-1">
                    <TextWithSpinner>Our rodents are busy searching for maintenance windows, hang on a
                        second...</TextWithSpinner>
                </Card>}
            isFalse={() => <RenderEither
                condition={_.isArray(this.props.entities) && this.props.entities.length > 0}
                isTrue={() => this.renderList()}
                isFalse={() =>
                    <Card className="bui-f-depth-1">
                        <EmptyState icon={<Calendar/>} text="No maintenance is planned" />
                    </Card>}
            />}
        />

        {this.renderPrompt()}
    </>;

    renderList() {
        return <div className={styles['component']}>
            <table className="bui-table  bui-f-depth-1">
                <thead className="bui-table__head">
                <tr className="bui-table__row">
                    <th className="bui-table__cell bui-table__cell--head">
                        ID
                    </th>
                    <th className="bui-table__cell bui-table__cell--head">
                        Status
                    </th>
                    <th className="bui-table__cell bui-table__cell--head">
                        <Info title="Room time zone"/> Date range
                    </th>
                    <th className="bui-table__cell bui-table__cell--head">
                        Meeting room
                    </th>
                    <th className="bui-table__cell bui-table__cell--head">
                        Reason
                    </th>
                    <th className="bui-table__cell bui-table__cell--head">
                        Contact
                    </th>
                    <th className="bui-table__cell bui-table__cell--head bui-table__cell--align-end">
                        {/*Buttons*/}
                    </th>
                </tr>
                </thead>
                <tbody className="bui-table__body">
                {_.orderBy(this.props.entities, ['from'], ['desc'])
                    .map(entity =>
                        <MaintenanceListItem
                            key={entity.id}
                            entity={entity}
                            isLoading={_.includes(this.props.entitiesBeingDeletedIds, entity.id)}
                            onCancelClicked={this.onMaintenanceCancelClicked}
                        />)}
                </tbody>
            </table>
            <Container>Number of maintenance windows: {this.props.entities.length}</Container>
        </div>;
    }

    renderPrompt = () => {
        const window = this.getMaintenanceWindow(this.state.idCancelPrompt);
        const roomName = !!window && !!window.meetingRoom
            ? `${window.meetingRoom.code} - ${window.meetingRoom.name}`
            : null;

        return <Modal
            title={`Cancel room maintenance?`}
            // subtitle={modalSubtitle}
            closeAriaLabel="Close modal"
            active={this.state.shouldDisplayCancelMaintenanceModal}
            onClose={this.resetCancelMaintenanceModal}
            buttons={[{
                text: 'Cancel room maintenance',
                variant: 'destructive',
                type: 'submit',
                loading: this.isCancelling(this.state.idCancelPrompt),
                onClick: this.doCancel,
            }]}
        >
            <Text variant="heading" className="bui-spacer--medium">Room: {roomName}</Text>
            <Text>The meeting room will be unblocked in few minutes</Text>
        </Modal>;
    };

    doCancel = () => {
        this.props.deleteEntityAction(this.state.idCancelPrompt);
    };

    onMaintenanceCancelClicked = id => {
        this.setState({
            shouldDisplayCancelMaintenanceModal: true,
            idCancelPrompt: id,
        });
    };

    resetCancelMaintenanceModal = () => {
        this.setState({
            shouldDisplayCancelMaintenanceModal: false,
            idCancelPrompt: null,
            deleteResult: null,
        });
    };

    isCancelling = id => _.includes(this.props.entitiesBeingDeletedIds, id);

    getMaintenanceWindow = id => _.find(this.props.entities, entity => entity.id === id);
}

/**
 * Maps Redux global state to props.
 *
 * https://react-redux.js.org/using-react-redux/connect-mapstate
 *
 * **Note**:
 * Fired before constructor and at any Redux state update.
 * */
const mapReduxStateToProps = state => ({
    // User
    user: state.oidc.user,

    // API data
    entities: state.api.roomMaintenance.entities,
    deleteResult: state.api.roomMaintenance.deleteResult,

    // External page data
    isLoadingEntities: state.pages.roomMaintenanceList.isLoadingEntities,
    entitiesBeingDeletedIds: state.pages.roomMaintenanceList.entitiesBeingDeletedIds,
});

/**
 * Injects action functions' dispatchers to props.
 *
 * https://react-redux.js.org/api/connect#mapdispatchtoprops-object-dispatch-ownprops-object
 *
 * https://redux.js.org/api/store#dispatch
 *
 * https://redux.js.org/glossary#action
 *
 * **Note**:
 *
 * Action function `doWork` could be dispatched in that way `this.props.doWork("paramValue")` instead of `this.props.dispatch(doWork("paramValue"))`.
 * */
const mapDispatchToProps = {
    deleteEntityAction,
    navigateToAction,
};

MaintenanceList.propTypes = {
    entities: PropTypes.array.isRequired,
    hideRoomColumn: PropTypes.bool,

    isLoadingEntities: PropTypes.bool,
    deleteResult: PropTypes.object,

    onCancelClicked: PropTypes.func,
    entitiesBeingDeletedIds: PropTypes.array,
};

MaintenanceList.defaultProps = {
    // isReadOnly: false,
};

/**
 * Connecting React component to the Redux store.
 *
 * https://react-redux.js.org/api/connect#connect
 *
 * **Usage**:
 *
 * `export default connect(mapReduxStateToProps, mapDispatchToProps)(HomePage);`
 * `export default connect(null, mapDispatchToProps)(HomePage);`
 * `export default connect(mapReduxStateToProps)(HomePage);`
 * `export default connect()(HomePage);`
 */
export default connect(mapReduxStateToProps, mapDispatchToProps)(MaintenanceList);
