export const MAINTENANCE_WINDOWS_FETCHING = 'api/room-maintenance/list/FETCHING';
export const MAINTENANCE_WINDOWS_FETCHED = 'api/room-maintenance/list/FETCHED';

export const MAINTENANCE_WINDOW_FETCHING = 'api/room-maintenance/entity/FETCHING';
export const MAINTENANCE_WINDOW_FETCHED = 'api/room-maintenance/entity/FETCHED';

export const MAINTENANCE_WINDOW_SAVING = 'api/room-maintenance/entity/SAVING';
export const MAINTENANCE_WINDOW_SAVED = 'api/room-maintenance/entity/SAVED';

export const MAINTENANCE_WINDOW_CREATING = 'api/room-maintenance/entity/CREATING';
export const MAINTENANCE_WINDOW_CREATED = 'api/room-maintenance/entity/CREATED';
export const MAINTENANCE_WINDOW_CREATE_FAILED = 'api/room-maintenance/entity/CREATE_FAILED';

export const MAINTENANCE_CALCULATION_FETCHING = 'api/room-maintenance/calculation/FETCHING';
export const MAINTENANCE_CALCULATION_FETCHED = 'api/room-maintenance/calculation/FETCHED';

export const MAINTENANCE_WINDOW_DELETING = 'api/room-maintenance/entity/DELETING';
export const MAINTENANCE_WINDOW_DELETED = 'api/room-maintenance/entity/DELETED';


export const fetchEntitiesAction = (roomId = null) => ({
  type: MAINTENANCE_WINDOWS_FETCHING,
  payload: {
    roomId,
  },
});

export const fetchEntityAction = id => ({
  type: MAINTENANCE_WINDOW_FETCHING,
  payload: {
    id,
  },
});

export const fetchEntityResultAction = entity => ({
  type: MAINTENANCE_WINDOW_FETCHED,
  payload: {
    entity,
  },
});

export const fetchEntitiesResultAction = entities => ({
  type: MAINTENANCE_WINDOWS_FETCHED,
  payload: {
    entities,
  },
});

export const createEntityAction = entity => ({
  type: MAINTENANCE_WINDOW_CREATING,
  payload: {
    entity,
  },
});

export const createEntityResultAction = entity => ({
  type: MAINTENANCE_WINDOW_CREATED,
  payload: {
    entity,
  },
});

export const createEntityFailedAction = error => ({
  type: MAINTENANCE_WINDOW_CREATE_FAILED,
  payload: {
    error,
  },
});

export const fetchCalculationAction = entity => ({
  type: MAINTENANCE_CALCULATION_FETCHING,
  payload: {
    entity,
  },
});

export const fetchCalculationResultAction = calculationResult => ({
  type: MAINTENANCE_CALCULATION_FETCHED,
  payload: {
    calculationResult,
  },
});

export const saveEntityAction = entity => ({
  type: MAINTENANCE_WINDOW_SAVING,
  payload: {
    entity,
  },
});

export const saveEntityResultAction = entity => ({
  type: MAINTENANCE_WINDOW_SAVED,
  payload: {
    entity,
  },
});

export const deleteEntityAction = id => ({
  type: MAINTENANCE_WINDOW_DELETING,
  payload: {
    id,
  },
});

export const deleteEntityResultAction = entity => ({
  type: MAINTENANCE_WINDOW_DELETED,
  payload: {
    entity,
  },
});
