import React from 'react';
import {connect} from 'react-redux';
import * as PropTypes from "prop-types";
import Text from "@bookingcom/bui-react/components/Text";
import Grid from "@bookingcom/bui-react/components/Grid";
import GridColumn from "@bookingcom/bui-react/components/Grid/GridColumn";
import {fetchEntitiesAction} from "../../api/room-maintenance/actions";
import {MaintenanceWindowShape} from "../../api/room-maintenance/shapes";
import DocumentTitle from "../../components/document-title/DocumentTitle";
import MaintenanceList from '../../components/room-maintenance-list/MaintenanceList';

class MaintenanceWindowsPage extends React.Component {
    /** Attached to the DOM. */
    componentDidMount() {
        // Dispatch an action to fetch all room maintenance windows as soon the page is mounted.
        this.props.fetchEntitiesAction();
    }

    /**
     * Renders the content based on the local state - `this.state`.
     *
     * Tips:
     *
     * Use `className=` instead of `class=`.
     */
    render = () => <DocumentTitle title="Room maintenance">
        <Grid>
            <GridColumn size="full">
                <Text variant="display_two" className="bui-spacer">Maintenance windows</Text>
                <MaintenanceList entities={this.props.entities} />
            </GridColumn>
        </Grid>
    </DocumentTitle>;
}

/**
 * Maps Redux global state to props.
 *
 * https://react-redux.js.org/using-react-redux/connect-mapstate
 *
 * **Note**:
 * Fired before constructor and at any Redux state update.
 * */
const mapReduxStateToProps = state => ({
    // API data
    entities: state.api.roomMaintenance.entities,
});

/**
 * Injects action functions' dispatchers to props.
 *
 * https://react-redux.js.org/api/connect#mapdispatchtoprops-object-dispatch-ownprops-object
 *
 * https://redux.js.org/api/store#dispatch
 *
 * https://redux.js.org/glossary#action
 *
 * **Note**:
 *
 * Action function `doWork` could be dispatched in that way `this.props.doWork("paramValue")` instead of `this.props.dispatch(doWork("paramValue"))`.
 * */
const mapDispatchToProps = {
    fetchEntitiesAction,
};

MaintenanceWindowsPage.propTypes = {
    // Redux props
    entities: PropTypes.arrayOf(PropTypes.shape(MaintenanceWindowShape)),

    // Actions
    fetchEntitiesAction: PropTypes.func,
};

/**
 * Connecting React component to the Redux store.
 *
 * https://react-redux.js.org/api/connect#connect
 *
 * **Usage**:
 *
 * `export default connect(mapReduxStateToProps, mapDispatchToProps)(HomePage);`
 * `export default connect(null, mapDispatchToProps)(HomePage);`
 * `export default connect(mapReduxStateToProps)(HomePage);`
 * `export default connect()(HomePage);`
 */
export default connect(mapReduxStateToProps, mapDispatchToProps)(MaintenanceWindowsPage);
