import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import ReactAI from 'react-appinsights';
import {Accordion} from "@bookingcom/bui-react";
import {ErrorMessage} from "./ErrorMessage";
import {ApplicationError} from "./ApplicationError";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            unrecoverableError: null,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (!state.unrecoverableError && props.unrecoverableError !== state.unrecoverableError) {
            return {
                unrecoverableError: props.unrecoverableError,
            };
        }

        return state;
    }

    componentDidUpdate(prevProps, prevState, _) {
        if (!!this.state.unrecoverableError && this.state.unrecoverableError !== prevState.unrecoverableError) {
            ReactAI.ai().trackException(this.state.unrecoverableError);
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { unrecoverableError: error };
    }

    getErrorViewModel = error => {
        debugger/*error*/;

        if (error instanceof Error) {
            const errorViewModel = new ApplicationError();
            const detailsItems = [];

            errorViewModel.message = error.message;
            if (!_.isEmpty(error.stack)) {
                detailsItems.push({
                    subtitle: 'Stack',
                    content: error.stack,
                });
            }
            if (!_.isEmpty(error.info)) {
                detailsItems.push({
                    subtitle: 'Info',
                    content: error.info,
                });
            }

            if (!_.isEmpty(error.title)) {
                errorViewModel.title = error.title;
            }

            if (detailsItems.length > 0) {
                errorViewModel.detailsNode = <Accordion items={detailsItems} />
            }

            return errorViewModel;
        }
        else {
            const simpleError = this.buildErrorFromString(error);
            return simpleError;
        }
    };

    buildErrorFromString(error) {
        if (_.includes(error, 'iat is in the future')) {
            error = new ApplicationError('Login error', <>
                <p>OpenID Connect validation failed</p>
                <p><strong>Please check the clock on your computer</strong> and set proper time</p>
            </>);
        } else if (_.includes(error, 'Login error')) {
            error = new ApplicationError('Login error', <>
                <p>OpenID Connect login failed</p>
            </>);
            error.reloadLink = '/';
        }
        return error;
    }

    render() {
        if (!!this.state.unrecoverableError) {
            const errorViewModel = this.getErrorViewModel(this.state.unrecoverableError);

            return <ErrorMessage error={errorViewModel} />;
        }

        return this.props.children;
    }
}

const mapStateToProps = (state, ownProps) => ({
    unrecoverableError: state.app.unrecoverableError,
});

const mapDispatchToProps = {
    // catchingUnhandledErrorAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
