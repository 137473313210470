import {put, takeEvery} from 'redux-saga/effects';
import {
    fetchEntitiesAction as fetchSharingRoomRequestsAction,
    SHARING_REQUEST_CREATED,
} from '../../../api/sharing/actions';

function* refetchSharingRequest(action) {
    const entity = {...action.payload.entity};
    yield put(fetchSharingRoomRequestsAction(entity.roomId));
}


export const sharingViewSagas = [
    takeEvery(SHARING_REQUEST_CREATED, refetchSharingRequest),
];
