import _ from "lodash";

export const getUserInitials = user => {
    if (!_.isEmpty(user) && !_.isEmpty(user.profile) && !_.isEmpty(user.profile.given_name)) {
        return `${user.profile.given_name.substring(0, 1)}${user.profile.family_name.substring(0, 1)}`;
    }

    return 'Me';
};

export const getUserName = user => {
    if (!_.isEmpty(user) && !_.isEmpty(user.profile) && !_.isEmpty(user.profile.name)) {
        return user.profile.name;
    }

    if (!_.isEmpty(user) && !_.isEmpty(user.profile) && !_.isEmpty(user.profile.unique_name)) {
        return user.profile.unique_name;
    }

    return '';
};
