export const NAVIGATE_TO = 'app/NAVIGATE_TO';

export const NAVIGATE_TO_AND_RELOAD = 'app/NAVIGATE_TO_AND_RELOAD';

export const UPDATE_URL = 'app/UPDATE_URL';

export const SIGNIN = 'app/SIGNIN';

export const LOGOUT = 'app/LOGOUT';

export const NAVIGATE_TO_LOCATION = 'app/NAVIGATE_TO_LOCATION';

export const UNRECOVERABLE_ERROR = 'app/UNRECOVERABLE_ERROR';

/**
 * Navigates to the next location.
 * @returns {{payload: {nextLocation: *}, type: string}}
 * @param nextLocation
 */
export const navigateToLocationAction = nextLocation => ({
    type: NAVIGATE_TO_LOCATION,
    payload: {
        nextLocation,
    },
});

/**
 * Navigate to route.
 * @param {string} route Existing route
 * */
export const navigateToAction = route => ({
    type: NAVIGATE_TO,
    payload: {
        route,
    },
});

/**
 * Navigate and reload the app.
 * @param {string} route Existing route
 * */
export const navigateToAndReloadAction = route => ({
    type: NAVIGATE_TO_AND_RELOAD,
    payload: {
        route,
    },
});

/**
 * Update URL in address bar
 * @param {string} url New URL
 * @example
 * updateUrl("/rooms?buildingCode=AMS9")
 * */
export const updateUrlAction = url => ({
    type: UPDATE_URL,
    payload: {
        url,
    },
});

/**
 * Signs user in. Triggers silent sign-on
 * @param nextLocation
 * @return {{payload: {nextLocation: *}, type: string}}
 */
export const signInAction = nextLocation => ({
    type: SIGNIN,
    payload: {
        nextLocation,
    },
});

/**
 * Logs user out. Cleans the state and local storage.
 */
export const logoutAction = () => ({
    type: LOGOUT,
});

export const unrecoverableErrorAction = error => ({
    type: UNRECOVERABLE_ERROR,
    payload: {
        error,
    },
});
