import React from 'react';
import * as PropTypes from "prop-types";
import {compose} from "redux";
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import {Card} from "@bookingcom/bui-react";
import {signInAction} from '../_app/actions';
import TextWithSpinner from "../components/text-with-spinner/TextWithSpinner";
import {LocationShape} from "../_app/appPropShapes";

const CURRENT_PAGE = '';

class LoginPage extends React.Component {
    componentDidMount() {
        this.props.signInAction(this.props.location.state ? this.props.location.state.from : {});
    }

    render = () =>
        <Card className="bui-f-depth-1">
            <TextWithSpinner>Performing automatic Single Sign-on (SSO)...</TextWithSpinner>
            <p>Try to refresh the <a href={CURRENT_PAGE}>page</a> if nothing happens</p>
        </Card>;
}

LoginPage.propTypes = {
    location: PropTypes.shape(LocationShape).isRequired,
};

export default compose(
    withRouter,
    connect(null, { signInAction }),
)(LoginPage);
