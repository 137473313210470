/**
 * @module selectors
 * Selectors to be used with `select` from `redux-saga/effects` to get data from the global Redux state.
 */


/**
 * Gets user from OIDC state
 * @returns {Oidc.User} User or null.
 */
export const getUser = state => state.oidc && state.oidc.user ? state.oidc.user : null;
