import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from "prop-types";
import React from "react";
import Alert from "@bookingcom/bui-react/components/Alert";
import Text from "@bookingcom/bui-react/components/Text";
import {USER_NOTIFICATION_VARIANT, UserNotificationShape} from "../../api/user-notifications/shapes";
import {RenderIf} from "../render-if/RenderIf";
import styles from "./styles.module.css";

export const UserNotification = props => {
    let alertVariant;
    switch (props.entity.variant) {
        case USER_NOTIFICATION_VARIANT.INFO:
            alertVariant = 'info';
            break;
        case USER_NOTIFICATION_VARIANT.WARNING:
        default:
            alertVariant = 'error';
            break;
    }

    const contactEmailText = <RenderIf
        condition={!_.isEmpty(props.entity.contactEmail)}
        render={() => {
            const mailto = `mailto:${props.entity.contactEmail}`;
            return <Text>Contact: <a href={mailto}>{props.entity.contactEmail}</a></Text>;
        }
    }/>;

    const contactWorkplaceText = <RenderIf
        condition={!_.isEmpty(props.entity.contactWorkplaceId) && !_.isEmpty(props.entity.contactWorkplaceName)}
        render={() => {
            const workplaceIdLink = `https://booking.workplace.com/chat/t/${props.entity.contactWorkplaceId}`;
            return <Text>Contact: <a href={workplaceIdLink} target="_blank" rel="noopener noreferrer">{props.entity.contactWorkplaceName}</a></Text>;
        }
    }/>;

    const datesText = <RenderIf
        condition={!_.isEmpty(props.entity.effectiveFrom) && !_.isEmpty(props.entity.effectiveTo)}
        render={() =>
            <Text variant="caption" className={styles['dates']}>Effective:&nbsp;
                {moment(props.entity.effectiveFrom).format('DD MMM YYYY HH:mm UTC')}&nbsp;- {moment(props.entity.effectiveTo).format('DD MMM YYYY HH:mm UTC')}
            </Text>
    }/>;

    const usersText = <RenderIf
        condition={!_.isEmpty(props.entity.showToUsers)}
        render={() =>
            <Text variant="caption" className={styles['users']}>This notification is shown only to specific users</Text>
    }/>;

    const routeText = <RenderIf
        condition={!_.isEmpty(props.entity.route) && props.entity.route !== '*'}
        render={() =>
            <Text variant="caption" className={styles['route']}>Notification is shown for route "{props.entity.route}"</Text>
        }/>;

    return <div className="bui-container bui-container--center">
        <Alert variant={alertVariant} size="large" title={props.entity.title}>
            <Text className="bui-spacer--medium">{props.entity.message}</Text>
            {contactEmailText}
            {contactWorkplaceText}

            {datesText}
            {usersText}
            {routeText}
        </Alert>
    </div>;
};

UserNotification.propTypes = {
    entity: PropTypes.shape(UserNotificationShape),
};
