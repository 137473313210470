import _ from "lodash";
import {put, select, takeLatest} from "redux-saga/effects";
import {SEARCH_CRITERIA_UPDATED} from "./actions";
import {ROOMS_BY_SEARCH_FUNC} from "../../appRoutes";
import {updateUrlAction} from "../../_app/actions";
import {fetchEntitiesAction} from "../../api/rooms/actions";

function* onSearchCriteriaUpdated(action) {
    const buildingCode = action.payload.buildingCode;
    const searchQuery = action.payload.searchQuery;

    yield put(updateUrlAction(ROOMS_BY_SEARCH_FUNC(buildingCode, searchQuery)));

    if (_.isEmpty(searchQuery) && _.isEmpty(buildingCode)) {
        return;
    }

    if (!_.isEmpty(searchQuery) && searchQuery.length < 3) {
        return;
    }

    let buildingId = null;

    if (!_.isEmpty(buildingCode)) {
        const buildings = yield select(state => state.api.buildings.entities);
        const building = buildings.find(b => b.code === buildingCode);
        if (!_.isEmpty(building)) {
            buildingId = building.id;
        }
    }

    // Dispatches an action to fetch rooms according to search criteria.
    yield put(fetchEntitiesAction(buildingId, searchQuery));
}

export const roomsPageSagas = [
    takeLatest(SEARCH_CRITERIA_UPDATED, onSearchCriteriaUpdated),
];
