import _ from "lodash";
import {ROOM_CREATE_PAGE_RESET_STATE} from './actions';
import {ROOM_CREATE_FAILED, ROOM_CREATED, ROOM_CREATING} from '../../api/rooms/actions';
import {ApplicationError} from "../../_app/ApplicationError";

const initialState = {
    isEntityCreating: false,
    isEntityCreated: false,
    entityCreateError: null,
};

export const roomCreatePageReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case ROOM_CREATING: {
            return {
                ...reducerState,
                isEntityCreating: true,
                isEntityCreated: false,
                entityCreateError: null,
                modelErrors: {},
            };
        }
        case ROOM_CREATED: {
            return {
                ...reducerState,
                isEntityCreating: false,
                isEntityCreated: true,
                createdEntity: action.payload.entity,
            };
        }
        case ROOM_CREATE_FAILED: {
            const entityCreateError = !_.isEmpty(action.payload.error)
                ? action.payload.error
                : new ApplicationError("Failed to create the room");
            entityCreateError.reloadLink = null;

            return {
                ...reducerState,
                isEntityCreating: false,
                isEntityCreated: false,
                modelErrors: action.payload.error.modelErrors || {},
                entityCreateError,
            };
        }
        case ROOM_CREATE_PAGE_RESET_STATE: {
            return {...initialState};
        }
        default:
            return reducerState;
    }
};
