import * as PropTypes from 'prop-types';

const PassportPoliciesResponseShape = {
    action: PropTypes.string,
    target: PropTypes.string,
    id: PropTypes.number
};

export const MeetingRoomAccessControlResponseShape = {
    smartAclManagementStatus: PropTypes.string,
    dedicatingStatus: PropTypes.string,
    isEnabled: PropTypes.bool,
    passportPolicies: PropTypes.arrayOf(PropTypes.shape(PassportPoliciesResponseShape))
};
