import * as PropTypes from 'prop-types';

export const ErrorShape = {
    title: PropTypes.string,
    message: PropTypes.node,
    reloadLink: PropTypes.string,
    detailsNode: PropTypes.node,
};

export const LocationShape = {
    state: PropTypes.shape({
        from: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
};

export const UserShape = {
    state: PropTypes.shape({
    }),
};

export const MatchShape = {
    params: PropTypes.shape({
        identifier: PropTypes.string,
        tab: PropTypes.string,
    }),
};
