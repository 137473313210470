import _ from "lodash";
import React from "react";
import * as HttpStatus from "http-status-codes";
import {Accordion} from "@bookingcom/bui-react";
import {ApplicationError} from "./ApplicationError";

export function buildResponseError(response, responseJson) {
    const appError = new ApplicationError();

    if (!response) {
        return appError;
    }

    const isPostConflict = (response.method === 'POST' && response.status === 409);
    if (isPostConflict) {
        appError.reloadLink = null;
    }
    else {
        appError.reloadLink = document.location.href;
    }

    if (response.status > 0) {
        appError.status = response.status;
        appError.title = buildErrorTitle(response, responseJson);
        appError.message = buildErrorMessage(response, responseJson) || 'Unknown error';

        if (response.status === HttpStatus.BAD_REQUEST) {
            debugger/*error*/;
            appError.modelErrors = responseJson;
        } else /*if (response.status === HttpStatus.PRECONDITION_FAILED)*/ {
            appError.detailsNode = !!responseJson
                ? buildErrorDetailsNode(responseJson)
                : null;
        }
    }

    return appError;
}

// function hasOneInnerError(responseJson) {
//     return _.isArray(responseJson.errors) && responseJson.errors.length === 1;
// }
//
// function hasMultipleInnerErrors(responseJson) {
//     return _.isArray(responseJson.errors) && responseJson.errors.length > 1;
// }

function buildErrorTitle(response, responseJson) {
    if (!!response && !!response.status && !!response.status > 0) {
        try {
            return HttpStatus.getStatusText(response.status);
        }
        catch (e) {
            debugger/*error*/;
            console.warn(e);
        }
    }

    debugger/*error*/;
    return 'Unexpected error';
}

function buildErrorMessage(response, responseJson) {
    if (!responseJson || !_.isArray(responseJson.errors) || responseJson.errors.length === 0) return null;

    // if (hasOneInnerError(responseJson)) {
        const innerError = responseJson.errors[0];

        if (!!innerError.title || !!innerError.message) {
            let result = <>
                {!!innerError.title ? <p>{innerError.title}</p> : ''}
                {!!innerError.message ? <p>{innerError.message}</p> : ''}
                {!!innerError.detail ? <p>{innerError.detail}</p> : ''}
            </>;
            return result;
        }
    // }
    // else {
    //     return response.statusText || HttpStatus.getStatusText(response.status);
    // }

    debugger/*error*/;
    return null;
}

function buildErrorDetailsNode(responseJson) {
    if (!!responseJson && _.isArray(responseJson.errors) && responseJson.errors.length > 0) {
        const items = responseJson.errors.map(e => ({
            title: buildErrorDetailsItemTitle(e) || 'Unexpected error',
            subtitle: buildErrorDetailsItemSubtitle(e) || 'Unknown error',
            content: buildErrorDetailsItemContent(e),
        }));

        return <Accordion items={items}/>;
    }

    return null;
}

function buildErrorDetailsItemTitle(error) {
    if (!!error) {
        let result = '';
        if (error.status && Number.isInteger(error.status)) {
            try {
                result = `${HttpStatus.getStatusText(error.status)}: `;
            }
            catch (e)
            {
                debugger/* error */;
                console.error(e);
            }
        }

        if (!!error.title) {
            result += error.title;
        }

        if (!!result) {
            return result;
        }
    }

    return null;
}

function buildErrorDetailsItemSubtitle(error) {
    if (!!error) {
        if (!!error.message) {
            return error.message;
        } else if (!!error.detail) {
            return error.detail;
        }
    }

    return null;
}

function buildErrorDetailsItemContent(error) {
    if (!!error) {
        if (!!error.meta && !!error.meta.stackTrace) {
            return error.meta.stackTrace;
        }
    }

    return null;
}
