import _ from "lodash";
import {buildMeetingRoomMeetingBot} from "./factories";

export const normalizeEntities = (entities, normalizeEntity) => {
    if (!!entities) {
        if (_.isArray(entities)) {
            return entities.map(x => normalizeEntity(x));
        }
        else if (_.isObject(entities) && !_.isEmpty(entities)) {
            for (const k in entities) {
                // noinspection JSUnfilteredForInLoop
                normalizeEntity(entities[k]);
            }
            return entities;
        }
    }

    return null;
};

export const normalizeRoom = room => {
    const result = _.cloneDeep(room);

    result.meetingBot = result.meetingBot || buildMeetingRoomMeetingBot();
    // TODO: Implement "Recurring events limiter". Uncomment
    // entity.recurringEventsLimiter = entity.recurringEventsLimiter || buildMeetingRoomRecurringEventsLimiter();

    result.buildingCode = result.building.code;
    return result;

};

export const normalizeMacAddress = macAddress => {
    if (macAddress.length === 12) {
        let result = '';
        for (let i = 0; i < 12; i++) {
            result += macAddress[i];
            if (i !== 11 && i % 2 === 1) {
                result += ':';
            }
        }
        return result;
    }

    return macAddress;
};
