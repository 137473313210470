import _ from 'lodash';
import {
    ROOM_FETCH_FAILED,
    ROOM_FETCHED,
    ROOM_FETCHING,
    ROOM_SAVE_FAILED,
    ROOM_SAVED,
    ROOM_SAVING,
} from '../../../api/rooms/actions';
import {ApplicationError} from "../../../_app/ApplicationError";
import {ROOM_DETAILS_VIEW_RESET_STATE} from "./actions";

const initialState = {
    room: null,
    isEntityLoading: false,
    entityLoadingError: null,

    isEntitySaving: false,
    isEntitySaved: false,
    entitySaveError: null,
    modelErrors: {},
};

export const roomDetailsViewReducer = (reducerState = initialState, action) => {
    switch (action.type) {
        case ROOM_SAVING: {
            return {
                ...reducerState,
                isEntitySaving: true,
                isEntitySaved: false,
                entitySaveError: null,
                modelErrors: {},
            };
        }
        case ROOM_SAVED: {
            return {
                ...reducerState,
                isEntitySaving: false,
                isEntitySaved: true,
            };
        }
        case ROOM_SAVE_FAILED: {
            const entitySaveError = !_.isEmpty(action.payload.error)
                ? action.payload.error
                : new ApplicationError("Failed to save the room");
            entitySaveError.reloadLink = null;

            return {
                ...reducerState,
                isEntitySaving: false,
                isEntitySaved: false,
                modelErrors: action.payload.error.modelErrors || {},
                entitySaveError,
            };
        }
        case ROOM_FETCHING: {
            return {
                ...reducerState,
                isEntityLoading: true, // Probably an object with roomCode or roomId as keys and status as value
                entityLoadingError: null,
            };
        }
        case ROOM_FETCHED: {
            return {
                ...reducerState,
                isEntityLoading: false,
            };
        }
        case ROOM_FETCH_FAILED: {
            return {
                ...reducerState,
                isEntityLoading: false,
                entityLoadingError: {...action.payload.error},
            };
        }
        case ROOM_DETAILS_VIEW_RESET_STATE: {
            return {...initialState};
        }
        default:
            return reducerState;
    }
};
