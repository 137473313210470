export const SharingStatuses = {
    SHARING_REQUESTED: "SHARING_REQUESTED",
    AWAITING_ADMIN_APPROVAL: "AWAITING_ADMIN_APPROVAL",
    ADMIN_APPROVED: "ADMIN_APPROVED",
    READY_FOR_SYNC: "READY_FOR_SYNC",
    FAILED: "FAILED",
    DELETE_REQUESTED: "DELETE_REQUESTED",
    SYNC_REQUESTED: "SYNC_REQUESTED",
    SYNC_APPROVED: "SYNC_APPROVED",
    SHARED: "SHARED"
}

export const NotificationVariant = {
    SUCCESS: `success`,
    ERROR: `error`,
    INFO: `info`
}

