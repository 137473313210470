import {userNotificationsSagas} from "./user-notifications/sagas";
import {auditLogSagas} from "./audit-log/sagas";
import {buildingsSagas} from './buildings/sagas';
import {roomsSagas} from './rooms/sagas';
import {roomMaintenanceSagas} from "./room-maintenance/sagas";
import {sharingSagas} from "./sharing/sagas";
import {equipmentSagas} from "./equipment/sagas";
import {accessControlSagas} from "./access-control/sagas";

export const apiSagas = [
    ...userNotificationsSagas,
    ...auditLogSagas,
    ...buildingsSagas,
    ...roomsSagas,
    ...roomMaintenanceSagas,
    ...sharingSagas,
    ...equipmentSagas,
    ...accessControlSagas
];
