import * as PropTypes from "prop-types";

export const RenderEither = props =>
    props.condition
        ? props.isTrue()
        : props.isFalse();

RenderEither.propTypes = {
    condition: PropTypes.bool,
    isTrue: PropTypes.func,
    isFalse: PropTypes.func,
};
