export const AUDIT_LOG_FETCHING = 'api/auditLogs/FETCHING';
export const AUDIT_LOG_FETCHED = 'api/auditLogs/FETCHED';

export const fetchAuditLogAction = (resourceEntityType, resourceEntityId) => ({
    type: AUDIT_LOG_FETCHING,
    payload: {
        resourceEntityType,
        resourceEntityId,
    },
});

export const fetchAuditLogResultAction = (resourceEntityType, resourceEntityId, entities) => ({
    type: AUDIT_LOG_FETCHED,
    payload: {
        resourceEntityType,
        resourceEntityId,
        entities,
    },
});
