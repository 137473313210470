import _ from 'lodash';
import moment from "moment";
import "moment-timezone";
import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { Icon } from "@bookingcom/bui-react";
import List from "@bookingcom/bui-react/components/List";
import CheckmarkSelected from "@bookingcom/bui-react/icons/CheckmarkSelected";
import Link from "@bookingcom/bui-react/components/Link";
import Warning from "@bookingcom/bui-react/icons/Warning";
import { RenderIf } from "../../../components/render-if/RenderIf";
import { AuditLogEntryShape } from "../../../api/audit-log/shapes";
import styles from "./RoomChangeLog.module.css";
import ArrowRight from "@bookingcom/bui-react/icons/ArrowRight";
import CloseCircle from "@bookingcom/bui-react/icons/CloseCircle";
import EditNote from "@bookingcom/bui-react/icons/EditNote";
import Info from "../../../components/info/Info";

const officeProfileUrlPrefix = 'https://office.booking.com/staff/user.html?loginname=';

const SHOW_COUNT = 10;

function getOfficeLoginName(userId) {
    if (userId.indexOf('CORPAD\\') === 0) {
        return userId.substr('CORPAD\\'.length);
    }

    return null;
}

function buildUserProfileLink(officeLoginName) {
    return `${officeProfileUrlPrefix}${officeLoginName}`;
}

function renderUserLink(userId) {
    const officeLoginName = getOfficeLoginName(userId);
    if (!_.isEmpty(officeLoginName)) {
        const userProfileLink = buildUserProfileLink(officeLoginName);

        return <Link
            variant="primary"
            text={officeLoginName}
            href={`${userProfileLink}`} />;
    }

    return userId;
}

function formatTimestampInUtc(timestamp) {
    const localMoment = moment(Date.parse(timestamp));
    const formattedLocal = localMoment.format('DD MMM YYYY HH:mm:ss UTC');
    const formattedUtcTimestamp = localMoment
        .utc()
        .format('DD MMM YYYY HH:mm:ss UTC');
    return <>{formattedUtcTimestamp} <Info variant="right" title={`Browser Time Zone: ${formattedLocal}`} /></>;
}

function buildCustomListItem(entry) {
    if (entry.propertyName != null && entry.propertyName.toLowerCase() === 'isEnabled'.toLowerCase()) {
        const timestamp = formatTimestampInUtc(entry.lastUpdatedTimestamp);
        const userLink = renderUserLink(entry.userId);

        let icon, iconProps, action;
        if (entry.newValue.toUpperCase() === 'TRUE') {
            icon = <CheckmarkSelected />;
            iconProps = { color: 'constructive' };
            action = 'enabled';
        } else {
            icon = <Warning />;
            iconProps = { color: 'destructive' };
            action = 'disabled';
        }

        return {
            key: `${timestamp}_${entry.propertyName}`,
            icon,
            iconProps,
            title: <>{userLink} {action} the room</>,
            content: entry.message,
            sideContent: timestamp,
        };
    }
    else if (entry.propertyName != null && entry.propertyName.toLowerCase() === "isDeleted".toLowerCase()) {

        if (entry.newValue.toUpperCase() === 'TRUE') {
            const timestamp = formatTimestampInUtc(entry.lastUpdatedTimestamp);
            const userLink = renderUserLink(entry.userId);

            return {
                key: `${timestamp}_${entry.propertyName}`,
                icon: <CheckmarkSelected />,
                iconProps: { color: 'destructive' },
                title: <>{userLink} deleted the room</>,
                content: entry.message,
                sideContent: timestamp,
            };
        }
        else {
            const timestamp = formatTimestampInUtc(entry.lastUpdatedTimestamp);
            const userLink = renderUserLink(entry.userId);

            return {
                key: `${timestamp}_${entry.propertyName}`,
                icon: <CloseCircle />,
                iconProps: { color: 'constructive' },
                title: <>{userLink} recreated the room</>,
                content: entry.message,
                sideContent: timestamp,
            };
        }
    }

    return null;
}

function buildListItem(entry) {
    if (entry.propertyName == null || (entry.propertyName.toLowerCase() !== 'isEnabled'.toLowerCase() && entry.propertyName.toLowerCase() !== 'isDeleted'.toLowerCase())) {
        const timestamp = formatTimestampInUtc(entry.lastUpdatedTimestamp);
        const userLink = renderUserLink(entry.userId);
        const title = !!entry.message
            ? <>{userLink}: {entry.message}</>
            : <>{userLink} updated entity</>;

        return {
            key: `${timestamp}_${entry.propertyName}`,
            icon: <EditNote />,
            title: title,
            content: buildChangedElement(entry),
            sideContent: timestamp,
        };
    }

    return null;
}

function buildChangedElement(entry) {
    return <div className={styles["__list-item"]}><strong>{entry.propertyName}</strong>:&nbsp;
        <span className={styles["__oldValue"]}>{entry.oldValue}</span>
        &nbsp;<Icon svg={<ArrowRight />} size="medium" className={styles["__arrow"]} />&nbsp;
        <span className={styles["__newValue"]}>{entry.newValue}</span>
    </div>;
}

const RoomChangeLog = props => {
    const changeLog = props.changeLog || [];

    const [showCount, setShowCount] = useState(SHOW_COUNT);
    const [showMoreLink, setShowMoreLink] = useState(true);

    if (changeLog.length === 0) return '';

    // Group by timestamp
    const groupedChangeLog = Object.values(_.groupBy(changeLog, 'lastUpdatedTimestamp'));

    // Show only first chunk for brevity
    const slicedChangeLog = _.slice(groupedChangeLog, 0, showCount);
    if (!!showMoreLink && slicedChangeLog.length === groupedChangeLog.length) {
        // Hide "Show more" link
        setShowMoreLink(false);
    }

    const changeLogEntries = [];

    for (const entries of slicedChangeLog) {
        if (entries.length === 1) {

            var item = buildListItem(entries[0]) ||
                buildCustomListItem(entries[0]);
            if (!!item) {
                changeLogEntries.push(item);
            }
        }
        else {
            // If more entry within the group

            // Build list item based on the first one (or second one because first one could be "IsEnabled" item)
            const listItem = buildListItem(entries[0]) || buildListItem(entries[1]);

            // Shouldn't be the case. But just to be sure
            if (!!listItem) {
                // Build content based on all
                listItem.content = entries
                    // Skipping "IsEnabled"
                    .filter(x => x.propertyName != null && x.propertyName.toLowerCase() !== "isEnabled".toLowerCase())
                    .map(x => buildChangedElement(x));

                changeLogEntries.push(listItem);
            }

            // Building "IsEnabled" items (should be only 1 but we don't care)
            const isEnabledListItem = entries
                .map(x => buildCustomListItem(x))
                // Find the one with value
                .reduce((result, x) => !!result ? result : x);
            if (!!isEnabledListItem) {
                changeLogEntries.push(isEnabledListItem);
            }
        }
    }

    return <RenderIf condition={!_.isEmpty(changeLogEntries)} render={() =>
        <div className={styles["component"]}>
            <List divided={true} items={changeLogEntries} />
            <RenderIf condition={showMoreLink} render={() =>
                <div className={styles['__show-more']}>
                    <Link text="Show more..." onClick={() => {
                        console.log(showCount);
                        console.log(SHOW_COUNT);
                        setShowCount(showCount + SHOW_COUNT);
                    }} />
                </div>}
            />
        </div>}
    />;
};

RoomChangeLog.propTypes = {
    changeLog: PropTypes.arrayOf(PropTypes.shape(AuditLogEntryShape)),
};

export default RoomChangeLog;
