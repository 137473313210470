import _ from 'lodash';
import {ApplicationError} from './_app/ApplicationError';
import {
    RBAC_BASIC_VIEWER,
    RBAC_ROOM_EQUIPMENT,
    RBAC_ROOM_MAINTENANCE,
    RBAC_ROOM_DEPLOYMENTS,
    RBAC_ROOM_PERMISSIONS,
    RBAC_ROOMS,
    RBAC_ROOM_SHARING,
    RBAC_ROOM_SHARING_CANCEL
} from './constants';

export const throwApplicationError = (message, title, link = null) => {
    const error = new ApplicationError(title, message);
    error.reloadLink = link;

    throw error;
};

export const enumToCssClass = value => {
    const strValue = String(value);
    return strValue
        .toLowerCase()
        .replace(/[_ ]/, '-');
};

/**
 * Finds room in array by ID.
 * @param {Array} rooms
 * @param {string} roomId
 */
export const findRoomById = (rooms, roomId) => {
    return rooms && rooms.length > 0
        ? rooms.indexOf(value => value.id === roomId)
        : null;
};

/**
 * Gets user RBAC rights from user
 * @param {Oidc.User} user
 * @returns {string[]}
 */
export const getRbacRights = user => (!!user && (user.profile.role || user.profile.groups)) || [];

export const hasRbacRight = (user, rbacRight) => {
    const rights = getRbacRights(user);
    return _.includes(rights, rbacRight);
};

export const hasViewerRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_BASIC_VIEWER);
};

export const hasRoomsRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOMS);
};

export const hasEquipmentRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOM_EQUIPMENT);
};

export const hasSharingRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOM_SHARING);
};

export const hasSharingCancelRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOM_SHARING_CANCEL);
};

export const hasRoomMaintenanceRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOM_MAINTENANCE);
};

export const hasRoomPermissionsRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOM_PERMISSIONS);
};

export const hasDeploymentsRbacRight = user => {
    const rights = getRbacRights(user);
    return _.includes(rights, RBAC_ROOM_DEPLOYMENTS);
};

/**
 * Pads number with zeroes.
 *
 * @param {number} number Original number
 * @param {number} digits Number of digits
 * @returns {string} String
 *
 * @example
 * padDigits(5, 3) // --> "005"
 * @example
 * padDigits(5, 1) // --> "5"
 * @example
 * padDigits(5, 2) // --> "05"
 */
export const padDigits = (number, digits) => {
    return Array(Math.max(digits - String(number).length + 1, 0)).join('0') + number;
};

/**
 * Compares two values with taking `null` and `undefined` into account.
 *
 * @example
 * isChanged(undefined, undefined) // -> false
 * @example
 * isChanged(null, undefined) // -> false
 * @example
 * isChanged("NEW", "OLD") // -> true
 * @example
 * isChanged("NEW", undefined) // -> true
 */
export const isChanged = (newValue, oldValue) => {
    return (!!newValue || !!oldValue) && newValue !== oldValue;
};

/**
 * Is `identifier` a valid UUID?
 * @param {string} identifier
 * @returns {RegExpMatchArray}
 */
export const isUuid = identifier => {
    return identifier.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
};

/**
 * Normalizes numbers for human sorting.
 * @param {string} strValue String value
 * @returns {string} String with all numbers padded (for human sorting)
 */
export const normalizeNumbers = strValue => {
    // Loop over all numeric values in the string and replace them with a value of a fixed-width with leading zeroes.
    const result = strValue.replace(
        /(\d+)/g,
        ($0, integer) => padDigits(integer, 10)
    );

    return result;
};
