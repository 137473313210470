import React from "react";
import Yoga from "@bookingcom/bui-react/icons/Yoga";
import World from "@bookingcom/bui-react/icons/World";
import Wine from "@bookingcom/bui-react/icons/Wine";
import Windsurfing from "@bookingcom/bui-react/icons/Windsurfing";
import Wind from "@bookingcom/bui-react/icons/Wind";
import Wildlife from "@bookingcom/bui-react/icons/Wildlife";
import Wifi from "@bookingcom/bui-react/icons/Wifi";
import Widgets from "@bookingcom/bui-react/icons/Widgets";
import Whale from "@bookingcom/bui-react/icons/Whale";
import Washer from "@bookingcom/bui-react/icons/Washer";
import Warning from "@bookingcom/bui-react/icons/Warning";
import Wallet from "@bookingcom/bui-react/icons/Wallet";
import Walk from "@bookingcom/bui-react/icons/Walk";
import Waffle from "@bookingcom/bui-react/icons/Waffle";
import Volcano from "@bookingcom/bui-react/icons/Volcano";
import VisualImpairment from "@bookingcom/bui-react/icons/VisualImpairment";
import Videochat from "@bookingcom/bui-react/icons/Videochat";
import VerticalDots from "@bookingcom/bui-react/icons/VerticalDots";
import VendingMachine from "@bookingcom/bui-react/icons/VendingMachine";
import ValetParking from "@bookingcom/bui-react/icons/ValetParking";
import UserAccountOutline from "@bookingcom/bui-react/icons/UserAccountOutline";
import UserAccount from "@bookingcom/bui-react/icons/UserAccount";
import Update from "@bookingcom/bui-react/icons/Update";
import TrophyCup from "@bookingcom/bui-react/icons/TrophyCup";
import TriangleUp from "@bookingcom/bui-react/icons/TriangleUp";
import TriangleRight from "@bookingcom/bui-react/icons/TriangleRight";
import TriangleLeft from "@bookingcom/bui-react/icons/TriangleLeft";
import TriangleDown from "@bookingcom/bui-react/icons/TriangleDown";
import TrendUpRight from "@bookingcom/bui-react/icons/TrendUpRight";
import TrendUpLeft from "@bookingcom/bui-react/icons/TrendUpLeft";
import TrendDownRight from "@bookingcom/bui-react/icons/TrendDownRight";
import TrendDownLeft from "@bookingcom/bui-react/icons/TrendDownLeft";
import Tree from "@bookingcom/bui-react/icons/Tree";
import Trash from "@bookingcom/bui-react/icons/Trash";
import Tram from "@bookingcom/bui-react/icons/Tram";
import Train from "@bookingcom/bui-react/icons/Train";
import Tractor from "@bookingcom/bui-react/icons/Tractor";
import Toiletries from "@bookingcom/bui-react/icons/Toiletries";
import ThumbsUpSquare from "@bookingcom/bui-react/icons/ThumbsUpSquare";
import ThumbsUp from "@bookingcom/bui-react/icons/ThumbsUp";
import ThumbsDown from "@bookingcom/bui-react/icons/ThumbsDown";
import Theater from "@bookingcom/bui-react/icons/Theater";
import Tennis from "@bookingcom/bui-react/icons/Tennis";
import Temple from "@bookingcom/bui-react/icons/Temple";
import TeaMaker from "@bookingcom/bui-react/icons/TeaMaker";
import Taxi from "@bookingcom/bui-react/icons/Taxi";
import Target from "@bookingcom/bui-react/icons/Target";
import Tablet from "@bookingcom/bui-react/icons/Tablet";
import SwitchProperty from "@bookingcom/bui-react/icons/SwitchProperty";
import Sushi from "@bookingcom/bui-react/icons/Sushi";
import Surf from "@bookingcom/bui-react/icons/Surf";
import Sunset from "@bookingcom/bui-react/icons/Sunset";
import Sunglasses from "@bookingcom/bui-react/icons/Sunglasses";
import Sun from "@bookingcom/bui-react/icons/Sun";
import SuitcaseOutline from "@bookingcom/bui-react/icons/SuitcaseOutline";
import Suitcase from "@bookingcom/bui-react/icons/Suitcase";
import Stroller from "@bookingcom/bui-react/icons/Stroller";
import Streetview from "@bookingcom/bui-react/icons/Streetview";
import Storm from "@bookingcom/bui-react/icons/Storm";
import Stop from "@bookingcom/bui-react/icons/Stop";
import StarUp from "@bookingcom/bui-react/icons/StarUp";
import StarOutline from "@bookingcom/bui-react/icons/StarOutline";
import StarDown from "@bookingcom/bui-react/icons/StarDown";
import Star from "@bookingcom/bui-react/icons/Star";
import Stadium from "@bookingcom/bui-react/icons/Stadium";
import SquareRounded from "@bookingcom/bui-react/icons/SquareRounded";
import SquareRating from "@bookingcom/bui-react/icons/SquareRating";
import Square from "@bookingcom/bui-react/icons/Square";
import Spring from "@bookingcom/bui-react/icons/Spring";
import Sports from "@bookingcom/bui-react/icons/Sports";
import SpicyFood from "@bookingcom/bui-react/icons/SpicyFood";
import SpeechBubbleSolid from "@bookingcom/bui-react/icons/SpeechBubbleSolid";
import SpeechBubbleEmail from "@bookingcom/bui-react/icons/SpeechBubbleEmail";
import SpeechBubbleBdot from "@bookingcom/bui-react/icons/SpeechBubbleBdot";
import SpeechBubble from "@bookingcom/bui-react/icons/SpeechBubble";
import Sparkles from "@bookingcom/bui-react/icons/Sparkles";
import Spa from "@bookingcom/bui-react/icons/Spa";
import Soundproof from "@bookingcom/bui-react/icons/Soundproof";
import SortFilters from "@bookingcom/bui-react/icons/SortFilters";
import SortAz from "@bookingcom/bui-react/icons/SortAz";
import Sort from "@bookingcom/bui-react/icons/Sort";
import Solarium from "@bookingcom/bui-react/icons/Solarium";
import SodaCan from "@bookingcom/bui-react/icons/SodaCan";
import Snowflake from "@bookingcom/bui-react/icons/Snowflake";
import Snowboard from "@bookingcom/bui-react/icons/Snowboard";
import Snow from "@bookingcom/bui-react/icons/Snow";
import Smoking from "@bookingcom/bui-react/icons/Smoking";
import Slippers from "@bookingcom/bui-react/icons/Slippers";
import Skilift from "@bookingcom/bui-react/icons/Skilift";
import Skiing from "@bookingcom/bui-react/icons/Skiing";
import Skating from "@bookingcom/bui-react/icons/Skating";
import Skateboard from "@bookingcom/bui-react/icons/Skateboard";
import Singlesday from "@bookingcom/bui-react/icons/Singlesday";
import SingleBed from "@bookingcom/bui-react/icons/SingleBed";
import Signout from "@bookingcom/bui-react/icons/Signout";
import Signin from "@bookingcom/bui-react/icons/Signin";
import Signal from "@bookingcom/bui-react/icons/Signal";
import ShuttleFee from "@bookingcom/bui-react/icons/ShuttleFee";
import Shuttle from "@bookingcom/bui-react/icons/Shuttle";
import Shower from "@bookingcom/bui-react/icons/Shower";
import ShoppingCart from "@bookingcom/bui-react/icons/ShoppingCart";
import ShoppingBag from "@bookingcom/bui-react/icons/ShoppingBag";
import ShoesWomen from "@bookingcom/bui-react/icons/ShoesWomen";
import Shark from "@bookingcom/bui-react/icons/Shark";
import ShareBold from "@bookingcom/bui-react/icons/ShareBold";
import Share2 from "@bookingcom/bui-react/icons/Share2";
import Share from "@bookingcom/bui-react/icons/Share";
import Settings from "@bookingcom/bui-react/icons/Settings";
import SeparatorDotted from "@bookingcom/bui-react/icons/SeparatorDotted";
import Select from "@bookingcom/bui-react/icons/Select";
import Screen from "@bookingcom/bui-react/icons/Screen";
import Scan from "@bookingcom/bui-react/icons/Scan";
import Sauna from "@bookingcom/bui-react/icons/Sauna";
import Salon from "@bookingcom/bui-react/icons/Salon";
import Safe from "@bookingcom/bui-react/icons/Safe";
import Running from "@bookingcom/bui-react/icons/Running";
import Ruler from "@bookingcom/bui-react/icons/Ruler";
import Rugby from "@bookingcom/bui-react/icons/Rugby";
import Route66 from "@bookingcom/bui-react/icons/Route66";
import Route from "@bookingcom/bui-react/icons/Route";
import RoomSize from "@bookingcom/bui-react/icons/RoomSize";
import Rollerblade from "@bookingcom/bui-react/icons/Rollerblade";
import Rocket from "@bookingcom/bui-react/icons/Rocket";
import Roadsign from "@bookingcom/bui-react/icons/Roadsign";
import ReviewTimeline from "@bookingcom/bui-react/icons/ReviewTimeline";
import ReviewTerrible from "@bookingcom/bui-react/icons/ReviewTerrible";
import ReviewPoor from "@bookingcom/bui-react/icons/ReviewPoor";
import ReviewGreat from "@bookingcom/bui-react/icons/ReviewGreat";
import ReviewGood from "@bookingcom/bui-react/icons/ReviewGood";
import ReviewAverage from "@bookingcom/bui-react/icons/ReviewAverage";
import Resort from "@bookingcom/bui-react/icons/Resort";
import ResizeVertical from "@bookingcom/bui-react/icons/ResizeVertical";
import ResizeHorizontal from "@bookingcom/bui-react/icons/ResizeHorizontal";
import Refresh from "@bookingcom/bui-react/icons/Refresh";
import RecentHistory from "@bookingcom/bui-react/icons/RecentHistory";
import Rain from "@bookingcom/bui-react/icons/Rain";
import QuestionMarkCircle from "@bookingcom/bui-react/icons/QuestionMarkCircle";
import QuestionMark from "@bookingcom/bui-react/icons/QuestionMark";
import PrivateBathroom from "@bookingcom/bui-react/icons/PrivateBathroom";
import Printer from "@bookingcom/bui-react/icons/Printer";
import PriceMatch from "@bookingcom/bui-react/icons/PriceMatch";
import PreferredProperty from "@bookingcom/bui-react/icons/PreferredProperty";
import PopoutBold from "@bookingcom/bui-react/icons/PopoutBold";
import Popout from "@bookingcom/bui-react/icons/Popout";
import Pool from "@bookingcom/bui-react/icons/Pool";
import PlusCircle from "@bookingcom/bui-react/icons/PlusCircle";
import PlusBold from "@bookingcom/bui-react/icons/PlusBold";
import Plus from "@bookingcom/bui-react/icons/Plus";
import PlayingCards from "@bookingcom/bui-react/icons/PlayingCards";
import Play from "@bookingcom/bui-react/icons/Play";
import Pizza from "@bookingcom/bui-react/icons/Pizza";
import Pin from "@bookingcom/bui-react/icons/Pin";
import Pillow from "@bookingcom/bui-react/icons/Pillow";
import Pill from "@bookingcom/bui-react/icons/Pill";
import Phone from "@bookingcom/bui-react/icons/Phone";
import PersonHalf from "@bookingcom/bui-react/icons/PersonHalf";
import Person from "@bookingcom/bui-react/icons/Person";
import Percentage from "@bookingcom/bui-react/icons/Percentage";
import Pepper from "@bookingcom/bui-react/icons/Pepper";
import Pawprint from "@bookingcom/bui-react/icons/Pawprint";
import Paste from "@bookingcom/bui-react/icons/Paste";
import PartlyCloudy from "@bookingcom/bui-react/icons/PartlyCloudy";
import ParkingSign from "@bookingcom/bui-react/icons/ParkingSign";
import ParkingPaidSign from "@bookingcom/bui-react/icons/ParkingPaidSign";
import Paraglider from "@bookingcom/bui-react/icons/Paraglider";
import Paperplane from "@bookingcom/bui-react/icons/Paperplane";
import Paperclip from "@bookingcom/bui-react/icons/Paperclip";
import Paintbrush from "@bookingcom/bui-react/icons/Paintbrush";
import PageEmpty from "@bookingcom/bui-react/icons/PageEmpty";
import Page from "@bookingcom/bui-react/icons/Page";
import PackedLunch from "@bookingcom/bui-react/icons/PackedLunch";
import P2gBed from "@bookingcom/bui-react/icons/P2gBed";
import Oven from "@bookingcom/bui-react/icons/Oven";
import Oldtown from "@bookingcom/bui-react/icons/Oldtown";
import Nosmoking from "@bookingcom/bui-react/icons/Nosmoking";
import Northernlights from "@bookingcom/bui-react/icons/Northernlights";
import Nightmarket from "@bookingcom/bui-react/icons/Nightmarket";
import Newspapers from "@bookingcom/bui-react/icons/Newspapers";
import NavarrowUpBold from "@bookingcom/bui-react/icons/NavarrowUpBold";
import NavarrowUp from "@bookingcom/bui-react/icons/NavarrowUp";
import NavarrowRightBold from "@bookingcom/bui-react/icons/NavarrowRightBold";
import NavarrowRight from "@bookingcom/bui-react/icons/NavarrowRight";
import NavarrowLeftBold from "@bookingcom/bui-react/icons/NavarrowLeftBold";
import NavarrowLeft from "@bookingcom/bui-react/icons/NavarrowLeft";
import NavarrowEndRightBold from "@bookingcom/bui-react/icons/NavarrowEndRightBold";
import NavarrowEndRight from "@bookingcom/bui-react/icons/NavarrowEndRight";
import NavarrowEndLeftBold from "@bookingcom/bui-react/icons/NavarrowEndLeftBold";
import NavarrowEndLeft from "@bookingcom/bui-react/icons/NavarrowEndLeft";
import NavarrowDownBold from "@bookingcom/bui-react/icons/NavarrowDownBold";
import NavarrowDown from "@bookingcom/bui-react/icons/NavarrowDown";
import NavarrowBackBold from "@bookingcom/bui-react/icons/NavarrowBackBold";
import NavarrowBack from "@bookingcom/bui-react/icons/NavarrowBack";
import MusicNote from "@bookingcom/bui-react/icons/MusicNote";
import Mountains from "@bookingcom/bui-react/icons/Mountains";
import Mosque from "@bookingcom/bui-react/icons/Mosque";
import MoonStars from "@bookingcom/bui-react/icons/MoonStars";
import MoonCrescent from "@bookingcom/bui-react/icons/MoonCrescent";
import Monument from "@bookingcom/bui-react/icons/Monument";
import MoneyIncoming from "@bookingcom/bui-react/icons/MoneyIncoming";
import MobilePhone from "@bookingcom/bui-react/icons/MobilePhone";
import MinusCircle from "@bookingcom/bui-react/icons/MinusCircle";
import Minus from "@bookingcom/bui-react/icons/Minus";
import MicrowaveOven from "@bookingcom/bui-react/icons/MicrowaveOven";
import Microphone from "@bookingcom/bui-react/icons/Microphone";
import Metro from "@bookingcom/bui-react/icons/Metro";
import Megaphone from "@bookingcom/bui-react/icons/Megaphone";
import Maximize from "@bookingcom/bui-react/icons/Maximize";
import Massage from "@bookingcom/bui-react/icons/Massage";
import ManageBooking from "@bookingcom/bui-react/icons/ManageBooking";
import Makeup from "@bookingcom/bui-react/icons/Makeup";
import MagnifyingGlassBold from "@bookingcom/bui-react/icons/MagnifyingGlassBold";
import MagnifyingGlass from "@bookingcom/bui-react/icons/MagnifyingGlass";
import LuggageStorage from "@bookingcom/bui-react/icons/LuggageStorage";
import LogoZeroFee from "@bookingcom/bui-react/icons/LogoZeroFee";
import LogoWhatsappColor from "@bookingcom/bui-react/icons/LogoWhatsappColor";
import LogoWhatsappBlack from "@bookingcom/bui-react/icons/LogoWhatsappBlack";
import LogoWeibo from "@bookingcom/bui-react/icons/LogoWeibo";
import LogoWechatMoments from "@bookingcom/bui-react/icons/LogoWechatMoments";
import LogoWechatColor from "@bookingcom/bui-react/icons/LogoWechatColor";
import LogoWechatBlack from "@bookingcom/bui-react/icons/LogoWechatBlack";
import LogoWalletMonochrome from "@bookingcom/bui-react/icons/LogoWalletMonochrome";
import LogoValueDeal from "@bookingcom/bui-react/icons/LogoValueDeal";
import LogoTwitter from "@bookingcom/bui-react/icons/LogoTwitter";
import LogoSecretDeal from "@bookingcom/bui-react/icons/LogoSecretDeal";
import LogoRewardsOutline from "@bookingcom/bui-react/icons/LogoRewardsOutline";
import LogoRewardsFill from "@bookingcom/bui-react/icons/LogoRewardsFill";
import LogoRentalcarsSymbol from "@bookingcom/bui-react/icons/LogoRentalcarsSymbol";
import LogoQq from "@bookingcom/bui-react/icons/LogoQq";
import LogoPinterest from "@bookingcom/bui-react/icons/LogoPinterest";
import LogoPaymentYandex from "@bookingcom/bui-react/icons/LogoPaymentYandex";
import LogoPaymentWechatPay from "@bookingcom/bui-react/icons/LogoPaymentWechatPay";
import LogoPaymentWebmoney from "@bookingcom/bui-react/icons/LogoPaymentWebmoney";
import LogoPaymentVisa from "@bookingcom/bui-react/icons/LogoPaymentVisa";
import LogoPaymentUnionPay from "@bookingcom/bui-react/icons/LogoPaymentUnionPay";
import LogoPaymentTenpay from "@bookingcom/bui-react/icons/LogoPaymentTenpay";
import LogoPaymentSofort from "@bookingcom/bui-react/icons/LogoPaymentSofort";
import LogoPaymentQiwi from "@bookingcom/bui-react/icons/LogoPaymentQiwi";
import LogoPaymentPaypalStacked from "@bookingcom/bui-react/icons/LogoPaymentPaypalStacked";
import LogoPaymentPaypalLong from "@bookingcom/bui-react/icons/LogoPaymentPaypalLong";
import LogoPaymentMastercard from "@bookingcom/bui-react/icons/LogoPaymentMastercard";
import LogoPaymentMaestro from "@bookingcom/bui-react/icons/LogoPaymentMaestro";
import LogoPaymentJcb from "@bookingcom/bui-react/icons/LogoPaymentJcb";
import LogoPaymentIdeal from "@bookingcom/bui-react/icons/LogoPaymentIdeal";
import LogoPaymentGiropay from "@bookingcom/bui-react/icons/LogoPaymentGiropay";
import LogoPaymentEps from "@bookingcom/bui-react/icons/LogoPaymentEps";
import LogoPaymentDotpat from "@bookingcom/bui-react/icons/LogoPaymentDotpat";
import LogoPaymentDiscover from "@bookingcom/bui-react/icons/LogoPaymentDiscover";
import LogoPaymentDinersClub from "@bookingcom/bui-react/icons/LogoPaymentDinersClub";
import LogoPaymentBancont from "@bookingcom/bui-react/icons/LogoPaymentBancont";
import LogoPaymentAmericanExpress from "@bookingcom/bui-react/icons/LogoPaymentAmericanExpress";
import LogoPaymentAlipay from "@bookingcom/bui-react/icons/LogoPaymentAlipay";
import LogoMessengerColor from "@bookingcom/bui-react/icons/LogoMessengerColor";
import LogoMessengerBlack from "@bookingcom/bui-react/icons/LogoMessengerBlack";
import LogoLine from "@bookingcom/bui-react/icons/LogoLine";
import LogoLateDeal from "@bookingcom/bui-react/icons/LogoLateDeal";
import LogoGooglePlusColor from "@bookingcom/bui-react/icons/LogoGooglePlusColor";
import LogoGooglePlusBlack from "@bookingcom/bui-react/icons/LogoGooglePlusBlack";
import LogoFacebookF from "@bookingcom/bui-react/icons/LogoFacebookF";
import LogoFacebookBoxReversed from "@bookingcom/bui-react/icons/LogoFacebookBoxReversed";
import LogoFacebookBox from "@bookingcom/bui-react/icons/LogoFacebookBox";
import LogoEarlyDeal from "@bookingcom/bui-react/icons/LogoEarlyDeal";
import LogoDealOfTheDay from "@bookingcom/bui-react/icons/LogoDealOfTheDay";
import LogoDealDay from "@bookingcom/bui-react/icons/LogoDealDay";
import LogoDealBadge from "@bookingcom/bui-react/icons/LogoDealBadge";
import LogoDeal from "@bookingcom/bui-react/icons/LogoDeal";
import LogoCpos from "@bookingcom/bui-react/icons/LogoCpos";
import LogoBookingAssistant2 from "@bookingcom/bui-react/icons/LogoBookingAssistant2";
import LogoBookingAssistant from "@bookingcom/bui-react/icons/LogoBookingAssistant";
import LogoBdot from "@bookingcom/bui-react/icons/LogoBdot";
import LogoBbooking from "@bookingcom/bui-react/icons/LogoBbooking";
import LogoApple from "@bookingcom/bui-react/icons/LogoApple";
import LogoAndroid from "@bookingcom/bui-react/icons/LogoAndroid";
import LockOpen from "@bookingcom/bui-react/icons/LockOpen";
import LockClosed from "@bookingcom/bui-react/icons/LockClosed";
import Location from "@bookingcom/bui-react/icons/Location";
import Localchoice from "@bookingcom/bui-react/icons/Localchoice";
import Loading from "@bookingcom/bui-react/icons/Loading";
import List from "@bookingcom/bui-react/icons/List";
import Lighthouse from "@bookingcom/bui-react/icons/Lighthouse";
import Lightbulb from "@bookingcom/bui-react/icons/Lightbulb";
import Lego from "@bookingcom/bui-react/icons/Lego";
import Leaf from "@bookingcom/bui-react/icons/Leaf";
import Latecheckout from "@bookingcom/bui-react/icons/Latecheckout";
import Landscape from "@bookingcom/bui-react/icons/Landscape";
import Landmark from "@bookingcom/bui-react/icons/Landmark";
import Lan from "@bookingcom/bui-react/icons/Lan";
import Lampion from "@bookingcom/bui-react/icons/Lampion";
import Label from "@bookingcom/bui-react/icons/Label";
import Kitesurfing from "@bookingcom/bui-react/icons/Kitesurfing";
import Keyboard from "@bookingcom/bui-react/icons/Keyboard";
import Key from "@bookingcom/bui-react/icons/Key";
import Kettle from "@bookingcom/bui-react/icons/Kettle";
import Kaabaa from "@bookingcom/bui-react/icons/Kaabaa";
import Journey from "@bookingcom/bui-react/icons/Journey";
import Iron from "@bookingcom/bui-react/icons/Iron";
import Internet from "@bookingcom/bui-react/icons/Internet";
import International from "@bookingcom/bui-react/icons/International";
import Institution from "@bookingcom/bui-react/icons/Institution";
import Instantconf from "@bookingcom/bui-react/icons/Instantconf";
import InfoSign from "@bookingcom/bui-react/icons/InfoSign";
import Inbox from "@bookingcom/bui-react/icons/Inbox";
import Icesculpture from "@bookingcom/bui-react/icons/Icesculpture";
import House from "@bookingcom/bui-react/icons/House";
import Hourglass from "@bookingcom/bui-react/icons/Hourglass";
import Hour from "@bookingcom/bui-react/icons/Hour";
import Hotel from "@bookingcom/bui-react/icons/Hotel";
import Hospital from "@bookingcom/bui-react/icons/Hospital";
import Horse from "@bookingcom/bui-react/icons/Horse";
import HorizontalLines from "@bookingcom/bui-react/icons/HorizontalLines";
import HorizontalDots from "@bookingcom/bui-react/icons/HorizontalDots";
import Hiking from "@bookingcom/bui-react/icons/Hiking";
import Help from "@bookingcom/bui-react/icons/Help";
import Heater from "@bookingcom/bui-react/icons/Heater";
import HeartOutline from "@bookingcom/bui-react/icons/HeartOutline";
import Heart from "@bookingcom/bui-react/icons/Heart";
import Headphones from "@bookingcom/bui-react/icons/Headphones";
import Hat from "@bookingcom/bui-react/icons/Hat";
import HalfStar from "@bookingcom/bui-react/icons/HalfStar";
import Halfcircle from "@bookingcom/bui-react/icons/Halfcircle";
import Hail from "@bookingcom/bui-react/icons/Hail";
import Calendar from "@bookingcom/bui-react/icons/Calendar";
import Group from "@bookingcom/bui-react/icons/Group";
import Graph from "@bookingcom/bui-react/icons/Graph";
import Grapes from "@bookingcom/bui-react/icons/Grapes";
import Grain from "@bookingcom/bui-react/icons/Grain";
import Gourmet from "@bookingcom/bui-react/icons/Gourmet";
import Golf from "@bookingcom/bui-react/icons/Golf";
import Gift from "@bookingcom/bui-react/icons/Gift";
import GeoPinHeart from "@bookingcom/bui-react/icons/GeoPinHeart";
import GeoPin from "@bookingcom/bui-react/icons/GeoPin";
import Gearbox from "@bookingcom/bui-react/icons/Gearbox";
import Garden from "@bookingcom/bui-react/icons/Garden";
import Game from "@bookingcom/bui-react/icons/Game";
import Gallery from "@bookingcom/bui-react/icons/Gallery";
import Funnel from "@bookingcom/bui-react/icons/Funnel";
import FuelPump from "@bookingcom/bui-react/icons/FuelPump";
import Frontdesk from "@bookingcom/bui-react/icons/Frontdesk";
import Fridge from "@bookingcom/bui-react/icons/Fridge";
import Football from "@bookingcom/bui-react/icons/Football";
import FoodAndDrink from "@bookingcom/bui-react/icons/FoodAndDrink";
import Food from "@bookingcom/bui-react/icons/Food";
import Fog from "@bookingcom/bui-react/icons/Fog";
import Flash from "@bookingcom/bui-react/icons/Flash";
import Flag from "@bookingcom/bui-react/icons/Flag";
import Fitness from "@bookingcom/bui-react/icons/Fitness";
import Fish from "@bookingcom/bui-react/icons/Fish";
import Filterfunnel from "@bookingcom/bui-react/icons/Filterfunnel";
import Ferry from "@bookingcom/bui-react/icons/Ferry";
import Favoriteflag from "@bookingcom/bui-react/icons/Favoriteflag";
import Family from "@bookingcom/bui-react/icons/Family";
import EyeCrossedOut from "@bookingcom/bui-react/icons/EyeCrossedOut";
import Eye from "@bookingcom/bui-react/icons/Eye";
import Expand from "@bookingcom/bui-react/icons/Expand";
import ExistingBed from "@bookingcom/bui-react/icons/ExistingBed";
import EmailOut from "@bookingcom/bui-react/icons/EmailOut";
import EmailOpen from "@bookingcom/bui-react/icons/EmailOpen";
import EmailIn from "@bookingcom/bui-react/icons/EmailIn";
import EmailAdd from "@bookingcom/bui-react/icons/EmailAdd";
import Email from "@bookingcom/bui-react/icons/Email";
import Elevator from "@bookingcom/bui-react/icons/Elevator";
import EditNote from "@bookingcom/bui-react/icons/EditNote";
import Edit from "@bookingcom/bui-react/icons/Edit";
import Earlycheckin from "@bookingcom/bui-react/icons/Earlycheckin";
import Dropdown from "@bookingcom/bui-react/icons/Dropdown";
import DownloadImage from "@bookingcom/bui-react/icons/DownloadImage";
import Download from "@bookingcom/bui-react/icons/Download";
import DoubleBed from "@bookingcom/bui-react/icons/DoubleBed";
import DoorOpen from "@bookingcom/bui-react/icons/DoorOpen";
import Dolphin from "@bookingcom/bui-react/icons/Dolphin";
import Dollar from "@bookingcom/bui-react/icons/Dollar";
import Diving from "@bookingcom/bui-react/icons/Diving";
import Distance from "@bookingcom/bui-react/icons/Distance";
import Disney from "@bookingcom/bui-react/icons/Disney";
import Dishwasher from "@bookingcom/bui-react/icons/Dishwasher";
import Disabled from "@bookingcom/bui-react/icons/Disabled";
import Directions from "@bookingcom/bui-react/icons/Directions";
import DiamondUp from "@bookingcom/bui-react/icons/DiamondUp";
import DiamondHalf from "@bookingcom/bui-react/icons/DiamondHalf";
import DiamondDown from "@bookingcom/bui-react/icons/DiamondDown";
import Diamond from "@bookingcom/bui-react/icons/Diamond";
import Desk from "@bookingcom/bui-react/icons/Desk";
import Dashboard from "@bookingcom/bui-react/icons/Dashboard";
import Cut from "@bookingcom/bui-react/icons/Cut";
import Crown from "@bookingcom/bui-react/icons/Crown";
import Crop from "@bookingcom/bui-react/icons/Crop";
import CreditCardFront from "@bookingcom/bui-react/icons/CreditCardFront";
import CreditCardBack from "@bookingcom/bui-react/icons/CreditCardBack";
import CreditCard from "@bookingcom/bui-react/icons/CreditCard";
import CreateAccount2 from "@bookingcom/bui-react/icons/CreateAccount2";
import CreateAccount from "@bookingcom/bui-react/icons/CreateAccount";
import Cow from "@bookingcom/bui-react/icons/Cow";
import Couple from "@bookingcom/bui-react/icons/Couple";
import Couch from "@bookingcom/bui-react/icons/Couch";
import Coral from "@bookingcom/bui-react/icons/Coral";
import Copy from "@bookingcom/bui-react/icons/Copy";
import Confirmation from "@bookingcom/bui-react/icons/Confirmation";
import Concierge from "@bookingcom/bui-react/icons/Concierge";
import Compass from "@bookingcom/bui-react/icons/Compass";
import Comparison from "@bookingcom/bui-react/icons/Comparison";
import Comics from "@bookingcom/bui-react/icons/Comics";
import Collapse from "@bookingcom/bui-react/icons/Collapse";
import Coins from "@bookingcom/bui-react/icons/Coins";
import Coffee from "@bookingcom/bui-react/icons/Coffee";
import Cloudy from "@bookingcom/bui-react/icons/Cloudy";
import CloudImport from "@bookingcom/bui-react/icons/CloudImport";
import Cloud from "@bookingcom/bui-react/icons/Cloud";
import Clothes from "@bookingcom/bui-react/icons/Clothes";
import CloseCircle from "@bookingcom/bui-react/icons/CloseCircle";
import CloseBold from "@bookingcom/bui-react/icons/CloseBold";
import Close from "@bookingcom/bui-react/icons/Close";
import Clock from "@bookingcom/bui-react/icons/Clock";
import Clean from "@bookingcom/bui-react/icons/Clean";
import City from "@bookingcom/bui-react/icons/City";
import Circle from "@bookingcom/bui-react/icons/Circle";
import ChristmasDecoration from "@bookingcom/bui-react/icons/ChristmasDecoration";
import Chocolate from "@bookingcom/bui-react/icons/Chocolate";
import Child from "@bookingcom/bui-react/icons/Child";
import Cheese from "@bookingcom/bui-react/icons/Cheese";
import Checkout from "@bookingcom/bui-react/icons/Checkout";
import CheckmarkUnselected from "@bookingcom/bui-react/icons/CheckmarkUnselected";
import CheckmarkSelected from "@bookingcom/bui-react/icons/CheckmarkSelected";
import CheckmarkDotBold from "@bookingcom/bui-react/icons/CheckmarkDotBold";
import CheckmarkDot from "@bookingcom/bui-react/icons/CheckmarkDot";
import CheckmarkBold from "@bookingcom/bui-react/icons/CheckmarkBold";
import Checkmark from "@bookingcom/bui-react/icons/Checkmark";
import Checkin from "@bookingcom/bui-react/icons/Checkin";
import Checkempty from "@bookingcom/bui-react/icons/Checkempty";
import CheckboxIndeterminate from "@bookingcom/bui-react/icons/CheckboxIndeterminate";
import CheckboxEmpty from "@bookingcom/bui-react/icons/CheckboxEmpty";
import Checkbox from "@bookingcom/bui-react/icons/Checkbox";
import ChatBubbles from "@bookingcom/bui-react/icons/ChatBubbles";
import Chart from "@bookingcom/bui-react/icons/Chart";
import ChangeCurrency from "@bookingcom/bui-react/icons/ChangeCurrency";
import Chain from "@bookingcom/bui-react/icons/Chain";
import Centermap from "@bookingcom/bui-react/icons/Centermap";
import Cathedral from "@bookingcom/bui-react/icons/Cathedral";
import Castle from "@bookingcom/bui-react/icons/Castle";
import CarSide from "@bookingcom/bui-react/icons/CarSide";
import CarnivalMask from "@bookingcom/bui-react/icons/CarnivalMask";
import CarFront from "@bookingcom/bui-react/icons/CarFront";
import Canoe from "@bookingcom/bui-react/icons/Canoe";
import Candle from "@bookingcom/bui-react/icons/Candle";
import Campfire from "@bookingcom/bui-react/icons/Campfire";
import Camera from "@bookingcom/bui-react/icons/Camera";
import CalendarNewyear from "@bookingcom/bui-react/icons/CalendarNewyear";
import CalendarCheckout from "@bookingcom/bui-react/icons/CalendarCheckout";
import CalendarCheckin from "@bookingcom/bui-react/icons/CalendarCheckin";
import Cactus from "@bookingcom/bui-react/icons/Cactus";
import CabinTrolley from "@bookingcom/bui-react/icons/CabinTrolley";
import BusSide from "@bookingcom/bui-react/icons/BusSide";
import BusFront from "@bookingcom/bui-react/icons/BusFront";
import Burger from "@bookingcom/bui-react/icons/Burger";
import BunkBed from "@bookingcom/bui-react/icons/BunkBed";
import Bulbtip from "@bookingcom/bui-react/icons/Bulbtip";
import Brush from "@bookingcom/bui-react/icons/Brush";
import Briefcase from "@bookingcom/bui-react/icons/Briefcase";
import Breakfast from "@bookingcom/bui-react/icons/Breakfast";
import Bowling from "@bookingcom/bui-react/icons/Bowling";
import Bookmark from "@bookingcom/bui-react/icons/Bookmark";
import Book from "@bookingcom/bui-react/icons/Book";
import Boat from "@bookingcom/bui-react/icons/Boat";
import Bird from "@bookingcom/bui-react/icons/Bird";
import Binocular from "@bookingcom/bui-react/icons/Binocular";
import BilliardBall from "@bookingcom/bui-react/icons/BilliardBall";
import BikeFee from "@bookingcom/bui-react/icons/BikeFee";
import Bike from "@bookingcom/bui-react/icons/Bike";
import Bidet from "@bookingcom/bui-react/icons/Bidet";
import BellStriked from "@bookingcom/bui-react/icons/BellStriked";
import BellNormal from "@bookingcom/bui-react/icons/BellNormal";
import Beer from "@bookingcom/bui-react/icons/Beer";
import Bed from "@bookingcom/bui-react/icons/Bed";
import Beachball from "@bookingcom/bui-react/icons/Beachball";
import Beach from "@bookingcom/bui-react/icons/Beach";
import Bcloud from "@bookingcom/bui-react/icons/Bcloud";
import Bbq from "@bookingcom/bui-react/icons/Bbq";
import Bath from "@bookingcom/bui-react/icons/Bath";
import Basketball from "@bookingcom/bui-react/icons/Basketball";
import Basket from "@bookingcom/bui-react/icons/Basket";
import Bar from "@bookingcom/bui-react/icons/Bar";
import BackToTop from "@bookingcom/bui-react/icons/BackToTop";
import Backpack from "@bookingcom/bui-react/icons/Backpack";
import Babycot from "@bookingcom/bui-react/icons/Babycot";
import BabyBottle from "@bookingcom/bui-react/icons/BabyBottle";
import AugmentedReality from "@bookingcom/bui-react/icons/AugmentedReality";
import AuditoryImpairment from "@bookingcom/bui-react/icons/AuditoryImpairment";
import Attractions from "@bookingcom/bui-react/icons/Attractions";
import Atm from "@bookingcom/bui-react/icons/Atm";
import AsianBreakfast from "@bookingcom/bui-react/icons/AsianBreakfast";
import ArrowUpBold from "@bookingcom/bui-react/icons/ArrowUpBold";
import ArrowUp from "@bookingcom/bui-react/icons/ArrowUp";
import ArrowRightBold from "@bookingcom/bui-react/icons/ArrowRightBold";
import ArrowRight from "@bookingcom/bui-react/icons/ArrowRight";
import ArrowLeftBold from "@bookingcom/bui-react/icons/ArrowLeftBold";
import ArrowLeft from "@bookingcom/bui-react/icons/ArrowLeft";
import ArrowDownBold from "@bookingcom/bui-react/icons/ArrowDownBold";
import ArrowDown from "@bookingcom/bui-react/icons/ArrowDown";
import Armchair from "@bookingcom/bui-react/icons/Armchair";
import Arena from "@bookingcom/bui-react/icons/Arena";
import Anchor from "@bookingcom/bui-react/icons/Anchor";
import Alarm from "@bookingcom/bui-react/icons/Alarm";
import AirplaneDepart from "@bookingcom/bui-react/icons/AirplaneDepart";
import AirplaneArrive from "@bookingcom/bui-react/icons/AirplaneArrive";
import Airplane from "@bookingcom/bui-react/icons/Airplane";
import AddProperty from "@bookingcom/bui-react/icons/AddProperty";
import AddListOutline from "@bookingcom/bui-react/icons/AddListOutline";
import AddList from "@bookingcom/bui-react/icons/AddList";
import AddBed from "@bookingcom/bui-react/icons/AddBed";
import Icon from "@bookingcom/bui-react/components/Icon";
import styles from './AllIcons.module.css';

const IconDemo = props =>
    <div className={styles['demo-icon']}>
        <Icon svg={props.svg} size="largest"/>
        <div className={styles['__label']}>{props.name}</div>
    </div>;

const AllIcons = () => {
    return <div className={styles['all-icons']}>
        <IconDemo svg={<AddBed />} name="AddBed"/>
        <IconDemo svg={<AddList />} name="AddList"/>
        <IconDemo svg={<AddListOutline />} name="AddListOutline"/>
        <IconDemo svg={<AddProperty />} name="AddProperty"/>
        <IconDemo svg={<Airplane />} name="Airplane"/>
        <IconDemo svg={<AirplaneArrive />} name="AirplaneArrive"/>
        <IconDemo svg={<AirplaneDepart />} name="AirplaneDepart"/>
        <IconDemo svg={<Alarm />} name="Alarm"/>
        <IconDemo svg={<Anchor />} name="Anchor"/>
        <IconDemo svg={<Arena />} name="Arena"/>
        <IconDemo svg={<Armchair />} name="Armchair"/>
        <IconDemo svg={<ArrowDown />} name="ArrowDown"/>
        <IconDemo svg={<ArrowDownBold />} name="ArrowDownBold"/>
        <IconDemo svg={<ArrowLeft />} name="ArrowLeft"/>
        <IconDemo svg={<ArrowLeftBold />} name="ArrowLeftBold"/>
        <IconDemo svg={<ArrowRight />} name="ArrowRight"/>
        <IconDemo svg={<ArrowRightBold />} name="ArrowRightBold"/>
        <IconDemo svg={<ArrowUp />} name="ArrowUp"/>
        <IconDemo svg={<ArrowUpBold />} name="ArrowUpBold"/>
        <IconDemo svg={<AsianBreakfast />} name="AsianBreakfast"/>
        <IconDemo svg={<Atm />} name="Atm"/>
        <IconDemo svg={<Attractions />} name="Attractions"/>
        <IconDemo svg={<AuditoryImpairment />} name="AuditoryImpairment"/>
        <IconDemo svg={<AugmentedReality />} name="AugmentedReality"/>
        <IconDemo svg={<BabyBottle />} name="BabyBottle"/>
        <IconDemo svg={<Babycot />} name="Babycot"/>
        <IconDemo svg={<Backpack />} name="Backpack"/>
        <IconDemo svg={<BackToTop />} name="BackToTop"/>
        <IconDemo svg={<Bar />} name="Bar"/>
        <IconDemo svg={<Basket />} name="Basket"/>
        <IconDemo svg={<Basketball />} name="Basketball"/>
        <IconDemo svg={<Bath />} name="Bath"/>
        <IconDemo svg={<Bbq />} name="Bbq"/>
        <IconDemo svg={<Bcloud />} name="Bcloud"/>
        <IconDemo svg={<Beach />} name="Beach"/>
        <IconDemo svg={<Beachball />} name="Beachball"/>
        <IconDemo svg={<Bed />} name="Bed"/>
        <IconDemo svg={<Beer />} name="Beer"/>
        <IconDemo svg={<BellNormal />} name="BellNormal"/>
        <IconDemo svg={<BellStriked />} name="BellStriked"/>
        <IconDemo svg={<Bidet />} name="Bidet"/>
        <IconDemo svg={<Bike />} name="Bike"/>
        <IconDemo svg={<BikeFee />} name="BikeFee"/>
        <IconDemo svg={<BilliardBall />} name="BilliardBall"/>
        <IconDemo svg={<Binocular />} name="Binocular"/>
        <IconDemo svg={<Bird />} name="Bird"/>
        <IconDemo svg={<Boat />} name="Boat"/>
        <IconDemo svg={<Book />} name="Book"/>
        <IconDemo svg={<Bookmark />} name="Bookmark"/>
        <IconDemo svg={<Bowling />} name="Bowling"/>
        <IconDemo svg={<Breakfast />} name="Breakfast"/>
        <IconDemo svg={<Briefcase />} name="Briefcase"/>
        <IconDemo svg={<Brush />} name="Brush"/>
        <IconDemo svg={<Bulbtip />} name="Bulbtip"/>
        <IconDemo svg={<BunkBed />} name="BunkBed"/>
        <IconDemo svg={<Burger />} name="Burger"/>
        <IconDemo svg={<BusFront />} name="BusFront"/>
        <IconDemo svg={<BusSide />} name="BusSide"/>
        <IconDemo svg={<CabinTrolley />} name="CabinTrolley"/>
        <IconDemo svg={<Cactus />} name="Cactus"/>
        <IconDemo svg={<Calendar />} name="Calendar"/>
        <IconDemo svg={<CalendarCheckin />} name="CalendarCheckin"/>
        <IconDemo svg={<CalendarCheckout />} name="CalendarCheckout"/>
        <IconDemo svg={<CalendarNewyear />} name="CalendarNewyear"/>
        <IconDemo svg={<Camera />} name="Camera"/>
        <IconDemo svg={<Campfire />} name="Campfire"/>
        <IconDemo svg={<Candle />} name="Candle"/>
        <IconDemo svg={<Canoe />} name="Canoe"/>
        <IconDemo svg={<CarFront />} name="CarFront"/>
        <IconDemo svg={<CarnivalMask />} name="CarnivalMask"/>
        <IconDemo svg={<CarSide />} name="CarSide"/>
        <IconDemo svg={<Castle />} name="Castle"/>
        <IconDemo svg={<Cathedral />} name="Cathedral"/>
        <IconDemo svg={<Centermap />} name="Centermap"/>
        <IconDemo svg={<Chain />} name="Chain"/>
        <IconDemo svg={<ChangeCurrency />} name="ChangeCurrency"/>
        <IconDemo svg={<Chart />} name="Chart"/>
        <IconDemo svg={<ChatBubbles />} name="ChatBubbles"/>
        <IconDemo svg={<Checkbox />} name="Checkbox"/>
        <IconDemo svg={<CheckboxEmpty />} name="CheckboxEmpty"/>
        <IconDemo svg={<CheckboxIndeterminate />} name="CheckboxIndeterminate"/>
        <IconDemo svg={<Checkempty />} name="Checkempty"/>
        <IconDemo svg={<Checkin />} name="Checkin"/>
        <IconDemo svg={<Checkmark />} name="Checkmark"/>
        <IconDemo svg={<CheckmarkBold />} name="CheckmarkBold"/>
        <IconDemo svg={<CheckmarkDot />} name="CheckmarkDot"/>
        <IconDemo svg={<CheckmarkDotBold />} name="CheckmarkDotBold"/>
        <IconDemo svg={<CheckmarkSelected />} name="CheckmarkSelected"/>
        <IconDemo svg={<CheckmarkUnselected />} name="CheckmarkUnselected"/>
        <IconDemo svg={<Checkout />} name="Checkout"/>
        <IconDemo svg={<Cheese />} name="Cheese"/>
        <IconDemo svg={<Child />} name="Child"/>
        <IconDemo svg={<Chocolate />} name="Chocolate"/>
        <IconDemo svg={<ChristmasDecoration />} name="ChristmasDecoration"/>
        <IconDemo svg={<Circle />} name="Circle"/>
        <IconDemo svg={<City />} name="City"/>
        <IconDemo svg={<Clean />} name="Clean"/>
        <IconDemo svg={<Clock />} name="Clock"/>
        <IconDemo svg={<Close />} name="Close"/>
        <IconDemo svg={<CloseBold />} name="CloseBold"/>
        <IconDemo svg={<CloseCircle />} name="CloseCircle"/>
        <IconDemo svg={<Clothes />} name="Clothes"/>
        <IconDemo svg={<Cloud />} name="Cloud"/>
        <IconDemo svg={<CloudImport />} name="CloudImport"/>
        <IconDemo svg={<Cloudy />} name="Cloudy"/>
        <IconDemo svg={<Coffee />} name="Coffee"/>
        <IconDemo svg={<Coins />} name="Coins"/>
        <IconDemo svg={<Collapse />} name="Collapse"/>
        <IconDemo svg={<Comics />} name="Comics"/>
        <IconDemo svg={<Comparison />} name="Comparison"/>
        <IconDemo svg={<Compass />} name="Compass"/>
        <IconDemo svg={<Concierge />} name="Concierge"/>
        <IconDemo svg={<Confirmation />} name="Confirmation"/>
        <IconDemo svg={<Copy />} name="Copy"/>
        <IconDemo svg={<Coral />} name="Coral"/>
        <IconDemo svg={<Couch />} name="Couch"/>
        <IconDemo svg={<Couple />} name="Couple"/>
        <IconDemo svg={<Cow />} name="Cow"/>
        <IconDemo svg={<CreateAccount />} name="CreateAccount"/>
        <IconDemo svg={<CreateAccount2 />} name="CreateAccount2" />
        <IconDemo svg={<CreditCard />} name="CreditCard"/>
        <IconDemo svg={<CreditCardBack />} name="CreditCardBack"/>
        <IconDemo svg={<CreditCardFront />} name="CreditCardFront"/>
        <IconDemo svg={<Crop />} name="Crop"/>
        <IconDemo svg={<Crown />} name="Crown"/>
        <IconDemo svg={<Cut />} name="Cut"/>
        <IconDemo svg={<Dashboard />} name="Dashboard"/>
        <IconDemo svg={<Desk />} name="Desk"/>
        <IconDemo svg={<Diamond />} name="Diamond"/>
        <IconDemo svg={<DiamondDown />} name="DiamondDown"/>
        <IconDemo svg={<DiamondHalf />} name="DiamondHalf"/>
        <IconDemo svg={<DiamondUp />} name="DiamondUp"/>
        <IconDemo svg={<Directions />} name="Directions"/>
        <IconDemo svg={<Disabled />} name="Disabled"/>
        <IconDemo svg={<Dishwasher />} name="Dishwasher"/>
        <IconDemo svg={<Disney />} name="Disney"/>
        <IconDemo svg={<Distance />} name="Distance"/>
        <IconDemo svg={<Diving />} name="Diving"/>
        <IconDemo svg={<Dollar />} name="Dollar"/>
        <IconDemo svg={<Dolphin />} name="Dolphin"/>
        <IconDemo svg={<DoorOpen />} name="DoorOpen"/>
        <IconDemo svg={<DoubleBed />} name="DoubleBed"/>
        <IconDemo svg={<Download />} name="Download"/>
        <IconDemo svg={<DownloadImage />} name="DownloadImage"/>
        <IconDemo svg={<Dropdown />} name="Dropdown"/>
        <IconDemo svg={<Earlycheckin />} name="Earlycheckin"/>
        <IconDemo svg={<Edit />} name="Edit"/>
        <IconDemo svg={<EditNote />} name="EditNote"/>
        <IconDemo svg={<Elevator />} name="Elevator"/>
        <IconDemo svg={<Email />} name="Email"/>
        <IconDemo svg={<EmailAdd />} name="EmailAdd"/>
        <IconDemo svg={<EmailIn />} name="EmailIn"/>
        <IconDemo svg={<EmailOpen />} name="EmailOpen"/>
        <IconDemo svg={<EmailOut />} name="EmailOut"/>
        <IconDemo svg={<ExistingBed />} name="ExistingBed"/>
        <IconDemo svg={<Expand />} name="Expand"/>
        <IconDemo svg={<Eye />} name="Eye"/>
        <IconDemo svg={<EyeCrossedOut />} name="EyeCrossedOut"/>
        <IconDemo svg={<Family />} name="Family"/>
        <IconDemo svg={<Favoriteflag />} name="Favoriteflag"/>
        <IconDemo svg={<Ferry />} name="Ferry"/>
        <IconDemo svg={<Filterfunnel />} name="Filterfunnel"/>
        <IconDemo svg={<Fish />} name="Fish"/>
        <IconDemo svg={<Fitness />} name="Fitness"/>
        <IconDemo svg={<Flag />} name="Flag"/>
        <IconDemo svg={<Flash />} name="Flash"/>
        <IconDemo svg={<Fog />} name="Fog"/>
        <IconDemo svg={<Food />} name="Food"/>
        <IconDemo svg={<FoodAndDrink />} name="FoodAndDrink"/>
        <IconDemo svg={<Football />} name="Football"/>
        <IconDemo svg={<Fridge />} name="Fridge"/>
        <IconDemo svg={<Frontdesk />} name="Frontdesk"/>
        <IconDemo svg={<FuelPump />} name="FuelPump"/>
        <IconDemo svg={<Funnel />} name="Funnel"/>
        <IconDemo svg={<Gallery />} name="Gallery"/>
        <IconDemo svg={<Game />} name="Game"/>
        <IconDemo svg={<Garden />} name="Garden"/>
        <IconDemo svg={<Gearbox />} name="Gearbox"/>
        <IconDemo svg={<GeoPin />} name="GeoPin"/>
        <IconDemo svg={<GeoPinHeart />} name="GeoPinHeart"/>
        <IconDemo svg={<Gift />} name="Gift"/>
        <IconDemo svg={<Golf />} name="Golf"/>
        <IconDemo svg={<Gourmet />} name="Gourmet"/>
        <IconDemo svg={<Grain />} name="Grain"/>
        <IconDemo svg={<Grapes />} name="Grapes"/>
        <IconDemo svg={<Graph />} name="Graph"/>
        <IconDemo svg={<Group />} name="Group"/>
        <IconDemo svg={<Hail />} name="Hail"/>
        <IconDemo svg={<Halfcircle />} name="Halfcircle"/>
        <IconDemo svg={<HalfStar />} name="HalfStar"/>
        <IconDemo svg={<Hat />} name="Hat"/>
        <IconDemo svg={<Headphones />} name="Headphones"/>
        <IconDemo svg={<Heart />} name="Heart"/>
        <IconDemo svg={<HeartOutline />} name="HeartOutline"/>
        <IconDemo svg={<Heater />} name="Heater"/>
        <IconDemo svg={<Help />} name="Help"/>
        <IconDemo svg={<Hiking />} name="Hiking"/>
        <IconDemo svg={<HorizontalDots />} name="HorizontalDots"/>
        <IconDemo svg={<HorizontalLines />} name="HorizontalLines"/>
        <IconDemo svg={<Horse />} name="Horse"/>
        <IconDemo svg={<Hospital />} name="Hospital"/>
        <IconDemo svg={<Hotel />} name="Hotel"/>
        <IconDemo svg={<Hour />} name="Hour"/>
        <IconDemo svg={<Hourglass />} name="Hourglass"/>
        <IconDemo svg={<House />} name="House"/>
        <IconDemo svg={<Icesculpture />} name="Icesculpture"/>
        <IconDemo svg={<Inbox />} name="Inbox"/>
        <IconDemo svg={<InfoSign />} name="InfoSign"/>
        <IconDemo svg={<Instantconf />} name="Instantconf"/>
        <IconDemo svg={<Institution />} name="Institution"/>
        <IconDemo svg={<International />} name="International"/>
        <IconDemo svg={<Internet />} name="Internet"/>
        <IconDemo svg={<Iron />} name="Iron"/>
        <IconDemo svg={<Journey />} name="Journey"/>
        <IconDemo svg={<Kaabaa />} name="Kaabaa"/>
        <IconDemo svg={<Kettle />} name="Kettle"/>
        <IconDemo svg={<Key />} name="Key"/>
        <IconDemo svg={<Keyboard />} name="Keyboard"/>
        <IconDemo svg={<Kitesurfing />} name="Kitesurfing"/>
        <IconDemo svg={<Label />} name="Label"/>
        <IconDemo svg={<Lampion />} name="Lampion"/>
        <IconDemo svg={<Lan />} name="Lan"/>
        <IconDemo svg={<Landmark />} name="Landmark"/>
        <IconDemo svg={<Landscape />} name="Landscape"/>
        <IconDemo svg={<Latecheckout />} name="Latecheckout"/>
        <IconDemo svg={<Leaf />} name="Leaf"/>
        <IconDemo svg={<Lego />} name="Lego"/>
        <IconDemo svg={<Lightbulb />} name="Lightbulb"/>
        <IconDemo svg={<Lighthouse />} name="Lighthouse"/>
        <IconDemo svg={<List />} name="List"/>
        <IconDemo svg={<Loading />} name="Loading"/>
        <IconDemo svg={<Localchoice />} name="Localchoice"/>
        <IconDemo svg={<Location />} name="Location"/>
        <IconDemo svg={<LockClosed />} name="LockClosed"/>
        <IconDemo svg={<LockOpen />} name="LockOpen"/>
        <IconDemo svg={<LogoAndroid />} name="LogoAndroid"/>
        <IconDemo svg={<LogoApple />} name="LogoApple"/>
        <IconDemo svg={<LogoBbooking />} name="LogoBbooking"/>
        <IconDemo svg={<LogoBdot />} name="LogoBdot"/>
        <IconDemo svg={<LogoBookingAssistant />} name="LogoBookingAssistant"/>
        <IconDemo svg={<LogoBookingAssistant2 />} name="LogoBookingAssistant2" />
        <IconDemo svg={<LogoCpos />} name="LogoCpos"/>
        <IconDemo svg={<LogoDeal />} name="LogoDeal"/>
        <IconDemo svg={<LogoDealBadge />} name="LogoDealBadge"/>
        <IconDemo svg={<LogoDealDay />} name="LogoDealDay"/>
        <IconDemo svg={<LogoDealOfTheDay />} name="LogoDealOfTheDay"/>
        <IconDemo svg={<LogoEarlyDeal />} name="LogoEarlyDeal"/>
        <IconDemo svg={<LogoFacebookBox />} name="LogoFacebookBox"/>
        <IconDemo svg={<LogoFacebookBoxReversed />} name="LogoFacebookBoxReversed"/>
        <IconDemo svg={<LogoFacebookF />} name="LogoFacebookF"/>
        <IconDemo svg={<LogoGooglePlusBlack />} name="LogoGooglePlusBlack"/>
        <IconDemo svg={<LogoGooglePlusColor />} name="LogoGooglePlusColor"/>
        <IconDemo svg={<LogoLateDeal />} name="LogoLateDeal"/>
        <IconDemo svg={<LogoLine />} name="LogoLine"/>
        <IconDemo svg={<LogoMessengerBlack />} name="LogoMessengerBlack"/>
        <IconDemo svg={<LogoMessengerColor />} name="LogoMessengerColor"/>
        <IconDemo svg={<LogoPaymentAlipay />} name="LogoPaymentAlipay"/>
        <IconDemo svg={<LogoPaymentAmericanExpress />} name="LogoPaymentAmericanExpress"/>
        <IconDemo svg={<LogoPaymentBancont />} name="LogoPaymentBancont"/>
        <IconDemo svg={<LogoPaymentDinersClub />} name="LogoPaymentDinersClub"/>
        <IconDemo svg={<LogoPaymentDiscover />} name="LogoPaymentDiscover"/>
        <IconDemo svg={<LogoPaymentDotpat />} name="LogoPaymentDotpat"/>
        <IconDemo svg={<LogoPaymentEps />} name="LogoPaymentEps"/>
        <IconDemo svg={<LogoPaymentGiropay />} name="LogoPaymentGiropay"/>
        <IconDemo svg={<LogoPaymentIdeal />} name="LogoPaymentIdeal"/>
        <IconDemo svg={<LogoPaymentJcb />} name="LogoPaymentJcb"/>
        <IconDemo svg={<LogoPaymentMaestro />} name="LogoPaymentMaestro"/>
        <IconDemo svg={<LogoPaymentMastercard />} name="LogoPaymentMastercard"/>
        <IconDemo svg={<LogoPaymentPaypalLong />} name="LogoPaymentPaypalLong"/>
        <IconDemo svg={<LogoPaymentPaypalStacked />} name="LogoPaymentPaypalStacked"/>
        <IconDemo svg={<LogoPaymentQiwi />} name="LogoPaymentQiwi"/>
        <IconDemo svg={<LogoPaymentSofort />} name="LogoPaymentSofort"/>
        <IconDemo svg={<LogoPaymentTenpay />} name="LogoPaymentTenpay"/>
        <IconDemo svg={<LogoPaymentUnionPay />} name="LogoPaymentUnionPay"/>
        <IconDemo svg={<LogoPaymentVisa />} name="LogoPaymentVisa"/>
        <IconDemo svg={<LogoPaymentWebmoney />} name="LogoPaymentWebmoney"/>
        <IconDemo svg={<LogoPaymentWechatPay />} name="LogoPaymentWechatPay"/>
        <IconDemo svg={<LogoPaymentYandex />} name="LogoPaymentYandex"/>
        <IconDemo svg={<LogoPinterest />} name="LogoPinterest"/>
        <IconDemo svg={<LogoQq />} name="LogoQq"/>
        <IconDemo svg={<LogoRentalcarsSymbol />} name="LogoRentalcarsSymbol"/>
        <IconDemo svg={<LogoRewardsFill />} name="LogoRewardsFill"/>
        <IconDemo svg={<LogoRewardsOutline />} name="LogoRewardsOutline"/>
        <IconDemo svg={<LogoSecretDeal />} name="LogoSecretDeal"/>
        <IconDemo svg={<LogoTwitter />} name="LogoTwitter"/>
        <IconDemo svg={<LogoValueDeal />} name="LogoValueDeal"/>
        <IconDemo svg={<LogoWalletMonochrome />} name="LogoWalletMonochrome"/>
        <IconDemo svg={<LogoWechatBlack />} name="LogoWechatBlack"/>
        <IconDemo svg={<LogoWechatColor />} name="LogoWechatColor"/>
        <IconDemo svg={<LogoWechatMoments />} name="LogoWechatMoments"/>
        <IconDemo svg={<LogoWeibo />} name="LogoWeibo"/>
        <IconDemo svg={<LogoWhatsappBlack />} name="LogoWhatsappBlack"/>
        <IconDemo svg={<LogoWhatsappColor />} name="LogoWhatsappColor"/>
        <IconDemo svg={<LogoZeroFee />} name="LogoZeroFee"/>
        <IconDemo svg={<LuggageStorage />} name="LuggageStorage"/>
        <IconDemo svg={<MagnifyingGlass />} name="MagnifyingGlass"/>
        <IconDemo svg={<MagnifyingGlassBold />} name="MagnifyingGlassBold"/>
        <IconDemo svg={<Makeup />} name="Makeup"/>
        <IconDemo svg={<ManageBooking />} name="ManageBooking"/>
        <IconDemo svg={<Massage />} name="Massage"/>
        <IconDemo svg={<Maximize />} name="Maximize"/>
        <IconDemo svg={<Megaphone />} name="Megaphone"/>
        <IconDemo svg={<Metro />} name="Metro"/>
        <IconDemo svg={<Microphone />} name="Microphone"/>
        <IconDemo svg={<MicrowaveOven />} name="MicrowaveOven"/>
        <IconDemo svg={<Minus />} name="Minus"/>
        <IconDemo svg={<MinusCircle />} name="MinusCircle"/>
        <IconDemo svg={<MobilePhone />} name="MobilePhone"/>
        <IconDemo svg={<MoneyIncoming />} name="MoneyIncoming"/>
        <IconDemo svg={<Monument />} name="Monument"/>
        <IconDemo svg={<MoonCrescent />} name="MoonCrescent"/>
        <IconDemo svg={<MoonStars />} name="MoonStars"/>
        <IconDemo svg={<Mosque />} name="Mosque"/>
        <IconDemo svg={<Mountains />} name="Mountains"/>
        <IconDemo svg={<MusicNote />} name="MusicNote"/>
        <IconDemo svg={<NavarrowBack />} name="NavarrowBack"/>
        <IconDemo svg={<NavarrowBackBold />} name="NavarrowBackBold"/>
        <IconDemo svg={<NavarrowDown />} name="NavarrowDown"/>
        <IconDemo svg={<NavarrowDownBold />} name="NavarrowDownBold"/>
        <IconDemo svg={<NavarrowEndLeft />} name="NavarrowEndLeft"/>
        <IconDemo svg={<NavarrowEndLeftBold />} name="NavarrowEndLeftBold"/>
        <IconDemo svg={<NavarrowEndRight />} name="NavarrowEndRight"/>
        <IconDemo svg={<NavarrowEndRightBold />} name="NavarrowEndRightBold"/>
        <IconDemo svg={<NavarrowLeft />} name="NavarrowLeft"/>
        <IconDemo svg={<NavarrowLeftBold />} name="NavarrowLeftBold"/>
        <IconDemo svg={<NavarrowRight />} name="NavarrowRight"/>
        <IconDemo svg={<NavarrowRightBold />} name="NavarrowRightBold"/>
        <IconDemo svg={<NavarrowUp />} name="NavarrowUp"/>
        <IconDemo svg={<NavarrowUpBold />} name="NavarrowUpBold"/>
        <IconDemo svg={<Newspapers />} name="Newspapers"/>
        <IconDemo svg={<Nightmarket />} name="Nightmarket"/>
        <IconDemo svg={<Northernlights />} name="Northernlights"/>
        <IconDemo svg={<Nosmoking />} name="Nosmoking"/>
        <IconDemo svg={<Oldtown />} name="Oldtown"/>
        <IconDemo svg={<Oven />} name="Oven"/>
        <IconDemo svg={<P2gBed />} name="P2gBed" />
        <IconDemo svg={<PackedLunch />} name="PackedLunch"/>
        <IconDemo svg={<Page />} name="Page"/>
        <IconDemo svg={<PageEmpty />} name="PageEmpty"/>
        <IconDemo svg={<Paintbrush />} name="Paintbrush"/>
        <IconDemo svg={<Paperclip />} name="Paperclip"/>
        <IconDemo svg={<Paperplane />} name="Paperplane"/>
        <IconDemo svg={<Paraglider />} name="Paraglider"/>
        <IconDemo svg={<ParkingPaidSign />} name="ParkingPaidSign"/>
        <IconDemo svg={<ParkingSign />} name="ParkingSign"/>
        <IconDemo svg={<PartlyCloudy />} name="PartlyCloudy"/>
        <IconDemo svg={<Paste />} name="Paste"/>
        <IconDemo svg={<Pawprint />} name="Pawprint"/>
        <IconDemo svg={<Pepper />} name="Pepper"/>
        <IconDemo svg={<Percentage />} name="Percentage"/>
        <IconDemo svg={<Person />} name="Person"/>
        <IconDemo svg={<PersonHalf />} name="PersonHalf"/>
        <IconDemo svg={<Phone />} name="Phone"/>
        <IconDemo svg={<Pill />} name="Pill"/>
        <IconDemo svg={<Pillow />} name="Pillow"/>
        <IconDemo svg={<Pin />} name="Pin"/>
        <IconDemo svg={<Pizza />} name="Pizza"/>
        <IconDemo svg={<Play />} name="Play"/>
        <IconDemo svg={<PlayingCards />} name="PlayingCards"/>
        <IconDemo svg={<Plus />} name="Plus"/>
        <IconDemo svg={<PlusBold />} name="PlusBold"/>
        <IconDemo svg={<PlusCircle />} name="PlusCircle"/>
        <IconDemo svg={<Pool />} name="Pool"/>
        <IconDemo svg={<Popout />} name="Popout"/>
        <IconDemo svg={<PopoutBold />} name="PopoutBold"/>
        <IconDemo svg={<PreferredProperty />} name="PreferredProperty"/>
        <IconDemo svg={<PriceMatch />} name="PriceMatch"/>
        <IconDemo svg={<Printer />} name="Printer"/>
        <IconDemo svg={<PrivateBathroom />} name="PrivateBathroom"/>
        <IconDemo svg={<QuestionMark />} name="QuestionMark"/>
        <IconDemo svg={<QuestionMarkCircle />} name="QuestionMarkCircle"/>
        <IconDemo svg={<Rain />} name="Rain"/>
        <IconDemo svg={<RecentHistory />} name="RecentHistory"/>
        <IconDemo svg={<Refresh />} name="Refresh"/>
        <IconDemo svg={<ResizeHorizontal />} name="ResizeHorizontal"/>
        <IconDemo svg={<ResizeVertical />} name="ResizeVertical"/>
        <IconDemo svg={<Resort />} name="Resort"/>
        <IconDemo svg={<ReviewAverage />} name="ReviewAverage"/>
        <IconDemo svg={<ReviewGood />} name="ReviewGood"/>
        <IconDemo svg={<ReviewGreat />} name="ReviewGreat"/>
        <IconDemo svg={<ReviewPoor />} name="ReviewPoor"/>
        <IconDemo svg={<ReviewTerrible />} name="ReviewTerrible"/>
        <IconDemo svg={<ReviewTimeline />} name="ReviewTimeline"/>
        <IconDemo svg={<Roadsign />} name="Roadsign"/>
        <IconDemo svg={<Rocket />} name="Rocket"/>
        <IconDemo svg={<Rollerblade />} name="Rollerblade"/>
        <IconDemo svg={<RoomSize />} name="RoomSize"/>
        <IconDemo svg={<Route />} name="Route"/>
        <IconDemo svg={<Route66 />} name="Route66" />
        <IconDemo svg={<Rugby />} name="Rugby"/>
        <IconDemo svg={<Ruler />} name="Ruler"/>
        <IconDemo svg={<Running />} name="Running"/>
        <IconDemo svg={<Safe />} name="Safe"/>
        <IconDemo svg={<Salon />} name="Salon"/>
        <IconDemo svg={<Sauna />} name="Sauna"/>
        <IconDemo svg={<Scan />} name="Scan"/>
        <IconDemo svg={<Screen />} name="Screen"/>
        <IconDemo svg={<Select />} name="Select"/>
        <IconDemo svg={<SeparatorDotted />} name="SeparatorDotted"/>
        <IconDemo svg={<Settings />} name="Settings"/>
        <IconDemo svg={<Share />} name="Share"/>
        <IconDemo svg={<Share2 />} name="Share2" />
        <IconDemo svg={<ShareBold />} name="ShareBold"/>
        <IconDemo svg={<Shark />} name="Shark"/>
        <IconDemo svg={<ShoesWomen />} name="ShoesWomen"/>
        <IconDemo svg={<ShoppingBag />} name="ShoppingBag"/>
        <IconDemo svg={<ShoppingCart />} name="ShoppingCart"/>
        <IconDemo svg={<Shower />} name="Shower"/>
        <IconDemo svg={<Shuttle />} name="Shuttle"/>
        <IconDemo svg={<ShuttleFee />} name="ShuttleFee"/>
        <IconDemo svg={<Signal />} name="Signal"/>
        <IconDemo svg={<Signin />} name="Signin"/>
        <IconDemo svg={<Signout />} name="Signout"/>
        <IconDemo svg={<SingleBed />} name="SingleBed"/>
        <IconDemo svg={<Singlesday />} name="Singlesday"/>
        <IconDemo svg={<Skateboard />} name="Skateboard"/>
        <IconDemo svg={<Skating />} name="Skating"/>
        <IconDemo svg={<Skiing />} name="Skiing"/>
        <IconDemo svg={<Skilift />} name="Skilift"/>
        <IconDemo svg={<Slippers />} name="Slippers"/>
        <IconDemo svg={<Smoking />} name="Smoking"/>
        <IconDemo svg={<Snow />} name="Snow"/>
        <IconDemo svg={<Snowboard />} name="Snowboard"/>
        <IconDemo svg={<Snowflake />} name="Snowflake"/>
        <IconDemo svg={<SodaCan />} name="SodaCan"/>
        <IconDemo svg={<Solarium />} name="Solarium"/>
        <IconDemo svg={<Sort />} name="Sort"/>
        <IconDemo svg={<SortAz />} name="SortAz"/>
        <IconDemo svg={<SortFilters />} name="SortFilters"/>
        <IconDemo svg={<Soundproof />} name="Soundproof"/>
        <IconDemo svg={<Spa />} name="Spa"/>
        <IconDemo svg={<Sparkles />} name="Sparkles"/>
        <IconDemo svg={<SpeechBubble />} name="SpeechBubble"/>
        <IconDemo svg={<SpeechBubbleBdot />} name="SpeechBubbleBdot"/>
        <IconDemo svg={<SpeechBubbleEmail />} name="SpeechBubbleEmail"/>
        <IconDemo svg={<SpeechBubbleSolid />} name="SpeechBubbleSolid"/>
        <IconDemo svg={<SpicyFood />} name="SpicyFood"/>
        <IconDemo svg={<Sports />} name="Sports"/>
        <IconDemo svg={<Spring />} name="Spring"/>
        <IconDemo svg={<Square />} name="Square"/>
        <IconDemo svg={<SquareRating />} name="SquareRating"/>
        <IconDemo svg={<SquareRounded />} name="SquareRounded"/>
        <IconDemo svg={<Stadium />} name="Stadium"/>
        <IconDemo svg={<Star />} name="Star"/>
        <IconDemo svg={<StarDown />} name="StarDown"/>
        <IconDemo svg={<StarOutline />} name="StarOutline"/>
        <IconDemo svg={<StarUp />} name="StarUp"/>
        <IconDemo svg={<Stop />} name="Stop"/>
        <IconDemo svg={<Storm />} name="Storm"/>
        <IconDemo svg={<Streetview />} name="Streetview"/>
        <IconDemo svg={<Stroller />} name="Stroller"/>
        <IconDemo svg={<Suitcase />} name="Suitcase"/>
        <IconDemo svg={<SuitcaseOutline />} name="SuitcaseOutline"/>
        <IconDemo svg={<Sun />} name="Sun"/>
        <IconDemo svg={<Sunglasses />} name="Sunglasses"/>
        <IconDemo svg={<Sunset />} name="Sunset"/>
        <IconDemo svg={<Surf />} name="Surf"/>
        <IconDemo svg={<Sushi />} name="Sushi"/>
        <IconDemo svg={<SwitchProperty />} name="SwitchProperty"/>
        <IconDemo svg={<Tablet />} name="Tablet"/>
        <IconDemo svg={<Target />} name="Target"/>
        <IconDemo svg={<Taxi />} name="Taxi"/>
        <IconDemo svg={<TeaMaker />} name="TeaMaker"/>
        <IconDemo svg={<Temple />} name="Temple"/>
        <IconDemo svg={<Tennis />} name="Tennis"/>
        <IconDemo svg={<Theater />} name="Theater"/>
        <IconDemo svg={<ThumbsDown />} name="ThumbsDown"/>
        <IconDemo svg={<ThumbsUp />} name="ThumbsUp"/>
        <IconDemo svg={<ThumbsUpSquare />} name="ThumbsUpSquare"/>
        <IconDemo svg={<Toiletries />} name="Toiletries"/>
        <IconDemo svg={<Tractor />} name="Tractor"/>
        <IconDemo svg={<Train />} name="Train"/>
        <IconDemo svg={<Tram />} name="Tram"/>
        <IconDemo svg={<Trash />} name="Trash"/>
        <IconDemo svg={<Tree />} name="Tree"/>
        <IconDemo svg={<TrendDownLeft />} name="TrendDownLeft"/>
        <IconDemo svg={<TrendDownRight />} name="TrendDownRight"/>
        <IconDemo svg={<TrendUpLeft />} name="TrendUpLeft"/>
        <IconDemo svg={<TrendUpRight />} name="TrendUpRight"/>
        <IconDemo svg={<TriangleDown />} name="TriangleDown"/>
        <IconDemo svg={<TriangleLeft />} name="TriangleLeft"/>
        <IconDemo svg={<TriangleRight />} name="TriangleRight"/>
        <IconDemo svg={<TriangleUp />} name="TriangleUp"/>
        <IconDemo svg={<TrophyCup />} name="TrophyCup"/>
        <IconDemo svg={<Update />} name="Update"/>
        <IconDemo svg={<UserAccount />} name="UserAccount"/>
        <IconDemo svg={<UserAccountOutline />} name="UserAccountOutline"/>
        <IconDemo svg={<ValetParking />} name="ValetParking"/>
        <IconDemo svg={<VendingMachine />} name="VendingMachine"/>
        <IconDemo svg={<VerticalDots />} name="VerticalDots"/>
        <IconDemo svg={<Videochat />} name="Videochat"/>
        <IconDemo svg={<VisualImpairment />} name="VisualImpairment"/>
        <IconDemo svg={<Volcano />} name="Volcano"/>
        <IconDemo svg={<Waffle />} name="Waffle"/>
        <IconDemo svg={<Walk />} name="Walk"/>
        <IconDemo svg={<Wallet />} name="Wallet"/>
        <IconDemo svg={<Warning />} name="Warning"/>
        <IconDemo svg={<Washer />} name="Washer"/>
        <IconDemo svg={<Whale />} name="Whale"/>
        <IconDemo svg={<Widgets />} name="Widgets"/>
        <IconDemo svg={<Wifi />} name="Wifi"/>
        <IconDemo svg={<Wildlife />} name="Wildlife"/>
        <IconDemo svg={<Wind />} name="Wind"/>
        <IconDemo svg={<Windsurfing />} name="Windsurfing"/>
        <IconDemo svg={<Wine />} name="Wine"/>
        <IconDemo svg={<World />} name="World"/>
        <IconDemo svg={<Yoga />} name="Yoga"/>
    </div>;
};

export default AllIcons;
