import _ from 'lodash';
import {takeEvery} from 'redux-saga/effects';
import {isUuid} from "../../helpers";
import { API } from '../apiRoutes';
import {httpGet, httpJsonApiPatch, httpJsonApiPost, processJsonApiResponse} from '../helpers';
import {
    ROOMS_FETCHING,
    ROOM_CREATING,
    ROOM_FETCHING,
    ROOM_SAVING,
    fetchEntitiesResultAction,
    fetchEntitiesFailedAction,
    createEntityFailedAction,
    createEntityResultAction,
    fetchEntityResultAction,
    fetchEntityFailedAction,
    saveEntityResultAction,
    saveEntityFailedAction,
} from './actions';

function* fetchEntities(action) {
    const {buildingId, searchQuery} = action.payload;

    // Search for rooms based on building ID and search query
    const response = _.isEmpty(buildingId) ? yield* httpGet(API.rooms.search(searchQuery || '')) : yield* httpGet(API.rooms.searchWithBuildingId(buildingId, searchQuery || ''));
    
    yield* processJsonApiResponse(response, fetchEntitiesResultAction, fetchEntitiesFailedAction);
}

function* fetchEntity(action) {
    const {identifier} = action.payload;

    let url;
    if (isUuid(identifier)) {
        url = API.rooms.byId(identifier);
    }
    else {
        url = API.rooms.byCode(identifier);
    }

    const response = yield* httpGet(url);
    yield* processJsonApiResponse(response, fetchEntityResultAction, fetchEntityFailedAction);
}

function* createEntity(action) {
    const response = yield* httpJsonApiPost(API.rooms.endpoint, action.payload.entity);
    yield* processJsonApiResponse(response, createEntityResultAction, createEntityFailedAction);
}

function* saveEntity(action) {
    const response = yield* httpJsonApiPatch(API.rooms.byId(action.payload.entity.id), action.payload.entity);
    yield* processJsonApiResponse(response, saveEntityResultAction, saveEntityFailedAction);
}

export const roomsSagas = [
    takeEvery(ROOMS_FETCHING, fetchEntities),
    takeEvery(ROOM_FETCHING, fetchEntity),
    takeEvery(ROOM_SAVING, saveEntity),
    takeEvery(ROOM_CREATING, createEntity),
];
