import _ from "lodash";
import React from "react";
import * as PropTypes from "prop-types";
import moment from "moment";
import Text from "@bookingcom/bui-react/components/Text";
import EmptyState from "@bookingcom/bui-react/components/EmptyState";
import Hourglass from "@bookingcom/bui-react/icons/Hourglass";
import Grid from "@bookingcom/bui-react/components/Grid";
import GridColumn from "@bookingcom/bui-react/components/Grid/GridColumn";
import Divider from "@bookingcom/bui-react/components/Divider";
import InputText from "@bookingcom/bui-react/components/InputText";
import CheckmarkSelected from "@bookingcom/bui-react/icons/CheckmarkSelected";
import Icon from "@bookingcom/bui-react/components/Icon";
import CloseCircle from "@bookingcom/bui-react/icons/CloseCircle";
import List from "@bookingcom/bui-react/components/List";
import {MeetingRoomGoogleCalendarShape} from "../../../api/rooms/shapes";
import {RenderIf} from "../../../components/render-if/RenderIf";
import Info from "../../../components/info/Info";
import CopyToClipboardText from "../../../components/copy-to-clipboard-text/CopyToClipboardText";
import {RenderEither} from "../../../components/render-either/RenderEither";
import Calendar from "@bookingcom/bui-react/icons/Calendar";

export const GoogleCalendarDetails = props => {
    const googleCalendarLink = buildCalendarLink(props.googleCalendar);

    const renderGrid = () =>
        <Grid className="bui-spacer">
            <GridColumn size="half">
                <Text variant="emphasized" className="bui-spacer--medium">
                    Sync status{/* No space */}<Info variant="right"
                                                   title={`Page loaded at: ${moment().utc().format()}`}/>
                </Text>
                <InputText
                    disabled={true}
                    name="timestamp"
                    label="Last updated"
                    value={props.googleCalendar.syncStatus.timestamp}/>
                <br/>
                <RenderEither condition={props.googleCalendar.syncStatus.status === "Successful"}
                  isTrue={() =>
                    <>
                        <Icon svg={<CheckmarkSelected/>} size="medium" color="constructive"/>&nbsp;
                        <span>{props.googleCalendar.syncStatus.message}</span>
                    </>}
                  isFalse={() =>
                    <>
                      <Icon svg={<CloseCircle/>} size="medium" color="destructive"/>&nbsp;
                      <span>{props.googleCalendar.syncStatus.message}</span>
                    </>}
                />
            </GridColumn>
            <GridColumn size="half">
                <Text variant="emphasized" className="bui-spacer--medium">
                    ACL list
                    <Info
                        variant="right"
                        title="There is no custom ACL management functionality in this application (they should be managed in GSuite)"/>
                </Text>
                <List
                    items={props.googleCalendar.aclRules.map(x => ({key: x.googleId, content: x.googleId}))}
                />
            </GridColumn>
        </Grid>;

    return <>
        <Divider className="bui-spacer"/>
        <Text variant="heading" className="bui-spacer">Google Calendar</Text>
        <RenderEither condition={(_.isEmpty(props.googleCalendar) || _.isEmpty(props.googleCalendar.calendarId))}
          isTrue={() =>
              <EmptyState
                  className="bui-spacer"
                  icon={<Hourglass size="larger"/>}
                  text="Google Calendar has not been created yet it can take up to 15 minutes to be created. Check back here in a few minutes" />}
          isFalse={() =>
            <>
                <Grid className="bui-spacer">
                    <GridColumn size="full">
                        <Text><Icon svg={<Calendar />} size="larger" /> <a href={googleCalendarLink} target="_blank" rel="noopener noreferrer">Calendar view</a></Text>
                    </GridColumn>
                    <GridColumn size="half">
                        <CopyToClipboardText
                            label="Calendar ID"
                            name="calendarId"
                            text={props.googleCalendar.calendarId}
                        />
                    </GridColumn>
                    <GridColumn size="half">
                        <CopyToClipboardText
                            label="Calendar email"
                            name="calendarEmail"
                            text={props.googleCalendar.calendarEmail}
                        />
                    </GridColumn>
                </Grid>

                <RenderIf condition={!_.isEmpty(props.googleCalendar.syncStatus)} render={renderGrid} />
            </>}
        />
    </>;
};

function buildCalendarLink(googleCalendar) {
    return !_.isEmpty(googleCalendar) && !_.isEmpty(googleCalendar.calendarEmail) ?
        `https://calendar.google.com/calendar/embed?src=${(buildCalendarCode(googleCalendar.calendarEmail))}` :
        null;
}

function buildCalendarCode(calendarEmail) {
    // Encode in Base64
    let result = btoa(calendarEmail);
    // Remove padding `=` characters
    return removeTrailingEquals(result);
}

function removeTrailingEquals(result) {
    if (result[result.length - 1] === '=') {
        return removeTrailingEquals(result.substr(0, result.length - 1));
    }

    return result;
}

GoogleCalendarDetails.propTypes = {
    googleCalendar: PropTypes.shape(MeetingRoomGoogleCalendarShape),
};
