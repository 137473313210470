import _ from "lodash";
import {isUuid} from "../../helpers";

/**
 * Finds room in array by room code (e.g. 'AMS9-2-04') or ID (e.g. 'e9807585-f143-44ab-8c35-ce0aa6ef2f4d').
 * @param {Array} entities
 * @param {string} identifier Room code or ID
 * @returns {object} Room or null
 *
 * @example
 * roomByCodeSelector(rooms, 'AMS9-2-04'); // -> room | null
 * @example
 * roomByCodeSelector(rooms, 'e9807585-f143-44ab-8c35-ce0aa6ef2f4d'); // -> room | null
 */
export const roomSelector = (entities, identifier) => {
    if (isUuid(identifier)) {
        return roomByIdSelector(entities, identifier);
    }
    else {
        return roomByCodeSelector(entities, identifier);
    }
};

/**
 * Finds room in array by room code (e.g. 'AMS9-2-04').
 * @param {Array} entities
 * @param {string} roomCode
 * @returns {object} Room or null
 *
 * @example
 * roomByCodeSelector(rooms, 'AMS9-2-04'); // -> room | null
 */
const roomByCodeSelector = (entities, roomCode) => {
    if (!_.isEmpty(entities)) {
        const entity = entities.find(r => r.code === roomCode);
        if (!_.isEmpty(entity)) {
            return entity;
        }
    }

    return null;
};

/**
 * Finds room in array by ID (e.g. 'e9807585-f143-44ab-8c35-ce0aa6ef2f4d').
 * @param {Array} entities
 * @param {uuid} id UUID
 * @returns {object} Room or null
 *
 * @example
 * roomByIdSelector(rooms, 'e9807585-f143-44ab-8c35-ce0aa6ef2f4d'); // -> room | null
 */
const roomByIdSelector = (entities, id) => {
    if (!_.isEmpty(entities)) {
        const entity = entities.find(r => r.id === id);
        if (!_.isEmpty(entity)) {
            return entity;
        }
    }

    return null;
};

// eslint-disable-next-line no-unused-vars
export const roomsByBuildingCodeSelector = (entities, buildingCode) => {
    // FIXME: Finish implementation. Do we need `buildingCode` here?

    // Sort rooms by room code
    return _.sortBy(entities, ['code']);
};