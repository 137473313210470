import * as _ from 'lodash';
import * as React from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {GridColumn} from '@bookingcom/bui-react';
import Grid from '@bookingcom/bui-react/components/Grid';
import Button from '@bookingcom/bui-react/components/Button';
import IconPlus from '@bookingcom/bui-react/icons/Plus';
import Table from '@bookingcom/bui-react/components/Table';
import Modal from '@bookingcom/bui-react/components/Modal';
import EmptyState from '@bookingcom/bui-react/components/EmptyState';
import CloseBold from "@bookingcom/bui-react/icons/CloseBold";
import Popout from "@bookingcom/bui-react/icons/Popout";
import TextWithSpinner from '../../../components/text-with-spinner/TextWithSpinner';
import {selectByRoomId} from "../../../api/equipment/selectors";
import {
    deleteEntityAction as deleteAirMediaUnitAction,
    fetchEntitiesAction as fetchAirMediaUnitsAction,
} from '../../../api/equipment/air-media-units/actions';
import {
    deleteEntityAction as deleteVcCodecAction,
    fetchEntitiesAction as fetchVcCodecsAction,
} from '../../../api/equipment/vc-codecs/actions';
import {triggerEquipmentExportAction} from "../../../api/equipment/actions";
import {EditVcDevice} from './edit-device/EditVcDevice';
import AddVcDevice from './add-device/AddVcDevice';
import styles from "./MeetingRoomVcListView.module.css";
import {ApplicationError} from "../../../_app/ApplicationError";
import {selectItemById} from "../../../api/selectors";
import {MODEL_STATE} from "../../../lib/MODEL_STATE";
import {MeetingRoomShape} from "../../../api/rooms/shapes";
import {VcCodecShape} from "../../../api/equipment/vc-codecs/shapes";
import {AirMediaUnitShape} from "../../../api/equipment/air-media-units/shapes";
import {ErrorShape, UserShape} from "../../../_app/appPropShapes";
import {ErrorMessage} from "../../../_app/ErrorMessage";
import {RenderIf} from "../../../components/render-if/RenderIf";
import {RenderEither} from "../../../components/render-either/RenderEither";
import Alert from "@bookingcom/bui-react/components/Alert";
import {EquipmentExportResultShape} from "../../../api/equipment/shapes";
import Page from "@bookingcom/bui-react/icons/Page";
import CopyToClipboardId from "../../../components/copy-to-clipboard-id/CopyToClipboardId";
import RbacButton from "../../../components/rbac-button/RbacButton";
import {RBAC_ROOM_EQUIPMENT} from "../../../constants";
import Wind from "@bookingcom/bui-react/icons/Wind";
import {removeAirMediaUnitErrorAction, removeVcCodecErrorAction} from "./actions";
import {API_RESOURCES} from "../../../api/apiRoutes";

const tableColumnsMapping = [
    { key: 'id', title: 'ID' },
    { key: 'model', title: 'Model' },
    { key: 'macAddress', title: 'MAC Address' },
    { key: 'ipAddress', title: 'IP Address' },
];

class MeetingRoomVcListView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldDisplayAddEntityModal: false,
            shouldDisplayEditEntityModal: false,
            selectedEntity: null,
        };
    }

    componentDidMount() {
        if (this.props.room) {
            this.props.fetchAirMediaUnitsAction(this.props.room.id);
            this.props.fetchVcCodecsAction(this.props.room.id);
        }
    }

    onAddDeviceClicked = () => {
        this.setState({shouldDisplayAddEntityModal: true});
    };

    onExportClicked = () => {
        this.props.triggerEquipmentExportAction();
    };

    deleteMeetingRoomDevice = entity => {
        if (entity.__type === API_RESOURCES.equipment.airMediaUnits.type) {
            this.props.deleteAirMediaUnitAction(entity);
        }
        else if (entity.__type === API_RESOURCES.equipment.vcCodecs.type) {
            this.props.deleteVcCodecAction(entity);
        }
        else {
            throw new ApplicationError('Unexpected error', `Unknown type of the entity: '${entity.__type}'`);
        }
    };

    editDeviceButtonClicked = entity => {
        this.setState({
            shouldDisplayEditEntityModal: true,
            selectedEntity: entity,
        });
    };

    tableRows = () => {
        const airMediaUnits = this.devicesRows(this.props.airMediaUnits);
        const vcCodecs = this.devicesRows(this.props.vcCodecs);
        return _.concat(airMediaUnits, vcCodecs);
    };

    devicesRows = devices =>
        _.map(
            devices,
            device => {
                const isDisabled = this.isSaving(device);

                const cells = _.map(tableColumnsMapping, m => this.buildCell(device, m));

                cells.push({
                    content: <div className={styles['__buttons-cell']}>
                        <RbacButton
                            rbacRight={RBAC_ROOM_EQUIPMENT}
                            text="Edit"
                            variant="secondary"
                            onClick={() => this.editDeviceButtonClicked(device)}
                            disabled={isDisabled}
                        />
                        &nbsp;
                        <RbacButton
                            rbacRight={RBAC_ROOM_EQUIPMENT}
                            className={styles['__cancel']}
                            icon={<CloseBold/>}
                            variant="secondary"
                            onClick={() => this.deleteMeetingRoomDevice(device)}
                            disabled={isDisabled}
                            attributes={{title: "Delete"}}
                            prompt={{ title: "Are you sure?", subtitle: "Are you sure want to remove the equipment?", buttonText: "Delete"}}
                        />
                    </div>,
                });

                return {
                    cells,
                    key: device.id,
                };
            }
        );

    buildCell(device, mapping) {
        const value = device[mapping.key];
        let content = value;

        if (mapping.key === 'id') {
            content = <CopyToClipboardId text={value} />;
        }

        return {content};
    }

    tableHeadings = () => {
        const tableHeadings = _.map(tableColumnsMapping, m => ({text: m.title}));
        tableHeadings.push({text: '', align: 'end'});
        return tableHeadings;
    };

    onAddDeviceModalClose = () => {
        this.setState({shouldDisplayAddEntityModal: false});
    };

    onEditDeviceModalClose = () => {
        if (!!this.state.selectedEntity) {
            if (this.state.selectedEntity.__type === API_RESOURCES.equipment.airMediaUnits.type) {
                this.props.removeAirMediaUnitErrorAction(this.state.selectedEntity);
            } else if ((this.state.selectedEntity.__type === API_RESOURCES.equipment.vcCodecs.type)) {
                this.props.removeVcCodecErrorAction(this.state.selectedEntity);
            }
        }

        this.setState({
            shouldDisplayEditEntityModal: false,
            selectedEntity: null,
        });
    };

    isSaving = entity => {
        let entities = null;
        if (entity.__type === API_RESOURCES.equipment.airMediaUnits.type) {
            entities = this.props.airMediaUnits;
        }
        else if ((entity.__type === API_RESOURCES.equipment.vcCodecs.type)) {
            entities = this.props.vcCodecs;
        }

        if (!_.isEmpty(entities)) {
            return selectItemById(entities, entity.id) === MODEL_STATE.SYNCING;
        }

        return false;
    };

    areAnyDevices = () =>
        (!_.isEmpty(this.props.vcCodecs) && this.props.vcCodecs.length > 0) ||
        (!_.isEmpty(this.props.airMediaUnits) && this.props.airMediaUnits.length > 0);

    render() {
        return <>
            <Grid>
                <GridColumn size="full">
                    <ErrorMessage error={this.props.exportError} />
                    <div className={styles['__buttons']}>
                        <Button text="Export all" icon={<Page/>} variant="secondary" onClick={this.onExportClicked} loading={this.props.isTriggeringExport} />
                        <RbacButton rbacRight={RBAC_ROOM_EQUIPMENT} text="Add device" icon={<IconPlus/>} onClick={this.onAddDeviceClicked} />
                    </div>
                </GridColumn>
                <RenderIf condition={!!this.props.exportResult} render={() =>
                    <GridColumn size="full">
                        <Alert
                            variant="success"
                            size="large"
                            title="Exported completed"
                        >
                            <p>There was a Google Spreadsheet created:&nbsp;<a href={this.props.exportResult.url} title="Open in new tab" target="_blank" rel="noopener noreferrer"><strong>report</strong> <Popout /></a></p>
                        </Alert>
                    </GridColumn>}
                />
                <GridColumn size="full">
                    <RenderEither condition={this.props.isLoadingAirMediaUnitsList || this.props.isLoadingVcCodecsList}
                      isTrue={() =>
                        <TextWithSpinner>Our rodents are busy searching for your devices, hang on a second...</TextWithSpinner>}
                      isFalse={() =>
                        <RenderEither condition={this.areAnyDevices()}
                          isTrue={() =>
                            <Table
                                headings={this.tableHeadings()}
                                rows={this.tableRows()}
                            />}
                          isFalse={() =>
                            <EmptyState icon={<Wind />} text="There are no devices in this meeting room"/>}
                        />
                      }
                    />
                </GridColumn>
            </Grid>

            <Modal
                active={this.state.selectedEntity !== null}
                closeAriaLabel="Edit device"
                onClose={this.onEditDeviceModalClose}
                title="Edit device"
            >
                <RenderIf condition={!!this.state.selectedEntity} render={() =>
                    <EditVcDevice
                        entity={this.state.selectedEntity}
                        onSave={this.onEditDeviceModalClose}
                        onCancel={this.onEditDeviceModalClose}
                    />}
                />
            </Modal>

            <Modal
                title={`Add device to ${this.props.room.code}`}
                closeAriaLabel="Close Modal"
                onClose={this.onAddDeviceModalClose}
                active={this.state.shouldDisplayAddEntityModal}
            >
                <AddVcDevice
                    room={this.props.room}
                    onSave={this.onAddDeviceModalClose}
                    onCancel={this.onAddDeviceModalClose}
                />
            </Modal>
        </>;
    }
}

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
    triggerEquipmentExportAction,
    fetchAirMediaUnitsAction,
    fetchVcCodecsAction,
    deleteAirMediaUnitAction,
    deleteVcCodecAction,
    removeAirMediaUnitErrorAction,
    removeVcCodecErrorAction,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state, ownProps) => ({
    // User
    user: state.oidc.user,

    // API data
    airMediaUnits: selectByRoomId(state.api.equipment.airMediaUnits.entities, ownProps.room.id),
    vcCodecs: selectByRoomId(state.api.equipment.vcCodecs.entities, ownProps.room.id),

    // Page data
    isLoadingAirMediaUnitsList: state.pages.roomEdit.equipment.isLoadingAirMediaUnitsList,
    isLoadingVcCodecsList: state.pages.roomEdit.equipment.isLoadingVcCodecsList,
    isTriggeringExport: state.pages.roomEdit.equipment.isTriggeringExport,
    exportError: state.pages.roomEdit.equipment.exportError,
    exportResult: state.pages.roomEdit.equipment.exportResult,
});

MeetingRoomVcListView.propTypes = {
    room: PropTypes.shape(MeetingRoomShape).isRequired,

    // Actions
    triggerEquipmentExportAction: PropTypes.func,
    fetchAirMediaUnitsAction: PropTypes.func,
    fetchVcCodecsAction: PropTypes.func,
    deleteAirMediaUnitAction: PropTypes.func,
    deleteVcCodecAction: PropTypes.func,
    removeAirMediaUnitErrorAction: PropTypes.func,
    removeVcCodecErrorAction: PropTypes.func,

    // Redux
    user: PropTypes.shape(UserShape),
    vcCodecs: PropTypes.arrayOf(PropTypes.shape(VcCodecShape)),
    airMediaUnits: PropTypes.arrayOf(PropTypes.shape(AirMediaUnitShape)),
    isLoadingAirMediaUnitsList: PropTypes.bool,
    isLoadingVcCodecsList: PropTypes.bool,
    isTriggeringExport: PropTypes.bool,
    exportError: PropTypes.shape(ErrorShape),
    exportResult: PropTypes.shape(EquipmentExportResultShape),
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingRoomVcListView);
